import React, { useEffect, useRef, useState } from 'react'
import {
  TabbingSwitch,
  AccessDenied,
  Thumbnails,
  EntityHeader,
  FunctionalButton
  // SkeletonLoader
} from '../helperComponents/_components'
import news from '../helperComponents/latestNews.json'
import {
  ITag,
  IDataMapped,
  ITrendline,
  IHomePage,
  IBasicIndicator,
  ICalculatedIndicator
} from '../utils/interfaces'

import { IconTag, IconWorkSpace } from '../helperComponents/Icons'
import Updates from '../helperComponents/Updates'
import { getAllValuesGroupedByKey } from '../utils/transformingData'
// import DataTable from '../helperComponents/DataTable'
import OwlCarousel from 'react-owl-carousel'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import { extraHomepageFetch } from '../utils/fetch'
import {
  capitalise,
  retrieveFromCache,
  saveDataToCache
} from '../utils/functions'
import MiniEntity from './MiniEntity'
import { SparklineTable } from '../charts/SparklineCharts'
import { TickerTape } from 'react-ts-tradingview-widgets'

const HomePage = ({
  userTags,
  homepageData,
  sections,
  channelsContent,
  userWorkspaces
}: IHomePage) => {
  try {
    const transformedData = useRef<IDataMapped>({
      data: []
    })
    const [optionsCarousel] = useState({
      margin: 10,
      loop: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        }
      }
    })
    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })
    // eslint-disable-next-line
    const fetchedIndicatorParameters = useRef<
      (ITrendline | IBasicIndicator | ICalculatedIndicator)[]
    >([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [filteredKeys, setFilteredKeys] = React.useState<string[]>([])
    const [selectedSection, setSelectedSection] = React.useState<string>('')
    const [lastUpdatedTrendlines, setLastUpdatedTrendlines] =
      React.useState<Date>()

    const [selectedScenario, setSelectedScenario] = React.useState<{
      title: string
      fid: string
      description: string
    }>(homepageData.scenarios[0])

    const swicthSection = (
      selectedSection: string,
      fetchedAllIndicators?: (
        | ITrendline
        | IBasicIndicator
        | ICalculatedIndicator
      )[]
    ) => {
      if (selectedSection === '') return
      setSelectedSection(selectedSection)

      const section = sections.find(
        (section) => section.sectionTitle === selectedSection
      )

      if (!section) return

      const localParams =
        fetchedAllIndicators || fetchedIndicatorParameters.current

      const sectionTrendlines = localParams.filter((param) =>
        section.trendlines.includes(param.fid)
      )

      const dataKeys = [...sectionTrendlines.map((key) => key.fid)]

      setFilteredKeys(dataKeys)
    }

    const handleScenarioClick = (fid: string) => {
      setSelectedScenario(
        homepageData.scenarios.find((scenario) => scenario.fid === fid) as any
      )
    }

    const initialFetch = async (useCache: boolean) => {
      setLoading(true)

      try {
        let homepageData = null
        if (useCache) {
          const EXPIRY = 1000 * 60 * 60 * 24
          const data = retrieveFromCache('homepageData', EXPIRY)

          if (data) {
            homepageData = data.data
            setLastUpdatedTrendlines(new Date(data.date))
          }
        }

        if (!homepageData) {
          const fetchResult = await extraHomepageFetch()
          homepageData = fetchResult.homepageData
          const now = new Date()
          saveDataToCache('homepageData', homepageData, now)
          setLastUpdatedTrendlines(now)
        }

        const { trendlines } = homepageData
        const { params, data } = trendlines
        const transformed: IDataMapped = getAllValuesGroupedByKey(data)

        transformedData.current = transformed
        fetchedIndicatorParameters.current = params
        setOwnerInfo({
          profileImage: homepageData.owner.profile_image,
          username: homepageData.owner.username
        })
        const selectedTitle =
          sections.length > 0 ? sections[0].sectionTitle : ''

        swicthSection(selectedTitle, params)
        setLoading(false)

        return true
      } catch (error) {
        console.error('Error setting up charts:', error)
        setLoading(false)

        return false
      }
    }

    useEffect(() => {
      if (loading) return

      initialFetch(true)
    }, [])

    return (
      <div className="entity homepage">
        <EntityHeader
          ownerInfo={ownerInfo}
          title="Welcome to Your Dashboard"
          description="Here's a summary of your activities and updates."
          subtitle="Home"
        />

        <div className="entity-blocks">
          <TickerTape
            symbols={[
              // Major Indices
              { proName: 'FOREXCOM:SPXUSD', title: 'S&P 500 Index' },
              { proName: 'FOREXCOM:NSXUSD', title: 'US 100 Cash CFD' },
              { proName: 'INDEX:DAX', title: 'Germany DAX 40' },
              { proName: 'INDEX:FTSE', title: 'UK FTSE 100' },
              { proName: 'INDEX:NIFTY', title: 'India Nifty 50' },
              { proName: 'INDEX:NKY', title: 'Japan Nikkei 225' },
              { proName: 'INDEX:HSI', title: 'Hong Kong Hang Seng Index' },

              // Commodities
              { proName: 'FOREXCOM:XAUUSD', title: 'Gold' }, // Gold price in USD
              { proName: 'FOREXCOM:XAGUSD', title: 'Silver' }, // Silver price in USD
              { proName: 'TVC:USOIL', title: 'Crude Oil (WTI)' },
              { proName: 'TVC:UKOIL', title: 'Brent Crude Oil' },
              { proName: 'TVC:NGAS', title: 'Natural Gas' },
              { proName: 'CME:ZC1!', title: 'Corn Futures' },
              { proName: 'CME:ZW1!', title: 'Wheat Futures' },

              // Currencies
              { proName: 'FX_IDC:EURUSD', title: 'EUR to USD' },
              { proName: 'FX_IDC:USDJPY', title: 'USD to JPY' },
              { proName: 'FX_IDC:GBPUSD', title: 'GBP to USD' },
              { proName: 'TVC:DXY', title: 'US Dollar Index' },

              // Cryptocurrencies
              { proName: 'BITSTAMP:BTCUSD', title: 'Bitcoin' },
              { proName: 'BITSTAMP:ETHUSD', title: 'Ethereum' },
              { proName: 'COINBASE:ADAUSD', title: 'Cardano' },
              { proName: 'COINBASE:SOLUSD', title: 'Solana' },

              // Bonds and Treasuries
              { proName: 'TVC:US10Y', title: 'US 10-Year Treasury Yield' },
              { proName: 'TVC:US02Y', title: 'US 2-Year Treasury Yield' },
              { proName: 'TVC:US30Y', title: 'US 30-Year Treasury Yield' },
              { proName: 'TVC:DE10Y', title: 'Germany 10-Year Bund Yield' },
              { proName: 'TVC:UK10Y', title: 'UK 10-Year Gilt Yield' },
              { proName: 'TVC:JP10Y', title: 'Japan 10-Year Bond Yield' },

              // Macro Futures
              { proName: 'CME:GEZ2024', title: 'Eurodollar Futures Dec 2024' },
              { proName: 'CME:ES1!', title: 'E-mini S&P 500 Futures' },
              { proName: 'CME:NQ1!', title: 'Nasdaq 100 Futures' },
              { proName: 'CME:YM1!', title: 'Dow Jones Futures' },
              { proName: 'CME:RTY1!', title: 'Russell 2000 Futures' }
            ]}
            showSymbolLogo
            colorTheme="light"
            isTransparent
            displayMode="adaptive"
            locale="en"
          />
          <div className="banner-strip action">
            Try our new{' '}
            <a
              className="text-white pointer"
              href="https://stocks.api-chartit360.com"
              target="_blank"
              rel="noreferrer"
            >
              StockBot
            </a>
          </div>
          {userWorkspaces && userWorkspaces.length > 0 && (
            <div className="container w-12">
              <div className="entity-data">
                <div className="entity-parameters">
                  <span className="px-2">Your Workspaces</span>
                </div>
                <div className="flex gap-3 tag-buttons">
                  <h3 className="m-0"></h3>
                  {userWorkspaces.map((workspace, index) => (
                    <button
                      key={index}
                      className="no-btn tag-button flex gap-1"
                      onClick={() => {
                        localStorage.setItem('workspaceFid', workspace.fid)
                        window.location.reload()
                      }}
                    >
                      <IconWorkSpace />
                      <span className="m-0">{workspace.title}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="container w-4 full scroll no-scrollbar">
            <div className="entity-data">
              <div className="entity-parameters">
                <TabbingSwitch
                  options={sections.map((section) => ({
                    label: section.sectionTitle,
                    active: selectedSection === section.sectionTitle,
                    onClick: () => swicthSection(section.sectionTitle)
                  }))}
                  fit
                  numberVisible={3}
                />
              </div>
              <div className="entity-parameters">
                <span className="small">
                  Last updated:{' '}
                  {lastUpdatedTrendlines
                    ? lastUpdatedTrendlines.toLocaleString()
                    : new Date().toLocaleString()}
                </span>
                <FunctionalButton
                  initialButtonState="Refresh"
                  functionToExecute={() => initialFetch(false)}
                  className="no-btn no-hover"
                  iconReload
                  doesReset
                />
              </div>

              <SparklineTable
                data={transformedData.current}
                filteredKeys={filteredKeys}
                titles={fetchedIndicatorParameters.current
                  .map((item) => ({
                    [item.fid]: item.title
                  }))
                  .reduce((acc, curr) => ({ ...acc, ...curr }), {})}
              />
            </div>
          </div>
          {homepageData.scenarios.length > 0 && selectedScenario && (
            <div className="container w-8 full">
              <div className="entity-parameters">
                <TabbingSwitch
                  options={homepageData.scenarios.map((scenario) => ({
                    label: scenario.title,
                    active: selectedScenario.fid === scenario.fid,
                    onClick: () => handleScenarioClick(scenario.fid)
                  }))}
                  fit
                  numberVisible={5}
                />
              </div>
              <MiniEntity id={selectedScenario.fid} type="scenario" />
            </div>
          )}

          {homepageData.scenarios.length === 0 && (
            <div className="container w-6 tall">
              <p className="banner-strip info p-2">
                No scenarios have been added yet. Please add scenarios from
                Settings
              </p>
            </div>
          )}
          {/* <div className="container w-2 tall">
            <div className="entity-data">
              <div className="entity-parameters">
                <span className="px-2">{selectedScenario.title}</span>
              </div>
              <p className="px-2">{selectedScenario.description}</p>
            </div>
          </div> */}
          <div className="container w-12 scroll">
            {channelsContent.length === 0 && (
              <p className="banner-strip info p-2">
                The content from the channels you are following will appear here
              </p>
            )}
            {channelsContent.length > 0 && (
              <Thumbnails
                thumbnails={channelsContent.map((piece) => ({
                  title: piece.title,
                  subtitle: capitalise(piece.source_type),
                  image: piece.link || '',
                  details: piece.short_description || '',
                  onClick: () =>
                    window.switchFunctions[
                      piece.source_type as keyof typeof window.switchFunctions
                    ](piece.source_fid),
                  ownerInfo: {
                    username: piece.owner_info.username,
                    profile_image: piece.owner_info.profile_image,
                    onClick: () =>
                      window.switchFunctions.channel(piece.channel_id)
                  },
                  tags: piece.tags
                }))}
                itemsInRow={4}
              />
            )}
          </div>
          <div className="container w-12">
            {homepageData.memos.length === 0 && (
              <h2>Add memos here from Settings</h2>
            )}
            {homepageData.memos.length > 0 && (
              <OwlCarousel className="owl-theme" {...optionsCarousel}>
                {homepageData.memos.map((memo, index) => (
                  <div key={index} className="card about-img p-2">
                    <div className="card-body">
                      <h5 className="card-title">
                        <span
                          className="pointer"
                          onClick={() => window.switchFunctions.memo(memo.fid)}
                        >
                          {memo.title}
                        </span>
                      </h5>
                      <div className="flex space-between">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '4.5em',
                            overflow: 'hidden'
                          }}
                        >
                          <p>{memo.short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
          <div className="container w-5">
            <div className="entity-data">
              <div className="entity-parameters">
                <span className="px-2">Latest Updates</span>
              </div>
              <Updates updates={news as any} />
            </div>
          </div>
          <div className="container w-3 small">
            <div className="entity-data">
              <div className="entity-parameters">
                <span className="px-2">User tags</span>
              </div>
              {userTags && userTags.length > 0 && (
                <div className="flex gap-3 tag-buttons">
                  {userTags.map((tag: ITag) => (
                    <button
                      key={tag.id}
                      className="no-btn icon inline tag-button"
                      onClick={() =>
                        window.switchFunctions.search(tag.title as string)
                      }
                    >
                      <IconTag /> {tag.title}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="container w-4">
            <ChatbotComponent page="home" />
          </div>
        </div>
      </div>
    )
  } catch (error) {
    console.error('Error rendering scenario page:', error)
    return <AccessDenied />
  }
}

export default React.memo(HomePage)
