import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PopupModal from '../helperComponents/PopUpModal'
import {
  FunctionalButton,
  InitialLoadingScreen,
  NextPrevChevrons,
  Spinner
} from '../helperComponents/_components'

import {
  initialFetch,
  createNewCollection,
  createNewIndicatorCalculated,
  updateUserSettings,
  createNewMemo,
  createWorkspace
} from '../utils/fetch'
import {
  IScenarioItem,
  ICustomContextMenuSettings,
  ICollection,
  ITag,
  ISwitchFunctions,
  ICalculatedIndicator,
  ITrendline,
  IUserSettings,
  IBasicIndicator,
  IMemo,
  IRecordedInteraction,
  IQuickSearchEntity,
  IForecast,
  IChannel,
  IVisitedPage,
  IChannelContentPiece
} from '../utils/interfaces'
import {
  transformAndSortAllEntities,
  transformAndSortSidePanelEntities,
  transformSettings
} from '../utils/transformingData'
import SidePanel from './SidePanel'
import IndicatorPage from './IndicatorPage'
import TrendLinePage from './TrendlinePage'
import UploadPage from '../helperComponents/UploadPage'

import {
  capitalise,
  getCurrentPage,
  getNextPage,
  getPreviousPage,
  isEmpty,
  updateLocalStoragePageHistory
} from '../utils/functions'
import SearchPage from '../helperComponents/SearchPage'
import HomePage from './HomePage'
import CollectionPage from './CollectionPage'
import ScenarioPage from './ScenarioPage'
import MemoPage from './MemoPage'
import CalculatedIndicator from './CalculatedIndicator'
import GlobalSettings from './GlobalSettings'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import {
  IconLogout,
  IconQuestionMark,
  IconSearch
} from '../helperComponents/Icons'
import CollapsibleSidePanel from '../helperComponents/CollapsibleSidePanel'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import Forecast from './ForecastPage'
import ChannelPage from './ChannelPage'
import { TYPES_DICT } from '../utils/_constants'
import ExternalPage from './ExternalPage'
import WorkspaceHomepage from './WorkspaceHomepage'
import MainAppCreateScenarioPopup from '../popups/MainApp/MainAppCreateScenarioPopup'
import ChannelsPage from './ChannelsPage'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    switchFunctions: ISwitchFunctions
    globalSettings: IUserSettings
    saveGlobalSettings: (settings: IUserSettings) => Promise<boolean>
    recordedInteractions: IRecordedInteraction[]
    collapseSidePanel: (state: boolean) => void
    visitedPages: IVisitedPage[]
    currentPageIndex: number
  }
}

const MainApp = () => {
  try {
    /// //////////////////////////   Auth States Start /////////////////////////////

    const [userId, setUserId] = useState<string>(
      localStorage.getItem('userId') || ''
    )
    const [modalCreateNew, setModalCreateNew] = useState<boolean>(false)
    const [modalCreateNewWorkspace, setModalCreateNewWorkspace] =
      useState<boolean>(false)
    const [
      modalCreateNewCalculatedIndicator,
      setModalCreateNewCalculatedIndicator
    ] = useState<boolean>(false)
    const [newCalculatedSettings, setNewCalculatedSettings] = useState<{
      title: string
      dataMode: string
      computationMode: string
    }>({
      title: '',
      dataMode: 'values',
      computationMode: ''
    })
    const [entities, setEntities] = useState<IQuickSearchEntity[]>([])
    const [readerMode, setReaderMode] = useState<boolean>(false)
    const [modalUserSettings, setModalUserSettings] = useState<boolean>(false)
    const [processedGlobalSettings, setProcessedGlobalSettings] =
      useState<IUserSettings>()
    const [modalCreateNewCollection, setModalCreateNewCollection] =
      useState<boolean>(false)
    const [modalCreateNewMemo, setModalCreateNewMemo] = useState<boolean>(false)
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [modalUpdateUser, setModalUpdateUser] = useState<boolean>(false)
    const [allEntitiesForWorkspace, setAllEntitiesForWorkspace] = useState<
      {
        fid: string | number
        title: string
        type: string
      }[]
    >([])
    const [newCollectionName, setNewCollectionName] = useState<string>('')

    const [fetchedScenariosList, setFetchedScenariosList] = useState<
      IScenarioItem[]
    >([])
    const [workspaceDetails, setWorkspaceDetails] = useState<{
      title: string
      fid: string
      isPrimaryWorkSpace: boolean
    }>()
    const [userWorkspaces, setUserWorkspaces] = useState<
      {
        title: string
        fid: string
      }[]
    >([])

    const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
      []
    )
    const [followingChannelsContentPieces, setFollowingChannelsContentPieces] =
      useState<IChannelContentPiece[]>([])
    const [fetchedForecasts, setFetchedForecasts] = useState<IForecast[]>([])
    const [channelsQuickSearch, setChannelsQuickSearch] = useState<
      IQuickSearchEntity[]
    >([])
    const [fetchedMemos, setFetchedMemos] = useState<IMemo[]>([])
    const [search, setSearch] = useState<string>('')
    const [chosenScenarioId, setChosenScenarioId] = useState<string>('')
    const [chosenIndicatorId, setChosenIndicatorId] = useState<string>('')
    const [chosenTrendlineId, setChosenTrendlineId] = useState<string>('')
    const [chosedExternalIndicatorId, setChosedExternalIndicatorId] =
      useState<string>('')
    const [chosenForecastId, setChosenForecastId] = useState<string>('')
    const [chosenCalculatedIndicator, setChosenCalculatedIndicator] =
      useState<string>('')
    const [chosenCollectionId, setChosenCollectionId] = useState<number>(0)
    const [chosenMemoId, setChosenMemoId] = useState<string>('')
    const [chosenChannelId, setChosenChannelId] = useState<string>('')
    const [
      fullFetchedIndicatorsParameters,
      setFullFetchedIndicatorsParameters
    ] = useState<(ITrendline | ICalculatedIndicator | IBasicIndicator)[]>([])
    const [homepageData, setHomepageData] = useState<any>()
    const [fetchedTrendlines, setFetchedTrendlines] = useState<ITrendline[]>([])
    const [fetchedUserTags, setFetchedUserTags] = useState<ITag[]>([])
    const [isPrimaryWorkSpace, setIsPrimaryWorkSpace] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [noData, setNoData] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('home')
    const contextMenuDefaultState = {
      show: false,
      top: 0,
      left: 0,
      submenuPosition: 'bottom'
    }

    const [contextMenuSidePanel, setContextMenuSidePanel] =
      useState<ICustomContextMenuSettings>(
        contextMenuDefaultState as ICustomContextMenuSettings
      )
    const resetContextMenuSidePanel = () =>
      contextMenuSidePanel.show &&
      setContextMenuSidePanel(
        contextMenuDefaultState as ICustomContextMenuSettings
      )

    const handleContextMenuSidePanel = (e: any) => {
      e.preventDefault()
      if (contextMenuSidePanel.show) {
        resetContextMenuSidePanel()
        return
      }
      setContextMenuSidePanel({
        show: true,
        top: e.pageY,
        left: e.pageX + 10,
        submenuPosition: 'bottom'
      })
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
      resetContextMenuSidePanel()
      setModalCreateNew(false)
      setModalCreateNewWorkspace(false)
      setModalCreateNewCollection(false)
      setModalCreateNewMemo(false)
      setNewCollectionName('')
      setModalCreateNewCalculatedIndicator(false)
      setNewCalculatedSettings({
        title: '',
        dataMode: '',
        computationMode: ''
      })
      setModalUserSettings(false)
      setModalUpdateUser(false)
    }

    const resetAll = () => {
      resetModals()
      setReaderMode(false)
      setChosenMemoId('')
      setChosenChannelId('')
      setChosenCollectionId(0)
      setChosenIndicatorId('')
      setChosenScenarioId('')
      setChosenTrendlineId('')
      setChosedExternalIndicatorId('')
      setChosenForecastId('')
      setChosenCalculatedIndicator('')
    }

    const switchOnLoad = (ids: string[]) => {
      let type = ''
      let id = ''
      const page = getCurrentPage()
      const url = new URLSearchParams(window.location.search)
      const urlType = url.get('t') || ''
      const urlId = url.get('i') || ''
      const search = url.get('search')
      setSearch(search || '')
      const isChatOpen = url.get('chat')
      if (isChatOpen) {
        setIsPanelOpen(true)
      }

      // clean the url
      if (isEmpty(urlType) && !page) {
        window.switchFunctions.home()
        return
      }

      if (isEmpty(urlType) && page) {
        type = page.type
        id = page.fid?.toString() || ''
      } else if (!isEmpty(urlType)) {
        type = urlType
        id = urlId || ''
      }

      if (isEmpty(type)) {
        window.switchFunctions.home()
        return
      }

      if (
        ids.map(String).indexOf(id.toString()) === -1 &&
        type !== 'h' &&
        type !== 'a' &&
        type !== 'u' &&
        type !== 'r'
      ) {
        setReaderMode(true)
      } else {
        setReaderMode(false)
      }

      switch (type) {
        case 'u':
          window.switchFunctions.upload()
          break
        case 'a':
          window.switchFunctions.search()
          break
        case 'h':
          window.switchFunctions.home()
          break
        case 's':
          window.switchFunctions.scenario(id)
          break
        case 'i':
          window.switchFunctions.indicator(id)
          break
        case 'c':
          window.switchFunctions.collection(parseInt(id))
          break
        case 'm':
          window.switchFunctions.memo(id)
          break
        case 'o':
          window.switchFunctions.calculated(id)
          break
        case 't':
          window.switchFunctions.trendline(id)
          break
        case 'f':
          window.switchFunctions.forecast(id)
          break
        case 'r':
          window.switchFunctions.channel(id)
          break
        case 'e':
          window.switchFunctions.external(id)
          break
        default:
          window.switchFunctions.home()
      }
    }

    // const handleUpdateUser = () => {
    //   if (localStorage.getItem('isUserUpdatedOnRangesnew') === 'true') return
    //   setModalUpdateUser(true)
    // }

    /// //////////////////////////   Edit States End  ////////////////////////////

    /// ///////////////////////////////////////////////////////////////////////////
    ///                           Fetching Start
    /// ///////////////////////////////////////////////////////////////////////////

    const precheckSwitch = (fid: string | number, newId: string | number) => {
      resetAll()

      // const currentPage = getCurrentPage();
      // if (currentPage && currentPage.fid === fid && currentPage.type === type) {
      //   if (isEmpty(pageMode)) {
      //     return;
      //   } else if (pageMode === currentPage.pageMode) {
      //     return;
      //   }
      // }

      return true
    }

    const switchFunction = (
      type: string,
      fid: string | number | null,
      dontUpdate?: boolean
    ) => {
      if (type === 'scenario') {
        setChosenScenarioId(fid as string)
      } else if (type === 'indicator') {
        setChosenIndicatorId(fid as string)
        localStorage.setItem('indicatorPageMode', `basic---${fid}`)
      } else if (type === 'collection') {
        setChosenCollectionId(fid as number)
      } else if (type === 'memo') {
        setChosenMemoId(fid as string)
      } else if (type === 'trendline') {
        setChosenTrendlineId(fid as string)
      } else if (type === 'calculated') {
        setChosenCalculatedIndicator(fid as string)
      } else if (type === 'forecast') {
        setChosenForecastId(fid as string)
      } else if (type === 'channel') {
        setChosenChannelId(fid as string)
      } else if (type === 'external') {
        localStorage.setItem('indicatorPageMode', `basic---${fid}`)
        setChosedExternalIndicatorId(fid as string)
      } else if (type === 'indicatorTrendlines') {
        localStorage.setItem('indicatorPageMode', `trendline---${fid}`)
        setChosenIndicatorId(fid as string)
      } else if (type === 'externalTrendlines') {
        localStorage.setItem('indicatorPageMode', `trendline---${fid}`)
        setChosedExternalIndicatorId(fid as string)
      }

      if (dontUpdate === true) {
        // step back can be a syntetic event
        // window.currentPageIndex -= 1
      } else {
        updateLocalStoragePageHistory({
          type,
          fid
        })
      }

      if (type === 'indicatorTrendlines') {
        type = 'indicator'
      }

      if (type === 'externalTrendlines') {
        type = 'external'
      }

      // push the type and id into url
      const url = new URL(window.location.href)
      url.searchParams.set('t', TYPES_DICT[type as keyof typeof TYPES_DICT])
      if (fid) {
        url.searchParams.set('i', String(fid))
      } else {
        url.searchParams.delete('i')
      }
      window.history.pushState({}, '', url.toString())
      setMode(type)
      const entityWithId = entities.find((item) => item.id === fid)
      if (entityWithId) {
        // push the entity to the top of the list
        const index = entities.indexOf(entityWithId)
        const newEntities = [...entities]
        newEntities.splice(index, 1)
        newEntities.unshift(entityWithId)
        setEntities(newEntities)
      }
    }

    const switchToHome = (dontUpdate?: boolean) => {
      try {
        resetAll()
        switchFunction('home', null, dontUpdate)
      } catch (error) {
        console.error(error)
        window.location.href = '/'
      }
    }

    const switchToHomeAfterDeleted = (
      id: string | number,
      dontUpdate?: boolean
    ) => {
      try {
        resetAll()

        // filter out the deleted entity from entities
        const newEntities = entities.filter((item) => item.id !== id)
        setEntities(newEntities)
        switchFunction('home', null, dontUpdate)
      } catch (error) {
        console.error(error)
        window.location.href = '/'
      }
    }

    const reset = () => {
      resetAll()
      // clean the url
      const url = new URL(window.location.href)
      url.searchParams.delete('t')
      url.searchParams.delete('i')
      window.history.pushState({}, '', url.toString())

      switchFunction('home', null)
    }

    const rollBackToHome = () => {
      // window.currentPageIndex -= 1;
      window.visitedPages = window.visitedPages.slice(0, -1)
      switchToHome()
    }

    const switchToScenarioById = (id: string, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenScenarioId)
        if (!check) return

        switchFunction('scenario', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToIndicatorById = (
      id: string,
      dontUpdate?: boolean,
      dependent?: boolean
    ) => {
      try {
        const check = precheckSwitch(id, chosenIndicatorId)
        if (!check) return

        if (dependent) {
          switchFunction('indicatorTrendlines', id, dontUpdate)
        } else {
          switchFunction('indicator', id, dontUpdate)
        }
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const defaultToPrimaryWorkspace = () => {
      localStorage.removeItem('workspaceFid')
      window.location.href = '/app'

      return true
    }

    const switchToTrendlineById = (id: string, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenTrendlineId)
        if (!check) return

        switchFunction('trendline', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToForecastById = (id: string, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenForecastId)
        if (!check) return

        switchFunction('forecast', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToCollectionById = (id: number, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenCollectionId)
        if (!check) return

        switchFunction('collection', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToMemoById = (id: string, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenMemoId)
        if (!check) return

        switchFunction('memo', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToSearch = (search?: string, dontUpdate?: boolean) => {
      try {
        resetAll()

        switchFunction('search', null, dontUpdate)
        setSearch(search || '')
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToChannles = () => {
      try {
        resetAll()

        switchFunction('channels', null)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToUpload = (dontUpdate?: boolean) => {
      try {
        resetAll()

        switchFunction('upload', null, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const swicthToChannel = (id: string, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenCalculatedIndicator)
        if (!check) return

        switchFunction('channel', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToExternal = (
      id: string,
      dontUpdate?: boolean,
      dependent?: boolean
    ) => {
      try {
        const check = precheckSwitch(id, chosedExternalIndicatorId)
        if (!check) return

        if (dependent) {
          switchFunction('externalTrendlines', id, dontUpdate)
        } else {
          switchFunction('external', id, dontUpdate)
        }
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchToCalculatedIndicator = (id: string, dontUpdate?: boolean) => {
      try {
        const check = precheckSwitch(id, chosenCalculatedIndicator)
        if (!check) return

        switchFunction('calculated', id, dontUpdate)
      } catch (error) {
        console.error(error)
        rollBackToHome()
      }
    }

    const switchFunctions = {
      scenario: (id: string, dontUpdate?: boolean) =>
        switchToScenarioById(id, dontUpdate),
      indicator: (id: string, dontUpdate?: boolean, dependent?: boolean) =>
        switchToIndicatorById(id, dontUpdate, dependent),
      collection: (id: number, dontUpdate?: boolean) =>
        switchToCollectionById(id, dontUpdate),
      memo: (id: string, dontUpdate?: boolean) =>
        switchToMemoById(id, dontUpdate),
      trendline: (id: string, dontUpdate?: boolean) =>
        switchToTrendlineById(id, dontUpdate),
      search: (search?: string, dontUpdate?: boolean) =>
        switchToSearch(search, dontUpdate),
      upload: (dontUpdate?: boolean) => switchToUpload(dontUpdate),
      home: (dontUpdate?: boolean) => switchToHome(dontUpdate),
      reset: () => reset(),
      calculated: (id: string, dontUpdate?: boolean) =>
        switchToCalculatedIndicator(id, dontUpdate),
      forecast: (id: string, dontUpdate?: boolean) =>
        switchToForecastById(id, dontUpdate),
      channel: (id: string, dontUpdate?: boolean) =>
        swicthToChannel(id, dontUpdate),
      external: (id: string, dontUpdate?: boolean) =>
        switchToExternal(id, dontUpdate),
      deleted: (id: string | number) => switchToHomeAfterDeleted(id),
      channels: () => switchToChannles()
    }
    window.switchFunctions = switchFunctions

    // add these to the window object so they can be called from the console

    /// ///////////////////////////////////////////////////////////////////////////
    ///                           Fetching End
    /// ///////////////////////////////////////////////////////////////////////////

    const fetchIntial = useCallback(async () => {
      try {
        // if (loading === true) return

        const res = await initialFetch()
        const {
          indicatorsParameters,
          collections,
          scenariosList,
          // followableScenariosList,
          tagsList,
          isAdmin,
          userId,
          trendlines,
          memos,
          settings,
          followingContent,
          // allAvailableContent,
          allEntitiesForWorkspace,
          channels,
          homepageData,
          workspaceDetails,
          userWorkspaces,
          forecasts,
          records
        } = res
        localStorage.setItem('isAdmin', isAdmin)
        localStorage.setItem('userId', userId)

        const processedSettings = transformSettings(settings)
        window.globalSettings = processedSettings
        window.visitedPages = []
        window.currentPageIndex = 0
        window.saveGlobalSettings = updateUserSettings
        window.recordedInteractions = records

        const allParams = [...indicatorsParameters, ...trendlines]
        const indicators = allParams.sort((a, b) =>
          (a.title || a.fid).localeCompare(b.title || b.fid)
        )

        const transformedEntities = transformAndSortAllEntities({
          indicators,
          trendlines,
          collections,
          scenariosList,
          memos,
          forecasts,
          records
        })

        const favourites = collections.filter(
          (collection: { collection_name: string }) =>
            collection.collection_name === 'favourites'
        )

        const transformedFavourites = transformAndSortSidePanelEntities(
          favourites,
          records
        )
        const combinedEntities = [
          ...transformedEntities,
          ...transformedFavourites
        ]

        const seen = new Set()
        const filteredEntities =
          combinedEntities.filter((item) => {
            const duplicate = seen.has(item.id)
            seen.add(item.id)
            return !duplicate
          }) || []

        const channelsConverted = [
          ...channels.followed.map(
            (item: IChannel): IQuickSearchEntity => ({
              id: item.channel_id,
              name: item.username || 'Channel',
              type: 'following',
              functionToExecute: {
                function: () => {
                  window.switchFunctions.channel(item.channel_id)
                  return true
                },
                title: 'Open Channel'
              },
              is_own: false
            })
          ),
          ...channels.notFollowed.map(
            (item: IChannel): IQuickSearchEntity => ({
              id: item.channel_id,
              name: item.username || 'Channel',
              type: 'not following',
              functionToExecute: {
                function: () => {
                  window.switchFunctions.channel(item.channel_id)
                  return true
                },
                title: 'Open Channel'
              },
              is_own: false
            })
          )
        ]

        // Update state variables
        setUserId(userId)
        setProcessedGlobalSettings(processedSettings)
        setAllEntitiesForWorkspace(allEntitiesForWorkspace)
        setEntities(filteredEntities.reverse())
        setFetchedUserTags(tagsList)
        setWorkspaceDetails(workspaceDetails)
        setUserWorkspaces(userWorkspaces)
        setIsPrimaryWorkSpace(workspaceDetails.isPrimaryWorkSpace)
        setFetchedMemos(memos)
        setFetchedCollections(collections)
        setFetchedForecasts(forecasts)
        setFetchedScenariosList(scenariosList)
        setHomepageData(homepageData)
        setChannelsQuickSearch(channelsConverted)
        setFollowingChannelsContentPieces(followingContent)
        setFullFetchedIndicatorsParameters(
          indicators.filter((item) => item.source !== 'scenario')
        )
        setFetchedTrendlines(trendlines)
        setFetchedForecasts(forecasts)

        if (filteredEntities) {
          const ids: string[] = filteredEntities.map((item) => String(item.id))
          switchOnLoad(ids)
        }
      } catch (error) {
        setNoData(true)
        console.error(error)
        rollBackToHome()
      }
    }, [])

    const handleSwitchClick = (mode: 'prev' | 'next' | 'current') => {
      // ts-ignore
      const page =
        mode === 'prev'
          ? getPreviousPage()
          : mode === 'next'
            ? getNextPage()
            : getCurrentPage()

      if (!page) return null

      if (mode === 'prev') {
        window.currentPageIndex -= 1
      } else if (mode === 'next') {
        window.currentPageIndex += 1
      }

      const dontUpdate = mode === 'prev' || mode === 'next'

      switch (page.type) {
        case 'scenario':
          switchToScenarioById(String(page.fid), dontUpdate)
          break
        case 'indicator':
          switchToIndicatorById(String(page.fid), dontUpdate)
          break
        case 'collection':
          switchToCollectionById(Number(page.fid), dontUpdate)
          break
        case 'memo':
          switchToMemoById(String(page.fid), dontUpdate)
          break
        case 'trendline':
          switchToTrendlineById(String(page.fid), dontUpdate)
          break
        case 'calculated':
          switchToCalculatedIndicator(String(page.fid), dontUpdate)
          break
        case 'search':
          switchToSearch(String(page.fid), dontUpdate)
          break
        case 'upload':
          switchToUpload(dontUpdate)
          break
        case 'forecast':
          switchToForecastById(String(page.fid), dontUpdate)
          break
        case 'channel':
          swicthToChannel(String(page.fid), dontUpdate)
          break
        case 'external':
          switchToExternal(String(page.fid), dontUpdate)
          break
        case 'indicatorTrendlines':
          switchToIndicatorById(String(page.fid), dontUpdate, true)
          break
        case 'externalTrendlines':
          switchToExternal(String(page.fid), dontUpdate, true)
          break
        default:
          switchToHome(dontUpdate)
          break
      }
    }

    const handleCloseChat = () => {
      if (isPanelOpen) {
        setIsPanelOpen(false)
        // remove the url parameter
        const url = new URL(window.location.href)
        url.searchParams.delete('chat')
        window.history.pushState({}, '', url.toString())
      } else {
        setIsPanelOpen(true)
      }
    }

    const logout = () => {
      window.location.href = '/logout'
    }

    const load = async () => {
      setLoading(true)
      await fetchIntial()
      setLoading(false)
    }

    useEffect(() => {
      load()
    }, [])

    if (loading || noData) {
      return (
        <InitialLoadingScreen>
          <div className="sticky-header flex space-between ">
            <NextPrevChevrons
              handleNextClick={() => handleSwitchClick('next')}
              previousDisabled={window.currentPageIndex <= 1}
              nextDisabled={
                !window.visitedPages ||
                window.currentPageIndex >= window.visitedPages.length
              }
              handlePrevClick={() => handleSwitchClick('prev')}
            />
            <div className="flex flex-end">
              <button
                className="send-update no-btn no-hover icon flex flex-end t-large"
                onClick={handleCloseChat}
              >
                <IconQuestionMark />
              </button>
              <button
                className="send-update no-btn no-hover icon flex flex-end t-large"
                onClick={logout}
              >
                <IconLogout />
              </button>
            </div>
          </div>
        </InitialLoadingScreen>
      )
    }

    return (
      <Fragment>
        <div className="new-modal">
          <div
            className={`sticky-header flex space-between${
              isPrimaryWorkSpace ? ' primary-workspace' : ' secondary-workspace'
            }`}
          >
            <NextPrevChevrons
              handleNextClick={() => handleSwitchClick('next')}
              previousDisabled={window.currentPageIndex <= 1}
              nextDisabled={
                !window.visitedPages ||
                window.currentPageIndex >= window.visitedPages.length
              }
              handlePrevClick={() => handleSwitchClick('prev')}
            />
            {!isPrimaryWorkSpace && (
              <div className="flex gap-2 m-0">
                <h2 className="m-0 t-white">
                  {workspaceDetails && workspaceDetails.title} Workspace
                </h2>
                <FunctionalButton
                  initialButtonState="Revert"
                  iconUndoMode
                  className="t-white"
                  functionToExecute={defaultToPrimaryWorkspace}
                />
              </div>
            )}
            <div className="flex flex-end">
              <button
                className="send-update no-btn icon no-hover t-large"
                onClick={() => window.switchFunctions.search()}
              >
                <IconSearch />
              </button>
              <button
                className="send-update no-btn no-hover icon flex flex-end t-large"
                onClick={handleCloseChat}
              >
                <IconQuestionMark />
              </button>
              <button
                className="send-update no-btn no-hover icon flex flex-end t-large"
                onClick={logout}
              >
                <IconLogout />
              </button>
            </div>
          </div>
          {processedGlobalSettings && homepageData && isPrimaryWorkSpace && (
            <div
              style={{
                display: mode === 'home' ? 'block' : 'none'
              }}
              className="entity homepage"
            >
              <HomePage
                readonly={readerMode}
                userTags={fetchedUserTags}
                homepageData={homepageData}
                sections={processedGlobalSettings.homepage_trendlines}
                channelsContent={followingChannelsContentPieces}
                userWorkspaces={userWorkspaces}
                // fetchedScenarioData, fetchedAllIndicators, fullDates
              />
            </div>
          )}
          {!isPrimaryWorkSpace && mode === 'home' && (
            <WorkspaceHomepage entities={allEntitiesForWorkspace} />
          )}
          {mode === 'scenario' && (
            <ScenarioPage
              readonly={readerMode}
              resetMenus={resetModals}
              fid={chosenScenarioId}
              fetchedCollections={fetchedCollections}
              userTags={fetchedUserTags}
            />
          )}
          {mode === 'indicator' && (
            <IndicatorPage
              readonly={readerMode}
              indicatorId={chosenIndicatorId}
              userTags={fetchedUserTags}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
            />
          )}
          {mode === 'trendline' && (
            <TrendLinePage
              readonly={readerMode}
              trendlineId={chosenTrendlineId}
              userTags={fetchedUserTags}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
            />
          )}
          {mode === 'forecast' && (
            <Forecast
              readonly={readerMode}
              forecastId={chosenForecastId}
              userTags={fetchedUserTags}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
              scenarios={fetchedScenariosList}
            />
          )}
          {mode === 'calculated' && (
            <CalculatedIndicator
              readonly={readerMode}
              indicatorId={chosenCalculatedIndicator}
              userTags={fetchedUserTags}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
              scenarios={fetchedScenariosList}
            />
          )}
          {mode === 'channels' && (
            <ChannelsPage channels={channelsQuickSearch} />
          )}
          {mode === 'upload' && (
            <UploadPage
              fullFetchedIndicatorsParameters={fullFetchedIndicatorsParameters}
              refreshFunction={fetchIntial}
              singleIndicatorMode={{
                exists: false,
                indicatorId: ''
              }}
              mode="upload"
            />
          )}
          {mode === 'search' && (
            <SearchPage
              scenarios={fetchedScenariosList}
              channelsContent={followingChannelsContentPieces}
              indicators={fullFetchedIndicatorsParameters}
              collections={fetchedCollections}
              trendlines={fetchedTrendlines}
              forecast={fetchedForecasts}
              search={search}
              memos={fetchedMemos}
            />
          )}
          {mode === 'external' && (
            <ExternalPage
              readonly={readerMode}
              indicatorId={chosedExternalIndicatorId}
              userTags={fetchedUserTags}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
            />
          )}
          {mode === 'channel' && processedGlobalSettings && (
            <ChannelPage entities={entities} channelId={chosenChannelId} />
          )}
          {mode === 'collection' && (
            <CollectionPage
              channelContent={followingChannelsContentPieces}
              readonly={readerMode}
              memos={fetchedMemos}
              collectionId={chosenCollectionId}
              userTags={fetchedUserTags}
              scenarios={fetchedScenariosList}
              forecast={fetchedForecasts}
              indicators={fullFetchedIndicatorsParameters}
              collections={fetchedCollections}
              trendlines={fetchedTrendlines}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
            />
          )}
          {mode === 'memo' && (
            <MemoPage
              readonly={readerMode}
              memoId={chosenMemoId}
              scenarios={fetchedScenariosList}
              collections={fetchedCollections}
              resetMenus={resetModals}
              userTags={fetchedUserTags}
              favourites={fetchedCollections.find(
                (item: ICollection) =>
                  item.collection_name.toLowerCase() === 'favourites'
              )}
            />
          )}
        </div>
        <SidePanel
          entities={entities}
          channelsQuickSearch={channelsQuickSearch}
          openSettings={() => setModalUserSettings(true)}
          handleContextMenuSidePanel={handleContextMenuSidePanel}
          resetContextMenu={resetContextMenuSidePanel}
        />

        {modalCreateNew && (
          <MainAppCreateScenarioPopup
            onClose={() => setModalCreateNew(false)}
          />
        )}
        {modalCreateNewCollection && (
          <PopupModal
            isOpen={modalCreateNewCollection}
            onClose={() => setModalCreateNewCollection(false)}
            title="Create a New Collection"
            size="medium"
            handleSubmit={() =>
              createNewCollection({
                userId,
                collectionName: newCollectionName
              })
            }
            saveButtonExists
            saveButtonDisabled={newCollectionName.length === 0}
            noChanges
          >
            <div className="flex-row center middle">
              <label>Create a name</label>
              <input
                type="text"
                value={newCollectionName}
                onChange={(e) => {
                  setNewCollectionName(e.target.value)
                }}
              />
            </div>
          </PopupModal>
        )}
        {modalCreateNewWorkspace && (
          <PopupModal
            isOpen={modalCreateNewWorkspace}
            onClose={() => setModalCreateNewWorkspace(false)}
            title="Create a New Workspace"
            size="medium"
            handleSubmit={() => createWorkspace(newCollectionName)}
            saveButtonExists
            saveButtonDisabled={newCollectionName.length === 0}
            noChanges
          >
            <div className="flex-row center middle">
              <label>Create a name</label>
              <input
                type="text"
                value={newCollectionName}
                onChange={(e) => {
                  setNewCollectionName(e.target.value)
                }}
              />
            </div>
          </PopupModal>
        )}
        {modalCreateNewMemo && (
          <PopupModal
            isOpen={modalCreateNewMemo}
            onClose={() => setModalCreateNewMemo(false)}
            title="Create a New Memo"
            size="medium"
            handleSubmit={() => createNewMemo(newCollectionName)}
            saveButtonExists
            saveButtonDisabled={newCollectionName.length === 0}
            noChanges
          >
            <div className="flex-row center middle gap-2">
              <label>Create a name</label>
              <input
                type="text"
                value={newCollectionName}
                onChange={(e) => {
                  setNewCollectionName(e.target.value)
                }}
              />
            </div>
          </PopupModal>
        )}
        {modalCreateNewCalculatedIndicator && (
          <PopupModal
            isOpen={modalCreateNewCalculatedIndicator}
            onClose={() => setModalCreateNewCalculatedIndicator(false)}
            title={`New ${capitalise(newCalculatedSettings.computationMode)} ${capitalise(newCalculatedSettings.dataMode)} Indicator`}
            size="medium"
            handleSubmit={() =>
              createNewIndicatorCalculated(newCalculatedSettings)
            }
            saveButtonExists
            saveButtonDisabled={newCalculatedSettings.title.length === 0}
            noChanges
          >
            <div className="flex-row center middle gap-3">
              <label>Title: </label>
              <input
                type="text"
                value={newCalculatedSettings.title}
                onChange={(e) => {
                  setNewCalculatedSettings({
                    ...newCalculatedSettings,
                    title: e.target.value
                  })
                }}
              />
            </div>
          </PopupModal>
        )}
        {modalUserSettings && (
          <PopupModal
            isOpen={modalUserSettings}
            onClose={() => setModalUserSettings(false)}
            title="User Settings"
            size="large"
            noChanges
            handleSubmit={() =>
              window.saveGlobalSettings(window.globalSettings)
            }
            saveButtonExists
            refreshOnComplete={{
              exists: true,
              refreshFunction: () => {
                setModalUserSettings(false)
                window.location.reload()
              }
            }}
          >
            <GlobalSettings
              fullFetchedIndicatorsParameters={fullFetchedIndicatorsParameters}
              memos={fetchedMemos}
              scenarios={fetchedScenariosList}
            />
          </PopupModal>
        )}
        {modalUpdateUser && (
          <PopupModal
            isOpen={modalUpdateUser}
            onClose={() => {
              localStorage.setItem('isUserUpdatedOnRangesnew', 'true')
              setModalUpdateUser(false)
            }}
            title="Me again"
            size="medium"
            noChanges
            className="py-0"
            handleSubmit={() => {
              localStorage.setItem('isUserUpdatedOnRangesnew', 'true')
              setModalUpdateUser(false)
            }}
            saveButtonExists
          >
            <h2>Text here</h2>
          </PopupModal>
        )}
        <CustomContextMenu
          contextMenuSettings={contextMenuSidePanel}
          menu={[
            {
              onClick: () => {
                resetModals()
                if (isPrimaryWorkSpace) {
                  setModalCreateNewWorkspace(!modalCreateNewWorkspace)
                } else {
                  defaultToPrimaryWorkspace()
                }
              },
              title: isPrimaryWorkSpace ? 'Workspace' : 'Primary Workspace'
            },
            {
              onClick: () => {
                resetModals()
                setModalCreateNew(!modalCreateNew)
              },
              title: 'Scenario'
            },
            {
              onClick: () => {
                resetModals()
                window.switchFunctions.upload()
              },
              title: 'Indicator'
            },
            {
              onClick: () => {
                resetModals()
                setModalCreateNewCollection(!modalCreateNewCollection)
              },
              title: 'Collection'
            },
            {
              onClick: () => {
                resetModals()
                setModalCreateNewMemo(!modalCreateNewMemo)
              },
              title: 'Memo'
            },
            {
              onClick: () => {
                resetModals()
                setModalCreateNewCalculatedIndicator(
                  !modalCreateNewCalculatedIndicator
                )
              },
              title: 'Calculated Indicator',
              submenu: [
                {
                  onClick: () => {
                    resetModals()
                    setNewCalculatedSettings({
                      title: '',
                      dataMode: 'deviations',
                      computationMode: 'calculated'
                    })
                    setModalCreateNewCalculatedIndicator(
                      !modalCreateNewCalculatedIndicator
                    )
                  },
                  title: 'Deviations',
                  className: 'widest'
                },
                {
                  onClick: () => {
                    resetModals()
                    setNewCalculatedSettings({
                      title: '',
                      dataMode: 'values',
                      computationMode: 'calculated'
                    })
                    setModalCreateNewCalculatedIndicator(
                      !modalCreateNewCalculatedIndicator
                    )
                  },
                  title: 'Equation',
                  className: 'widest'
                },
                {
                  onClick: () => {
                    resetModals()
                    setNewCalculatedSettings({
                      title: '',
                      dataMode: 'values',
                      computationMode: 'offset'
                    })
                    setModalCreateNewCalculatedIndicator(
                      !modalCreateNewCalculatedIndicator
                    )
                  },
                  title: 'CAGR',
                  className: 'widest'
                },
                {
                  onClick: () => {
                    resetModals()
                    setNewCalculatedSettings({
                      title: '',
                      dataMode: 'values',
                      computationMode: 'delayed'
                    })
                    setModalCreateNewCalculatedIndicator(
                      !modalCreateNewCalculatedIndicator
                    )
                  },
                  title: 'Growth From an Initial Point',
                  className: 'widest'
                },
                {
                  onClick: () => {
                    resetModals()
                    setNewCalculatedSettings({
                      title: '',
                      dataMode: 'values',
                      computationMode: 'delayedTotal'
                    })
                    setModalCreateNewCalculatedIndicator(
                      !modalCreateNewCalculatedIndicator
                    )
                  },
                  title: 'Growth From an Initial Point (Total)',
                  className: 'widest'
                }
              ]
            }
          ]}
        />
        <CollapsibleSidePanel
          fullScreen={window ? window.innerWidth < 1024 : true}
          isOpen={isPanelOpen}
          onToggle={handleCloseChat}
          functionalButton={undefined}
        >
          <ChatbotComponent page="side" />
        </CollapsibleSidePanel>
      </Fragment>
    )
  } catch (error) {
    console.error(error)
    return <Spinner />
  }
}

export default React.memo(MainApp)
