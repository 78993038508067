import React, { useState } from 'react'
import { ReactTyped } from 'react-typed'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const MarketingSite = () => {
  const [options2] = useState({
    margin: 10,
    loop: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      }
    }
  })
  const [options] = useState({
    margin: 10,
    loop: true,
    autoplay: false,
    dots: true,
    lazyLoad: true,
    mouseDrag: false,
    touchDrag: false,
    autoplayHoverPause: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 1000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  })

  return (
    <div className="marketing-site col-12">
      <div className="scroll-up-btn">
        <i className="fas fa-angle-up"></i>
      </div>
      <nav className="navbar-marketing sticky">
        <div className="max-width">
          <div className="logo">
            <p className="hire about-img">
              <img src="/favicon.ico" alt="icon" width="50px" height="50px" />
              Chartit-360
            </p>
          </div>
          <ul className="menu about-img">
            <li>
              <a href="#home" className="menu-btn hire">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="menu-btn hire">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="menu-btn hire">
                Services
              </a>
            </li>
            <li>
              <a href="#skills" className="menu-btn hire">
                Advantages
              </a>
            </li>
            <li>
              <a href="#teams" className="menu-btn hire">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="menu-btn hire">
                Contact
              </a>
            </li>
          </ul>
          <div className="menu-btn">
            <i className="fas fa-bars"></i>
          </div>
        </div>
      </nav>

      <section className="home" id="home">
        <div className="max-width">
          <div className="home-content">
            <div className="text-1"></div>
            <div className="text-2">Chartit-360</div>
            <div className="text-3">
              This is{' '}
              <ReactTyped
                strings={[
                  'Data Visualization',
                  'Financial Analysis',
                  'Scenario Comparison'
                ]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </div>
            <br />
            <br />
            <a href="/app" className="hire about-img">
              Open Chartit-360
            </a>
          </div>
        </div>
      </section>

      <section className="about" id="about">
        <div className="max-width">
          <h2 className="title">About Chartit-360</h2>
          <div className="about-content">
            <div className="about-img">
              <p>
                Chartit-360 is a powerful online platform designed for financial
                analysts, investors, and traders to graphically depict market
                trends, analyze and visualize the performance of various
                indicators. This sophisticated tool empowers users to create
                dynamic scenarios, enabling a comprehensive understanding of
                market dynamics and potential investment outcomes.
                <br />
                <br />
                Key Features:
                <br />
                <br />
                Graphical Representation:
                <br />
                <br />
                The platform offers intuitive charting capabilities to visually
                represent movements and indicator values over time. Users can
                choose from a variety of chart types, including line charts,
                exponential charts, and bar charts, to customize the
                visualization based on their preferences.
                <br />
                <br />
                Scenario Analysis:
                <br />
                <br />
                Easily analyze scenarios by identifying peaks and troughs in
                market trends. The tool automatically detects and highlights
                periods of value increases and decreases, providing valuable
                insights into market sentiment. Users can build and customize
                scenarios by combining various indicators, allowing for a deeper
                understanding of market conditions.
                <br />
                <br />
                Indicator Insights:
                <br />
                <br />
                Evaluate the performance of financial indicators such as moving
                averages, Relative Strength Index (RSI), and others. Gain a
                deeper understanding of indicator crossovers, trend
                confirmations, and potential reversal points. The platform also
                includes a comprehensive library of indicators and allows users
                to craft personalized business indicators.
                <br />
                <br />
                Dynamic Collections:
                <br />
                <br />
                Users can create and save collections of indicators and
                scenarios for specific stocks or indices. This feature allows
                for easy comparison and analysis of different financial
                instruments within a single, cohesive interface. Organize these
                indicators into collections for more efficient data management
                and enhanced productivity.
                <br />
                <br />
                Collaborative Tools:
                <br />
                <br />
                Facilitate collaboration by sharing scenarios and collections
                with team members or other users. Enhance decision-making
                processes by discussing and analyzing financial market scenarios
                in a collaborative environment. Share your scenarios,
                trendlines, indicators, and memos with others, and stay updated
                by subscribing to expert channels.
                <br />
                <br />
                User-Friendly Interface:
                <br />
                <br />
                The platform features an easy-to-use interface for chart
                customization. Accessible to both beginners and experienced
                financial professionals, the interface prioritizes user-friendly
                interactions. Stay informed with real-time data updates,
                ensuring that analyses are based on the latest market
                information.
                <br />
                <br />
                AI Assistance:
                <br />
                <br />
                With AI-powered tools, users can enhance their analysis by
                automatically generating insights, performing comprehensive
                scenario evaluations, and analyzing indicators with a single
                click. Whether creating custom scenarios from descriptions or
                uploaded files, the platform leverages AI to save valuable time
                and simplify complex tasks. Users can quickly find relevant
                indicators using keyword search, streamlining their workflows
                and optimizing decision-making processes.
                <br />
                <br />
                Constant AI Interaction:
                <br />
                <br />
                One of the standout features of Chartit-360 is the continuous
                use of AI across the platform. Users can rely on AI to
                constantly assist in various tasks—whether for real-time data
                analysis, scenario creation, or even for predictive insights.
                This ongoing AI support enables financial professionals to save
                time, reduce manual analysis efforts, and achieve quicker, more
                accurate results, providing a clear advantage in the fast-paced
                world of finance.
                <br />
                <br />
                Real-time Data Integration:
                <br />
                <br />
                Stay informed with real-time data updates, ensuring that
                analyses are based on the latest market information. The
                Financial Scenario Visualizer is a versatile tool designed to
                transform complex financial data into clear, actionable
                visualizations. Whether you are a seasoned trader or a novice
                investor, this platform empowers you to make informed decisions
                by understanding the past, present, and potential future trends
                in the financial markets. Explore, analyze, and strategize with
                confidence using the Chartit-360.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="services" id="services">
        <div className="max-width">
          <h2 className="title" style={{ color: 'white' }}>
            Services
          </h2>
          <div className="serv-content">
            <OwlCarousel className="owl-theme" {...options2}>
              <div className="card about-img">
                <div className="box">
                  <img src="/indicator.png" width="310px" height="330px" />
                  <div className="text">Indicators</div>
                  <p>
                    Craft personalized business indicators, share insights, or
                    adopt proven metrics. Explore a comprehensive library of
                    indicators tailored to suit your analytical needs.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="calculated.png" width="310px" height="330px" />
                  <div className="text">Calculated Indicators</div>
                  <p>
                    Create advanced metrics by combining existing indicators
                    using flexible calculation methods. Contribute your insights
                    to a growing community of experts.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="external.png" width="400px" height="330px" />
                  <div className="text">External Indicators</div>
                  <p>
                    These are pre-built indicators available on our platform,
                    sourced from trusted external providers. Integrate them
                    seamlessly into your analysis for enhanced insights.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="scenario.png" width="310px" height="330px" />
                  <div className="text">Scenarios</div>
                  <p>
                    Build and customize scenarios by combining various
                    indicators. Effortlessly compare, modify, and refine them to
                    enhance your analysis and decision-making.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="gear.png" width="400px" height="330px" />
                  <div className="text">Settings Panel</div>
                  <p>
                    Customize chart lines, adjust frequency and aggregation
                    methods, and recalculate lines by combining them with other
                    indicators. Access all available settings in one place for
                    full control over your analysis.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="assistant.png" width="310px" height="330px" />
                  <div className="text">AI Assistance</div>
                  <p>
                    Create scenarios from custom descriptions or uploaded files.
                    Perform automatic, comprehensive analysis of scenarios and
                    indicators with a click. Find specific indicators quickly
                    using keyword search.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="stockbot.png" width="400px" height="330px" />
                  <div className="text">Stock Bot</div>
                  <p>
                    Your personal assistant for the stock market. Get insights,
                    analyze trends, and receive smart recommendations. Ask
                    questions, track stocks, and make informed decisions with
                    ease.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="dashboard.png" width="400px" height="330px" />
                  <div className="text">Dashboard</div>
                  <p>
                    Monitor your selected indicators, trendlines, and scenarios
                    in one place. Stay updated with content from subscribed
                    channels and track the latest market news effortlessly.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="collection.png" width="400px" height="330px" />
                  <div className="text">Collections</div>
                  <p>
                    Organize scenarios and indicators into collections for more
                    efficient data management. Enjoy a more convenient workflow
                    and enhanced productivity.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="tags.png" width="400px" height="330px" />
                  <div className="text">Tags</div>
                  <p>
                    Streamline your workflow with powerful tagging for scenarios
                    and indicators. Easily categorize, search, and organize
                    resources for efficient data management.
                  </p>
                </div>
              </div>
              {/* <div className="card about-img">
                <div className="box">
                  <img src="trendline.png" width="400px" height="330px" />
                  <div className="text">Trendlines</div>
                  <p>text for trendlines here</p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="forecast.jpg" width="400px" height="330px" />
                  <div className="text">Forecasts</div>
                  <p>text for foracasts here</p>
                </div>
              </div> */}
              <div className="card about-img">
                <div className="box">
                  <img src="channel.png" width="400px" height="330px" />
                  <div className="text">Channels</div>
                  <p>
                    Share your scenarios, trendlines, indicators, and memos with
                    others. Subscribe to channels, follow experts, and
                    conveniently stay updated on shared content.
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section className="skills" id="skills">
        <div className="max-width">
          <h2 className="title">Our Advantages</h2>
          <div className="skills-content">
            <div className="column left">
              <div className="text">
                Empowering Financial Excellence: Advanced Tools with AI
                Precision.
              </div>
              <p>
                Chartit-360 redefines financial analysis by combining intuitive
                visualization, advanced scenario-building tools, and AI-driven
                assistance to streamline your decision-making processes. Our
                platform offers real-time data integration, customizable chart
                options, and extensive performance insights into indicators,
                making financial analysis faster, smarter, and more
                collaborative.
                <br />
                <br />
                Unleash the power of AI for automatic scenario generation, data
                analysis, and smart recommendations, saving you valuable time
                and effort. Build custom scenarios, organize resources into
                collections, and share insights seamlessly through collaborative
                tools and expert channels. Whether you are a seasoned analyst or
                a beginner, our platform is tailored to provide unparalleled
                flexibility, productivity, and ease of use.
              </p>
              <a href="#" className="about-img">
                Try it
              </a>
            </div>
            <div className="column right">
              <div className="bars">
                <div className="info">
                  <span>Intuitive Visualization</span>
                </div>
              </div>

              <div className="line html"></div>
              <div className="bars">
                <div className="info">
                  <span>Scenario Analysis Capabilities</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>AI-Driven Insights</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>Dynamic Chart Customization</span>
                </div>
              </div>
              <div className="line html"></div>
              <div className="bars">
                <div className="info">
                  <span>Real-time Data Integration</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>Advanced Indicator Performance</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>Collaborative Tools & Channels</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>Organized Collections & Tagging</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>User-Friendly Interface</span>
                </div>
              </div>
              <div className="line html"></div>

              <div className="bars">
                <div className="info">
                  <span>Versatility for Various Financial Instruments</span>
                </div>
              </div>
              <div className="line html"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="teams" id="teams">
        <div className="max-width">
          <h2 className="title" style={{ color: 'white' }}>
            Team
          </h2>
          <OwlCarousel className="owl-theme" {...options}>
            <div className="item">
              <div className="card">
                <div className="box">
                  <div className="flex">
                    <div>
                      <h2 className="text px-2">Stan</h2>
                      <p className="px-2">Lead Developer</p>
                    </div>
                    <img src="/stan-photo.JPG" alt="" className="about-img" />
                  </div>
                  <br />
                  <p>
                    Stan is a full-stack developer with a passion for building
                    scalable, high-performance applications. Stan has worked on
                    a variety of projects, including web applications, mobile
                    apps, and desktop apps.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card">
                <div className="box">
                  <div className="flex">
                    <div>
                      <h2 className="text px-2">Andrey</h2>
                      <p className="px-2">Founder and CEO</p>
                    </div>
                    <img src="/placeholder.jpeg" alt="" className="about-img" />
                  </div>
                  <br />
                  <p>
                    Andrey is a seasoned professional with a passion for
                    financial analysis and data visualization. He has a keen
                    interest in developing innovative tools to help financial
                    professionals make better decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card">
                <div className="box">
                  <div className="flex">
                    <div>
                      <h2 className="text px-2">Alexey</h2>
                      <p className="px-2">Developer</p>
                    </div>
                    <img src="/alex-photo.jpg" alt="" className="about-img" />
                  </div>
                  <br />
                  <p>
                    Alexey is a software engineer from Ukraine with a curiosity
                    for solving complex problems. He has experience in
                    developing web applications in React and is passionate about
                    learning new technologies.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="max-width">
          <h2 className="title">Contact us</h2>
          <div className="contact-content">
            <div className="column left">
              <div className="text">Get in Touch</div>
              <p></p>
              <div className="icons">
                <div className="row">
                  <i className="fas fa-user"></i>
                  <div className="info">
                    <div className="sub-title">Stan Huseletov</div>
                  </div>
                </div>
                <div className="row">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="info">
                    <div className="sub-title">London, UK</div>
                  </div>
                </div>
                <div className="row">
                  <i className="fas fa-envelope"></i>
                  <div className="info">
                    <div className="sub-title">alexey@chartit360.com</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column right">
              <div className="text">Message us</div>
              <form action="https://formspree.io/f/mwkgezgz" method="POST">
                <div className="fields">
                  <div className="field name">
                    <input
                      type="text"
                      placeholder="Name"
                      name="Name"
                      required
                    />
                  </div>
                  <div className="field email">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email_id"
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <input
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    required
                  />
                </div>
                <div className="field textarea">
                  <textarea
                    placeholder="Message..."
                    name="message"
                    required
                  ></textarea>
                </div>
                <div className="button">
                  <button type="submit">Send message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <span>
          <span className="far fa-copyright"></span>
        </span>
      </footer>

      {/* <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" /> */}
      {/* <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script> */}
      {/* <script type="text/javascript">
    window.onload = function() { Calendly.initBadgeWidget({ url: 'https://calendly.com/pulkit-singh/meet-with-pulkit', text: 'Schedule a meet with him', color: '#fa0000', textColor: '#ffffff' }); }
    </script> */}
    </div>
  )
}

export default MarketingSite
