import { Checkbox } from 'pretty-checkbox-react'
import React from 'react'
import { IAllIndicatorsTableDisplay } from '../utils/interfaces'

import { capitalise } from '../utils/functions'
import { FunctionalButton } from './_components'

const AllIndicatorsTableDisplay = ({
  updateSelected,
  selection,
  allIndicators,
  scenarioSpecifcAddRemoveButtons
}: IAllIndicatorsTableDisplay) => {
  selection = selection.map((item) =>
    item.type === 'actuals'
      ? {
          ...item,
          type: 'indicator'
        }
      : item
  )
  const GROUPS = [
    'calculated',
    'forecast',
    'indicator',
    'external',
    'trendline'
  ].filter((type) => selection.some((item) => item.type === type))

  return (
    <ol className="column-parent">
      {GROUPS.map((type) => {
        const selected = selection.filter((item) => item.type === type)
        const checkeOfType = selected.filter((item) => item.chart)

        return (
          <li key={type + '-type'}>
            <h3 className="col-12 flex gap-2">
              <span>
                {capitalise(type)}{' '}
                <span className="small disabled">({checkeOfType.length})</span>
              </span>
            </h3>

            {selected.map((selected, index) => {
              const matchedIndicator = allIndicators.find(
                (item) => item.fid === selected.fid
              )
              if (!matchedIndicator) return null

              return (
                <ol key={selected.fid + '_' + index + '-data-display-checkbox'}>
                  <li className="flex gap-2 col-12">
                    {scenarioSpecifcAddRemoveButtons && (
                      <FunctionalButton
                        initialButtonState={'Remove'}
                        functionToExecute={() =>
                          scenarioSpecifcAddRemoveButtons.remove(selected.fid)
                        }
                        iconMinusMode
                      />
                    )}
                    <div className="flex middle center">
                      <Checkbox
                        checked={selected.chart}
                        onChange={() =>
                          updateSelected(selected.fid, !selected.chart)
                        }
                        color="success-o"
                        animation="pulse"
                        shape="curve"
                      >
                        Show/hide
                      </Checkbox>
                      <button
                        className="no-btn no-hover no-border auto p-0"
                        onClick={() =>
                          selected.type === 'calculated'
                            ? window.switchFunctions.calculated(selected.fid)
                            : selected.type === 'forecast'
                              ? window.switchFunctions.forecast(selected.fid)
                              : selected.type === 'trendline'
                                ? window.switchFunctions.trendline(selected.fid)
                                : window.switchFunctions.indicator(selected.fid)
                        }
                      >
                        {matchedIndicator.title}
                      </button>
                    </div>
                  </li>

                  {scenarioSpecifcAddRemoveButtons &&
                    matchedIndicator.type !== 'external' && (
                      <li className="flex gap-2 col-12">
                        <span
                          style={{
                            margin: '0px 0 0 -10px'
                          }}
                        >
                          <FunctionalButton
                            functionToExecute={() =>
                              scenarioSpecifcAddRemoveButtons.generateTrendline(
                                selected.fid
                              )
                            }
                            className="no-btn no-hover wider"
                            doesReset
                            initialButtonState={'Generate Trendline'}
                            iconPlusMode
                            combinedButtonTitle
                          />
                        </span>
                      </li>
                    )}
                </ol>
              )
            })}
          </li>
        )
      })}
    </ol>
  )
}

export default AllIndicatorsTableDisplay
