import React from 'react'
import { IQuickSearchEntity } from '../utils/interfaces'
import QuickSearch from '../helperComponents/QuickSearch'
import { EntityHeader } from '../helperComponents/_components'

export default function ChannelsPage({
  channels
}: {
  channels: IQuickSearchEntity[]
}) {
  return (
    <div className="entity channel default-text">
      <EntityHeader
        subtitle="All Channels"
        ownerInfo={{ profileImage: '', username: '' }}
        title={'Channels'}
        description={'Here you can find all the channels you have access to.'}
      />
      <div className="entity-blocks">
        <div className="container w-12 fit">
          <div></div>
          <div>
            <div className="p-2">
              <QuickSearch entities={channels} typeSwitcher={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
