import { compareDates, isEmpty } from '../utils/functions'
import {
  IGoogleChartWithRangeFilterData,
  ITreeChartDataInput
} from '../utils/interfaces'
import { transformRawDataToGoogleChartInputData } from '../utils/transformingData'

const findMaxChartIndex = (date: string | Date, dates: (string | Date)[]) => {
  const maxIndexArray = [
    dates.findIndex((d) => compareDates(date, d)),
    dates.length
  ]
    .filter((item) => item !== -1)
    // .reverse()
    .sort((a, b) => b - a)

  return maxIndexArray.length === 1
    ? maxIndexArray[0]
    : maxIndexArray[0] - dates.length <= 1
      ? maxIndexArray[0]
      : maxIndexArray[1] - dates.length <= 1
        ? maxIndexArray[1]
        : dates.length
}

const findMinChartIndex = (date: string | Date, dates: (string | Date)[]) => {
  const index = dates.findIndex((d) => compareDates(date, d))
  return index === -1 ? 0 : index
}

const findChartRangeBoundaries = (
  range: string[],
  dates: (string | Date)[]
) => {
  return [
    findMinChartIndex(range[0], dates),
    findMaxChartIndex(range[1], dates)
  ]
}

const findSelectedChartRangeBoundaries = (
  range: string[],
  dates: (string | Date)[]
) => {
  // if the last item is selected, we need to show the last item
  const min = findMinChartIndex(range[0], dates)
  const max = findMinChartIndex(range[1], dates)
  if (max + 1 === dates.length) {
    return [min, max + 1]
  }
  return [min, max]
}

function convertJsonToTreeChartData(
  json: ITreeChartDataInput | string,
  dataOptions: {
    titles: {
      name: string
      title: string
    }[]
  }
) {
  const inputData = typeof json === 'string' ? (json = JSON.parse(json)) : json

  let stringifiedJSON = JSON.stringify(inputData)

  dataOptions.titles.forEach((title) => {
    stringifiedJSON = stringifiedJSON.replace(title.name, title.title)
  })
  const parsedJSON = JSON.parse(stringifiedJSON)

  const chartData: any = []
  // Helper function to traverse the tree
  function traverse(node: ITreeChartDataInput, parentId: string | null) {
    if (node.id) {
      // Add the current node with its parent to the chart data
      chartData.push([node.id, parentId || ''])
    }
    // Traverse children if they exist
    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => traverse(child, node.id))
    }
  }
  // Start traversing from the root
  traverse(parsedJSON, null)
  return chartData
}

const calculateAAGR = (b: number) => {
  // Calculate the monthly growth factor
  const G_monthly = Math.exp(b)
  // Calculate the annual growth factor
  const G_annual = Math.pow(G_monthly, 12)
  // Calculate the AAGR as a percentage
  const AAGR = (G_annual - 1) * 100

  return AAGR
}

const findFirstVaueIndex = (data: any[], idToCheck: string) =>
  data.findIndex((item) => !isEmpty(item[idToCheck]) && !isNaN(item[idToCheck]))

const findLastIndex = (data: any[], idToCheck: string) =>
  data.length -
  1 -
  data
    .slice()
    .reverse()
    .findIndex((item) => !isEmpty(item[idToCheck]) && !isNaN(item[idToCheck]))

const cutOffNulls = (data: any[], idToCheck: string) => {
  const firstValueIndex = findFirstVaueIndex(data, idToCheck)
  const lastValueIndex = findLastIndex(data, idToCheck)

  return data.slice(firstValueIndex, lastValueIndex + 1)
}

const getTransformedKeys = (keys: any, chartData: any) => {
  if (!keys || !chartData || !chartData.titles) return []
  return keys.map((key: any) => {
    const matchingTitle = chartData.titles.find(
      (titleObj: any) => Object.keys(titleObj)[0] === key
    )
    return matchingTitle ? matchingTitle[key] : key
  })
}

const getRelativeChart = ({
  chartData,
  chart
}: {
  chartData: IGoogleChartWithRangeFilterData
  chart: any
}) => {
  try {
    const newTransformedData = transformRawDataToGoogleChartInputData({
      inputData: chartData,
      mode: 'relativeToFirstValue'
    })

    const keysToTransform = newTransformedData[0]
    const transformedKeys = getTransformedKeys(keysToTransform, chartData)

    const data = [transformedKeys, ...newTransformedData.slice(1)]

    return data
  } catch (e) {
    console.error(e)
    return chartData
  }
}

const getAbsoluteChart = ({
  chartData
}: {
  chartData: IGoogleChartWithRangeFilterData
}) => {
  const newTransformedData = transformRawDataToGoogleChartInputData({
    inputData: { ...chartData },
    mode: 'absolute'
  })

  const keysToTransform = newTransformedData[0]
  const transformedKeys = getTransformedKeys(keysToTransform, chartData)

  const data = [transformedKeys, ...newTransformedData.slice(1)]

  return data
}

const getDifferencesChart = ({
  chartData
}: {
  chartData: IGoogleChartWithRangeFilterData
}) => {
  const data = transformRawDataToGoogleChartInputData({
    inputData: { ...chartData },
    mode: 'differences'
  })

  return data
}

export {
  convertJsonToTreeChartData,
  findChartRangeBoundaries,
  findMinChartIndex,
  findMaxChartIndex,
  calculateAAGR,
  findSelectedChartRangeBoundaries,
  cutOffNulls,
  getRelativeChart,
  getAbsoluteChart,
  getDifferencesChart,
  getTransformedKeys
}
