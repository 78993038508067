import React from 'react'
import * as pdfjsLib from 'pdfjs-dist'
import mammoth from 'mammoth'

const FileUploadComponent = ({
  handleSendFileContent
}: {
  handleSendFileContent: ({
    title,
    content
  }: {
    title: string
    content: string
  }) => void
}) => {
  const handleTxtFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const text = e.target?.result as string
      handleSendFileContent({
        title: file.name,
        content: text
      })
    }
    reader.readAsText(file)
  }

  const handlePdfFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      const typedarray = new Uint8Array(e.target?.result as ArrayBuffer)
      const loadingTask = pdfjsLib.getDocument({ data: typedarray })
      const pdf = await loadingTask.promise

      // Extract metadata for title
      const metaData = await pdf.getMetadata()
      const titleFromMetadata = metaData.info.toLocaleString() || file.name

      let textContent = ''
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i)
        const textContentItems = await page.getTextContent()
        const pageText = textContentItems.items
          .map((item: any) => item.str)
          .join(' ')
        textContent += pageText + '\n'
      }

      handleSendFileContent({
        title: titleFromMetadata,
        content: textContent
      })
    }
    reader.readAsArrayBuffer(file)
  }

  const handleWordFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer
      const result = await mammoth.extractRawText({ arrayBuffer })

      handleSendFileContent({ title: file.name, content: result.value })
    }
    reader.readAsArrayBuffer(file)
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const fileName = file.name
      const extension = fileName.split('.').pop()?.toLowerCase()
      if (extension === 'txt') {
        handleTxtFile(file)
      } else if (extension === 'pdf') {
        handlePdfFile(file)
      } else if (extension === 'docx' || extension === 'doc') {
        handleWordFile(file)
      } else {
        alert('Unsupported file type')
      }
    }
  }

  const clearFile = () => {
    handleSendFileContent({ title: '', content: '' })
    // clear file input
    const fileInput = document.querySelector('input[type="file"]')
    if (fileInput) {
      ;(fileInput as HTMLInputElement).value = ''
    }
  }

  return (
    <div className="flex gap-2">
      <input
        type="file"
        accept=".pdf,.txt,.docx"
        onChange={handleFileUpload}
        className="widest small inline"
      />
      <button className="destructive small inline" onClick={clearFile}>
        Clear
      </button>
    </div>
  )
}

export default FileUploadComponent
