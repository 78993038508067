import React, { useState } from 'react'
import { IMemo, IMemoNote } from '../utils/interfaces'
import { FunctionalButton } from './_components'
import { createMemoNote, deleteMemoNote } from '../utils/fetch'

interface CreateUpdateCardProps {
  onCreate: (note: IMemoNote) => Promise<boolean> // Function to handle the creation
  memos: IMemo[] // Existing notes
}

const CreateUpdateCard: React.FC<CreateUpdateCardProps> = ({
  onCreate,
  memos
}) => {
  const [memoId, setMemoId] = useState<string>('')

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const handleCreate = async () => {
    if (title && memoId) {
      const create = await onCreate({
        memoId,
        date: new Date().toUTCString(),
        title,
        description,
        id: 0
      })
      if (!create) {
        return false
      }
      setMemoId('')
      setTitle('')
      setDescription('')
      return true
    } else {
      return false
    }
  }

  return (
    <div className="w-full bg-white shadow-sm hover:shadow-md transition-shadow duration-300 rounded-lg p-4 transform transition-transform duration-300 no-border">
      <div className="flex gap-3 space-y-3 align-middle items-center">
        <select value={memoId} onChange={(e) => setMemoId(e.target.value)}>
          <option value="">Select a memo</option>
          {memos.map((memo, index) => (
            <option key={index} value={memo.fid}>
              {memo.title}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Note Title"
          className="m-0"
        />
        <FunctionalButton
          functionToExecute={handleCreate}
          initialButtonState={'Create Note'}
          disabled={!title || !memoId}
          className="primary wide m-0"
          doesReset
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Note Content"
          rows={3}
          className="w-full px-3 py-2 text-sm"
        />
      </div>
    </div>
  )
}

// Individual Note Card
const NoteCard: React.FC<{ note: IMemoNote; memo: IMemo | undefined }> = ({
  note,
  memo
}) => {
  return (
    <div className="w-full flex items-center justify-between bg-white shadow-sm hover:shadow-md transition-shadow duration-300 rounded-lg p-2  transform  transition-transform duration-300">
      <div className="flex flex-col justify-between col-8 items-start">
        <h4 className="text-lg font-semibold text-gray-800">
          {note.title} - <i>{memo?.title}</i>
        </h4>
        <p className="text-sm text-gray-600">{note.description}</p>
      </div>
      <div className="flex flex-col justify-between col-4 items-end">
        <p className="text-xs text-gray-400 mt-2">
          {new Date(note.date).toUTCString()}
        </p>
        <FunctionalButton
          className="primary wide text-sm px-3 py-1 bg-teal hover:bg-teal_dark text-white rounded-md shadow-sm hover:shadow-md transition-shadow duration-300"
          initialButtonState={'View Memo'}
          doesReset
          functionToExecute={() => window.switchFunctions.memo(note.memoId)}
        />
        <br />
        {memo?.is_own && (
          <FunctionalButton
            className="destructive wide text-sm px-3 py-1 bg-teal hover:bg-teal_dark text-white rounded-md shadow-sm hover:shadow-md transition-shadow duration-300"
            initialButtonState={'Delete Note'}
            doesReset
            functionToExecute={() => deleteMemoNote(note)}
          />
        )}
      </div>
    </div>
  )
}

// Notes Feed
const UpdatesFeed: React.FC<{ memos: IMemo[]; isOwn: boolean }> = ({
  memos,
  isOwn
}) => {
  const [notes, setNotes] = useState(
    (
      memos
        .map((memo: IMemo) => memo.updates)
        .filter((updates: IMemoNote[] | null) => updates !== null)
        .flat()
        .filter((note: IMemoNote | null) => note !== null) as IMemoNote[]
    ).sort(
      (a: IMemoNote, b: IMemoNote) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    )
  )

  const onCreateNote = async (note: IMemoNote) => {
    const added = await createMemoNote(note)

    if (!added) {
      return false
    }

    setNotes([note, ...notes])

    return true
  }

  return (
    <div className="space-y-4 px-4 py-10 no-border">
      {isOwn && <CreateUpdateCard onCreate={onCreateNote} memos={memos} />}
      {notes.map((note) => (
        <NoteCard
          key={note.memoId}
          note={note}
          memo={memos.find((memo) => memo.fid === note.memoId)}
        />
      ))}
    </div>
  )
}

export default UpdatesFeed
