import React, { useEffect, useMemo, useState } from 'react'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IEquationPiece,
  ITrendline,
  ICombinedIndicatorFormulaAlternativeEquationBlocks
} from '../utils/interfaces'
import { FunctionalButton, SearchableSelect, ToggleInfo } from './_components'
import { checkEquationValidity } from '../utils/functions'
import { Icon3Dots } from './Icons'
import {
  checkCalcEquationChanges,
  transformEquationPiecesIntoStringEquation,
  transformStringEquationIntoEquationPieces
} from '../utils/transformingData'
import Instructions from '../charts/helperComponents/blocks'
import { AggregationMethod } from '../charts/interfaces'

const CombineIndicatorFormula = ({
  indicators,
  indicatorId,
  refreshFunction,
  updateEquationFunction,
  customFunctionalButton,
  // computationMode,
  changes,
  alternativeMode,
  updateEquationInParent,
  children
  // dataMode
}: {
  indicators: (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
  indicatorId: string
  updateEquationInParent?: (
    equation?: IEquationPiece[],
    alternativeEquation?: IEquationPiece[]
  ) => boolean
  refreshFunction: () => void
  customFunctionalButton?: {
    iconCalculate?: boolean
    functionToExecute: (
      equation: IEquationPiece[],
      alternativeEquation: IEquationPiece[]
    ) => Promise<boolean>
    title: string
    className?: string
    children?: React.ReactNode
  }
  updateEquationFunction: (equation: IEquationPiece[]) => Promise<boolean>
  alternativeMode?: {
    mode: 'ab'
    indicatorsDict: ICombinedIndicatorFormulaAlternativeEquationBlocks
    equation: IEquationPiece[]
  }
  children?: React.ReactNode
  computationMode?: string
  changes?: boolean
  dataMode?: string
}) => {
  try {
    const OPERATIONS = ['+', '-', '*', '/', '(', ')']
    const [error, setError] = useState('')
    const [info, setInfo] = useState('')

    const [alternativeModeEquation, setAlternativeModeEquation] = useState(
      alternativeMode === undefined
        ? ''
        : transformEquationPiecesIntoStringEquation(
            alternativeMode ? alternativeMode.equation : [],
            alternativeMode!.indicatorsDict
          ).equation
    )

    const [equation, setEquation] = useState<IEquationPiece[]>(
      alternativeMode ? alternativeMode.equation : []
    )
    const [renderedEquation, setRenderedEquation] = useState<any>(null)
    const [modalPieceSettings, setModalPieceSettings] =
      useState<IEquationPiece | null>(null)
    const [showCAGR, setShowCAGR] = useState<string[]>([])

    const indicatorsNames = useMemo(() => {
      try {
        return indicators
          .filter((indicator) => indicator !== undefined && indicator !== null)
          .filter(
            (indicator) =>
              indicator.fid !== indicatorId &&
              (Object.keys(indicator).includes('base_indicator')
                ? (indicator as any).base_indicator !== indicatorId
                : true)
          )
          .map((indicator) => ({
            value: indicator.fid,
            label: indicator.title || ''
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      } catch (error) {
        return []
      }
    }, [indicators, alternativeMode?.equation, alternativeMode?.indicatorsDict])

    const insertIntoEquationNew = ({
      value,
      positionIndex,
      mode = 'values',
      type,
      offset = 0,
      cagr = 0,
      aggregationMethod = 'first'
    }: {
      value: string
      positionIndex: number
      type: string
      mode?: string
      offset?: number
      cagr?: number
      aggregationMethod?: AggregationMethod
    }) => {
      if (positionIndex === -1) {
        positionIndex = equation.length
      }
      const newToken: IEquationPiece = {
        value,
        type,
        offset,
        mode,
        cagr,
        aggregationMethod
      }
      const newEquation = [...equation]
      newEquation[positionIndex] = newToken

      setEquation(newEquation)
      setModalPieceSettings(null)
    }

    const evaluateIfIndicatorIsExternal = (indicatorId: string) => {
      const indicator = indicators.find((_) => _.fid === indicatorId)

      if (!indicator || indicator.category === 'FRED') {
        return true
      }

      return false
    }

    const evaluateTypeOfIndicator = (indicatorId: string) =>
      evaluateIfIndicatorIsExternal(indicatorId) ? 'external' : 'indicator'

    const removeLast = () => {
      const newEquation = [...equation]
      newEquation.pop()
      setEquation(newEquation)
    }

    const refreshAlternativeModeEquation = () => {
      if (!alternativeMode) return
      if (!updateEquationInParent) return

      updateEquationInParent(
        equation,
        transformStringEquationIntoEquationPieces(
          alternativeModeEquation,
          alternativeMode.indicatorsDict
        ).pieces
      )
    }

    const addIndicator = () => {
      if (indicatorsNames.length === 0) {
        return
      }
      insertIntoEquationNew({
        value: indicatorsNames[0].value,
        positionIndex: equation.length,
        type: evaluateTypeOfIndicator(indicatorsNames[0].value)
      })
    }

    const clearEquation = () => {
      setEquation([])
    }

    const handleUpdate = async (
      equation: IEquationPiece[],
      alternativeEquation: string
    ) => {
      if (!alternativeMode && !updateEquationFunction) {
        return false
      }

      if (alternativeMode !== undefined) {
        // custom alternative checks
        if (alternativeModeEquation === '') {
          setError('Please enter an equation')
          return false
        }

        const checked = transformStringEquationIntoEquationPieces(
          alternativeEquation,
          alternativeMode.indicatorsDict
        )

        if (!checked || checked.errorCount > 0) {
          const joinedErrors = checked.errors.join(';\n\n')
          setError(joinedErrors)

          return false
        }

        const res = updateEquationFunction(checked.pieces)

        return res
      } else if (
        !checkEquationValidity(
          equation,
          indicatorsNames.map((_) => _.value),
          setError,
          OPERATIONS
        )
      ) {
        return false
      }

      if (!updateEquationFunction) {
        return false
      }

      const res = await updateEquationFunction(equation)

      return res
    }

    const renderUpdatedEquation = (newEquation: IEquationPiece[]) => {
      const eq = newEquation.map((token, index) => {
        if (token.type === 'operator') {
          return (
            <select
              key={index}
              className="operator"
              defaultValue={token.value}
              onChange={(e) =>
                insertIntoEquationNew({
                  value: e.target.value,
                  positionIndex: index,
                  type: 'operator'
                })
              }
            >
              {OPERATIONS.map((operation, idx) => (
                <option key={idx} value={operation}>
                  {operation}
                </option>
              ))}
            </select>
          )
        } else if (token.type === 'number') {
          return (
            <input
              key={index}
              type="number"
              className="narrower"
              value={Number(token.value)}
              onChange={(e) =>
                insertIntoEquationNew({
                  value: e.target.value,
                  positionIndex: index,
                  type: 'number'
                })
              }
            />
          )
        } else {
          return (
            <div className="indicator-box" key={index}>
              <SearchableSelect
                defaultValue={token.value}
                options={indicatorsNames}
                onChange={(value: any) => {
                  const current = equation[index]
                  if (current.value === value) {
                    return
                  }

                  insertIntoEquationNew({
                    value,
                    positionIndex: index,
                    type: evaluateTypeOfIndicator(value)
                  })
                }}
                // defaultValue={token.value}
                width={150}
                className="indicator"
              />
              <div className="settings">
                <button
                  className="flex justify-center"
                  onClick={() => setModalPieceSettings(token)}
                >
                  <Icon3Dots />
                </button>
                <span>
                  {token.cagr > 0 && `CAGR of ${token.cagr}   `}
                  {token.offset > 0 && `Offset by ${token.offset}`}
                  {token.mode !== 'values_absolute' && <br />}
                  {token.mode !== 'values_absolute' &&
                    token.mode?.split('_')[1] &&
                    ` Aggregated ${token.mode?.split('_')[1]}`}
                  {token.aggregationMethod !== 'first' && <br />}
                  {token.aggregationMethod !== 'first' &&
                    ` by ${token.aggregationMethod}`}
                </span>
              </div>
            </div>
          )
        }
      })

      setError('')
      setEquation(newEquation)
      setRenderedEquation(eq)
    }

    const updateEquation = (newEquation: IEquationPiece[]) => {
      try {
        renderUpdatedEquation(newEquation)
      } catch (error) {
        if (typeof newEquation === 'string') {
          newEquation = JSON.parse(newEquation)
          try {
            renderUpdatedEquation(newEquation)
          } catch (error) {
            setError('Invalid equation')
          }
        }
      }
    }

    // const checkIfEquationHasFid = (element: string) => {

    const resetEquation = () => {
      setRenderedEquation(null)
      setEquation(alternativeMode ? alternativeMode.equation : [])
      setAlternativeModeEquation('')
    }

    useEffect(() => {
      updateEquation(equation)
      updateEquationInParent && updateEquationInParent(equation, undefined)
    }, [equation])

    useEffect(() => {
      updateEquation(alternativeMode ? alternativeMode.equation : [])
    }, [alternativeMode ? alternativeMode.equation : []])

    useEffect(() => {
      setError('')
      updateEquation(alternativeMode ? alternativeMode.equation : [])
      updateEquationInParent &&
        updateEquationInParent(
          undefined,
          transformStringEquationIntoEquationPieces(
            alternativeModeEquation,
            alternativeMode!.indicatorsDict
          ).pieces
        )
    }, [alternativeModeEquation])

    useEffect(() => {
      if (error !== '' && $('.equation-display').html() === '') {
        setInfo('Try reseting the equation')
      } else {
        setInfo('')
      }
    }, [error])

    // useEffect(() => {
    //   if (alternativeMode !== undefined) {
    //     setAlternativeModeEquation(
    //       transformEquationPiecesIntoStringEquation(
    //         existingEquation,
    //         alternativeMode.indicatorsDict
    //       ).equation
    //     )
    //   }
    // }, [existingEquation])

    return (
      <div className="combine-indicator-formula">
        {!alternativeMode && (
          <div className="operation-buttons">
            <div className="control-buttons">
              <FunctionalButton
                className="secondary"
                functionToExecute={addIndicator}
                noReturn
                iconPlusMode
                doesReset
                combinedButtonTitle
                initialButtonState="Item"
              />
              <FunctionalButton
                className="secondary"
                functionToExecute={() =>
                  insertIntoEquationNew({
                    value: '+',
                    positionIndex: equation.length,
                    type: 'operator'
                  })
                }
                noReturn
                iconPlusMode
                doesReset
                initialButtonState="Operator"
                combinedButtonTitle
              />
              <FunctionalButton
                className="secondary"
                functionToExecute={() =>
                  insertIntoEquationNew({
                    value: '0',
                    positionIndex: equation.length,
                    type: 'number'
                  })
                }
                noReturn
                iconPlusMode
                doesReset
                initialButtonState="Number"
                combinedButtonTitle
              />
              <FunctionalButton
                className="destructive"
                functionToExecute={removeLast}
                noReturn
                iconMinusMode
                initialButtonState="Back"
                combinedButtonTitle
                doesReset
              />
              <FunctionalButton
                className="destructive"
                functionToExecute={clearEquation}
                noReturn
                iconMinusMode
                doesReset
                initialButtonState="Clear"
                combinedButtonTitle
              />
            </div>
          </div>
        )}
        {error !== '' && (
          <span className="banner-strip small danger">{error}</span>
        )}
        {info !== '' && <span className="banner-strip small info">{info}</span>}
        {!alternativeMode && (
          <>
            <div className="equation-display">{renderedEquation}</div>
            {modalPieceSettings !== null && equation && equation.length > 0 && (
              <div className="flex flex-col col-12 gap-2 p-1">
                <div className="flex col-12">
                  <p className="m-0">CAGR of</p>
                  <input
                    type="number"
                    className="narrowest inline"
                    value={modalPieceSettings.cagr}
                    onChange={(e) =>
                      setModalPieceSettings({
                        ...modalPieceSettings,
                        cagr: Number(e.target.value)
                      })
                    }
                  />
                  <p className="m-0">Offset by</p>
                  <input
                    type="number"
                    className="narrowest inline"
                    value={modalPieceSettings.offset}
                    onChange={(e) =>
                      setModalPieceSettings({
                        ...modalPieceSettings,
                        offset: Number(e.target.value)
                      })
                    }
                  />
                </div>
                <div className="flex col-12 gap-2">
                  <select
                    className="inline m-0"
                    value={modalPieceSettings.mode?.split('_')[1]}
                    onChange={async (e) => {
                      let changed = e.target.value

                      if (changed === 'values_absolute') {
                        changed = 'absolute'
                      }

                      setModalPieceSettings({
                        ...modalPieceSettings,
                        mode: 'values_' + changed
                      })
                    }}
                  >
                    <option value="absolute">Absolute</option>
                    <option value="percentChangeFromYear">
                      Percent Change from Year
                    </option>
                    <option value="changeAndPercentChangeFromYear">
                      Change from Year
                    </option>
                    <option value="monthlyPercentChange">
                      Monthly Percent Change
                    </option>
                    <option value="annualCompoundedRate">
                      Annual Compounded Rate
                    </option>
                    <option value="continuousRate">Continuous Rate</option>
                    <option value="continuousAnnualRate">
                      Continuous Annual Rate
                    </option>
                    <option value="indexScaled">Index Scaled</option>
                  </select>
                  <select
                    className="inline m-0"
                    value={modalPieceSettings.aggregationMethod}
                    onChange={async (e) => {
                      const changed = e.target.value as AggregationMethod

                      setModalPieceSettings({
                        ...modalPieceSettings,
                        aggregationMethod: changed
                      })
                    }}
                  >
                    <option value="average">Average</option>
                    <option value="sum">Sum</option>
                    <option value="first">First of Period</option>
                    <option value="last">Last of Period</option>
                  </select>
                </div>
                <div className="flex col-12 gap-2 justify-center">
                  <FunctionalButton
                    functionToExecute={() => {
                      insertIntoEquationNew({
                        value: modalPieceSettings.value,
                        positionIndex: equation.findIndex(
                          (token) => token.value === modalPieceSettings.value
                        ),
                        type: evaluateTypeOfIndicator(modalPieceSettings.value),
                        mode: modalPieceSettings.mode,
                        offset: modalPieceSettings.offset,
                        cagr: modalPieceSettings.cagr,
                        aggregationMethod: modalPieceSettings.aggregationMethod
                      })
                      setModalPieceSettings(null)

                      return true
                    }}
                    className="primary scale-75"
                    iconSaveMode
                    combinedButtonTitle
                    initialButtonState={'Save'}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {alternativeMode !== undefined && (
          <div className="flex flex-wrap col-12">
            {Object.entries(alternativeMode.indicatorsDict)
              // .sort((a, b) =>
              //   a[1].placeholderValue.localeCompare(b[1].placeholderValue)
              // )
              .map(([key, value]) => (
                <div
                  key={key}
                  className="flex flex-wrap col-12 items-start self-start p-1 py-2 gap-1 border-b-2"
                >
                  <p className="m-0 col-12 max-w-[100%] text-ellipsis overflow-hidden whitespace-nowrap font-bold">
                    {value.placeholderValue} = {value.title}
                  </p>
                  <div className="flex col-12 gap-2">
                    <span>Aggregation Method</span>
                    <select
                      className="inline ms-0 mx-1"
                      defaultValue={value.aggregationMethod}
                      onChange={async (e) => {
                        const changed = e.target.value as AggregationMethod

                        ;(alternativeMode.indicatorsDict as any)[
                          key
                        ].aggregationMethod = changed

                        refreshAlternativeModeEquation()
                      }}
                    >
                      <option value="first">First of Period</option>
                      <option value="last">Last of Period</option>
                      <option value="average">Average</option>
                      <option value="sum">Sum</option>
                    </select>
                  </div>
                  <div className="flex col-12 gap-1">
                    <p className="m-0">Number of Periods to Offset</p>
                    <input
                      type="number"
                      className="narrowest inline"
                      placeholder="offset"
                      defaultValue={value.offset}
                      onChange={(e) => {
                        const changed = e.target.value

                        ;(alternativeMode.indicatorsDict as any)[key].offset =
                          changed
                        updateEquationInParent &&
                          updateEquationInParent(
                            equation,
                            transformStringEquationIntoEquationPieces(
                              alternativeModeEquation,
                              alternativeMode.indicatorsDict
                            ).pieces
                          )
                      }}
                    />
                  </div>
                  <div className="flex col-12 gap-1">
                    <span>Value</span>
                    <select
                      className="inline mx-1"
                      defaultValue={value.mode?.split('_')[1]}
                      onChange={async (e) => {
                        let changed = e.target.value

                        if (changed === 'showCAGR') {
                          setShowCAGR((prev) => [...prev, key])
                          return
                        } else {
                          setShowCAGR((prev) => prev.filter((_) => _ !== key))
                        }

                        if (changed === 'values_absolute') {
                          changed = 'absolute'
                        }

                        ;(alternativeMode.indicatorsDict as any)[key].mode =
                          'values_' + changed

                        refreshAlternativeModeEquation()
                      }}
                    >
                      <option value="absolute">Absolute</option>
                      <option value="percentChangeFromYear">
                        Percent Change from Year
                      </option>
                      <option value="changeAndPercentChangeFromYear">
                        Change from Year
                      </option>
                      <option value="monthlyPercentChange">
                        Monthly Percent Change
                      </option>
                      <option value="annualCompoundedRate">
                        Annual Compounded Rate
                      </option>
                      <option value="continuousRate">Continuous Rate</option>
                      <option value="continuousAnnualRate">
                        Continuous Annual Rate
                      </option>
                      <option value="indexScaled">Index Scaled</option>
                      <option value="showCAGR">Growth Rate of ...</option>
                    </select>
                    {showCAGR.includes(key) && (
                      <input
                        type="number"
                        placeholder="CAGR"
                        className="narrowest inline"
                        defaultValue={value.cagr}
                        onChange={(e) => {
                          const changed = e.target.value

                          ;(alternativeMode.indicatorsDict as any)[key].cagr =
                            changed
                          updateEquationInParent &&
                            updateEquationInParent(
                              equation,
                              transformStringEquationIntoEquationPieces(
                                alternativeModeEquation,
                                alternativeMode.indicatorsDict
                              ).pieces
                            )
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}

        {alternativeMode && (
          <div className="col-12 p-1 position-relative">
            <input
              type="text"
              onChange={(e) => setAlternativeModeEquation(e.target.value)}
              value={
                alternativeModeEquation ||
                transformEquationPiecesIntoStringEquation(
                  alternativeMode ? alternativeMode.equation : [],
                  alternativeMode!.indicatorsDict
                ).equation
              }
              placeholder="Enter equation"
              className="col-12"
            />
            <ToggleInfo>
              <Instructions />
            </ToggleInfo>
          </div>
        )}
        <div className="operation-buttons">
          {(checkCalcEquationChanges(
            equation,
            alternativeMode ? alternativeMode.equation : []
          ) ||
            changes ||
            (alternativeMode && alternativeModeEquation !== '')) && (
            <div className="control-buttons">
              <FunctionalButton
                className="primary center middle"
                functionToExecute={async () =>
                  await handleUpdate(equation, alternativeModeEquation)
                }
                refreshOnComplete={{
                  exists: true,
                  refreshFunction
                }}
                doesReset
                initialButtonState={'Calculate'}
              />
              <FunctionalButton
                className="teriary center middle"
                functionToExecute={resetEquation}
                initialButtonState={'Reset'}
                doesReset
              />
              {customFunctionalButton && (
                <>
                  {customFunctionalButton.children}
                  <FunctionalButton
                    iconCalculate
                    combinedButtonTitle
                    doesReset
                    initialButtonState={customFunctionalButton.title}
                    className={customFunctionalButton.className}
                    disabled={
                      alternativeModeEquation === '' && equation.length === 0
                    }
                    confirmation={{
                      position: 'inline',
                      message: 'Save as a new calculated indicator?'
                    }}
                    functionToExecute={async () =>
                      customFunctionalButton.functionToExecute(
                        equation,
                        transformStringEquationIntoEquationPieces(
                          alternativeModeEquation,
                          alternativeMode!.indicatorsDict
                        ).pieces
                      )
                    }
                  />
                </>
              )}
            </div>
          )}
        </div>

        {children}
      </div>
    )
  } catch (error) {
    console.error(error)
    return <div>Error in CombineIndicatorFormula</div>
  }
}

export default CombineIndicatorFormula
