import React, { useEffect, useState } from 'react'
import { fetchChosenIndicator } from '../utils/fetch'
import {
  IBasicIndicator,
  ITrendline,
  IMiniIndicator
} from '../utils/interfaces'
import {
  AccessDenied,
  Spinner,
  TabbingSwitch
} from '../helperComponents/_components'
import {
  combineFetchedData,
  findLowestFrequencyFromParams,
  getAllValuesGroupedByKey
} from '../utils/transformingData'

import DataTableNew from '../helperComponents/DataTableNew'
import { HighChartsLinearChart } from '../charts/HighChartsReact'

const MiniIndicator = ({ indicatorId }: IMiniIndicator) => {
  try {
    const [fetchedIndicatorParameters, setFetchedIndicatorParameters] =
      React.useState({} as IBasicIndicator)
    const [selectedIndicatorTab, setSelectedIndicatorTab] =
      useState('googleChart')
    const [
      fullFetchedIndicatorsParameters,
      setFullFetchedIndicatorsParameters
    ] = React.useState<ITrendline[]>([])
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const [fetchedIndicatorData, setFetchedIndicatorData] = useState<any>([])
    const [fetchedDataTransformed, setFetchedDataTransformed] = React.useState(
      {} as any
    )

    const [fullDates, setFullDates] = React.useState<string[]>([])
    const [pageLoading, setPageLoading] = React.useState(false)

    const handleIndicatorTabChange = (option: any) => {
      setSelectedIndicatorTab(option)
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }
        const response = await fetchChosenIndicator(indicatorId)
        if (!response) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }
        const fetchedParameters: IBasicIndicator =
          response.fetchedIndicatorParameters[0]
        const { trendlines, forecasts } = response
        const allParams = [
          ...response.fetchedIndicatorParameters,
          ...trendlines,
          ...forecasts.parameters
        ]

        const frequency = findLowestFrequencyFromParams(allParams)

        const combinedData = combineFetchedData({
          fetchedData: response.fetchedIndicatorData,
          forecasts,
          allParams,
          fullDates: response.fullDates,
          dataMode: fetchedParameters.data_mode,
          frequency
        })

        // if frequency is monthly, we need to remove all but the first day of the month in each month in fulldates
        const actualFullDates = response.fullDates.filter(
          (date: string, index: number) =>
            frequency === 'monthly'
              ? date.split('T')[0].split('-')[2] === '01'
              : false
        )

        const transformed = getAllValuesGroupedByKey(combinedData)

        setFetchedIndicatorParameters(fetchedParameters)
        setFetchedIndicatorData(combinedData)
        setFullDates(actualFullDates)

        setFullFetchedIndicatorsParameters(allParams)
        setFetchedDataTransformed(transformed)

        if (!noClose) {
          setPageLoading(false)
        }
        setAccessAllowed(true)
      } catch (err: any) {
        console.error(err)
        window.switchFunctions.home()
      }
    }

    useEffect(() => {
      if (pageLoading) return
      fetchIndicator(indicatorId)
    }, [indicatorId])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (accessAllowed && !pageLoading && fetchedIndicatorParameters) {
      return (
        <div className="entity">
          <TabbingSwitch
            options={[
              {
                label: 'Chart',
                active: selectedIndicatorTab === 'googleChart',
                onClick: () => handleIndicatorTabChange('googleChart')
              },
              {
                label: 'Table',
                active: selectedIndicatorTab === 'dataTable',
                onClick: () => handleIndicatorTabChange('dataTable')
              },
              {
                label: 'Details',
                active: selectedIndicatorTab === 'details',
                onClick: () => handleIndicatorTabChange('details')
              }
            ]}
            fit
          />
          <div
            className="entity-chart"
            style={{
              display: selectedIndicatorTab === 'googleChart' ? 'block' : 'none'
            }}
          >
            <HighChartsLinearChart
              page="indicator"
              chartOptions={{
                xAxisTitle: 'Month',
                yAxisTitle: 'Value',
                title: fetchedIndicatorParameters.title,
                reactive: true,
                mediumSize: true
                // reactive: initialChartKeys !== entityChartKeys
              }}
              chartData={{
                fetchedData: fetchedIndicatorData,
                fullDates,
                titles: fetchedDataTransformed.data.map((item: any) => ({
                  [item.title]:
                    fullFetchedIndicatorsParameters.find(
                      (indicator: any) =>
                        indicator.db_column_name === item.title
                    )?.title || item.title
                })),
                filteredKeys: fetchedDataTransformed.data.map(
                  (item: any) => item.title
                )
                // deviations: fullFetchedIndicatorsParameters
              }}
              componentOptions={{
                own:
                  fetchedIndicatorParameters &&
                  !fetchedIndicatorParameters.admin_access,
                parametersFetched: fetchedIndicatorParameters
              }}
            />
          </div>
          <div
            className="entity-data"
            style={{
              display: selectedIndicatorTab === 'dataTable' ? 'block' : 'none'
            }}
          >
            <DataTableNew
              tableMode="medium"
              fullDates={fullDates}
              data={fetchedDataTransformed}
              filteredKeys={fetchedDataTransformed.data.map(
                (key: any) => key.title
              )}
              chartKeys={fetchedDataTransformed.data.map(
                (key: any) => key.title
              )}
              fetchedIndicators={fullFetchedIndicatorsParameters}
              dataMode={fetchedIndicatorParameters.data_mode}
              componentMode="indicator"
              // selectedDataMappedMode={selectedDataMappedMode}
              // noCalcCondition={
              //   (key: string) =>
              //   entityAllKeys.find(
              //     (item: IEntityAllKeys) => item.title === key
              //   )?.type !== 'trendline'
              // }
            />
          </div>
          <div
            style={{
              display: selectedIndicatorTab === 'details' ? 'block' : 'none'
            }}
          >
            <div className="p-2 flex gap-3">
              <h2 className="m-0">{fetchedIndicatorParameters.title}</h2>
              <button
                onClick={() =>
                  window.switchFunctions.indicator(
                    fetchedIndicatorParameters.fid
                  )
                }
                className="no-btn no-hover border wide"
              >
                Visit Indicator
              </button>
            </div>
            <p className="px-2">{fetchedIndicatorParameters.meaning}</p>
          </div>
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default MiniIndicator
