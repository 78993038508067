import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
  useRef
} from 'react'
import {
  ChevronButtonProps,
  IEntityHeader,
  IFunctionalButton,
  IFunctionalDropdown,
  IFunctionalToggle,
  IThumbnail,
  TickProps
} from '../utils/interfaces'
import IconArrowUp from '../assets/icon-arrow_up.png'
import IconArrowDown from '../assets/icon-arrow_down.png'
import ChevronRight from '../assets/icon-chevron_right.png'
import ChevronRightDisabled from '../assets/icon-chevron_right_disabled.png'
import IconShow from '../assets/icon-show.png'
import IconHide from '../assets/icon-hide.png'
import {
  Icon3Dots,
  IconAI,
  IconBackwards,
  IconCalculate,
  IconChevronOmniDirectional,
  IconChevronUpDown,
  IconClose,
  IconConvert,
  IconDelete,
  IconEdit,
  IconForwards,
  IconList,
  IconMinus,
  IconNext,
  IconPlus,
  IconPrev,
  IconQuestionMark,
  IconReload,
  IconSave,
  IconSearch,
  IconSettings,
  IconTag,
  IconTrendline,
  IconUndo,
  ImagePlaceHolderNoImage,
  TickFail,
  TickSuccess
} from './Icons'
import { Checkbox } from 'pretty-checkbox-react'
import { capitalise, isEmpty } from '../utils/functions'
import { TYPES_DICT_REVERSE } from '../utils/_constants'
import { useMediaQuery } from '@mui/material'
import jsPDF from 'jspdf'
import PopupModal from './PopUpModal'
import ChatbotComponent from './ChatbotComponent'

const TechnicalWorks = () => (
  <div className="maintanance">
    <article>
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        <p>
          Sorry for the inconvenience but we&rsquo;re performing some
          maintenance at the moment. If you need to, you can always{' '}
          <a href="mailto:stanhuseletov@gmail.com">contact us</a>, otherwise
          we&rsquo;ll be back online shortly!
        </p>
        <p>&mdash; The Team</p>
      </div>
    </article>
  </div>
)

const TabbingSwitch = ({
  options,
  className,
  numberVisible = 4,
  fit,
  disappearing,
  tabbingLook
}: {
  options: {
    label: string
    active: boolean
    onClick: () => any
    exists?: boolean
    className?: string
  }[]
  className?: string
  numberVisible?: number
  fit?: boolean
  disappearing?: boolean
  style?: string
  tabbingLook?: boolean
}) => {
  if (useMediaQuery('(max-width: 768px)')) {
    numberVisible = 2
  } else if (useMediaQuery('(max-width: 1024px)')) {
    numberVisible = 4
  }
  const [startIndex, setStartIndex] = useState(0)

  const filteredOptions = useMemo(
    () => options.filter((option) => option.exists !== false),
    [options]
  )

  const slicedOptions = useMemo(() => {
    return filteredOptions.slice(startIndex, startIndex + numberVisible)
  }, [filteredOptions, startIndex, numberVisible])

  const shiftView = () => {
    if (startIndex + numberVisible < filteredOptions.length) {
      setStartIndex(startIndex + 1)
    }
  }

  const shiftBack = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1)
    }
  }

  return (
    <div
      className={`tabbing-switch-new ${className || ''}`}
      style={{
        justifyContent: fit ? 'space-between' : ''
      }}
    >
      {numberVisible < filteredOptions.length && (
        <button
          className="tabbing-switch-option icon"
          onClick={shiftBack}
          disabled={startIndex === 0}
        >
          <IconPrev />
        </button>
      )}
      {slicedOptions.map((option, index) => (
        <button
          key={index}
          onClick={option.onClick}
          className={`tabbing-switch-option${option.active ? ' active' : ''} ${option.className || ''}${tabbingLook ? ' tabbing-look' : ''}${fit ? ' flex justify-center' : ''}`}
          style={{
            width: fit
              ? `${100 / Math.min(numberVisible, options.length)}%`
              : '',
            display: disappearing && !option.active ? 'none' : ''
          }}
        >
          <span>{option.label}</span>
        </button>
      ))}
      {disappearing &&
        slicedOptions.filter((option) => option.active).length !== 0 && (
          <button
            className="tabbing-switch-option icon"
            onClick={shiftBack}
            disabled={startIndex === 0}
          >
            <IconClose />
          </button>
        )}
      {numberVisible < filteredOptions.length && (
        <button
          disabled={startIndex + numberVisible >= filteredOptions.length}
          className="tabbing-switch-option icon"
          onClick={shiftView}
        >
          <IconNext />
        </button>
      )}
    </div>
  )
}

const TickUp = () => {
  return <img src={IconArrowUp} alt="tick-up" />
}

const TickDown = () => {
  return <img src={IconArrowDown} alt="tick-down" />
}

const TickUpDouble = () => {
  return (
    <div>
      <img src={IconArrowUp} alt="tick-up" />
      <img src={IconArrowUp} alt="tick-up" />
    </div>
  )
}

const TickDownDouble = () => {
  return (
    <div>
      <img src={IconArrowDown} alt="tick-down" />
      <img src={IconArrowDown} alt="tick-down" />
    </div>
  )
}

// const TickNeutral = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="15"
//       height="15"
//       viewBox="0 0 26 26"
//     >
//       <path d="M0 10h24v4h-24z" />
//     </svg>
//   )
// }

const IconChevronRight = ({ disabled }: { disabled: boolean }) => (
  <img
    src={disabled ? ChevronRightDisabled : ChevronRight}
    alt="chevron-right"
  />
)

const Tick = ({
  direction,
  zone,
  weight,
  date,
  valuesPainted,
  arrowsPainted,
  value,
  difference
}: TickProps) => {
  const isEmptyVal = useMemo(
    () =>
      isEmpty(difference) ||
      difference === null ||
      (typeof difference === 'number' && isNaN(difference)),
    [difference]
  )

  const differenceToDisplay = useMemo(
    () => (isEmptyVal ? '' : Number(difference).toFixed(1).toString()),
    [isEmptyVal]
  )
  return (
    <Fragment>
      <div
        className={'flex middle end pointer tick ' + weight}
        style={{
          backgroundColor: isEmptyVal ? '' : zone
        }}
        // title={`${value} at ${date}`}
      >
        {!isEmptyVal && valuesPainted && (
          <div
            className={
              differenceToDisplay.length > 5 ? 'difference small' : 'difference'
            }
          >
            {differenceToDisplay}
          </div>
        )}
        {direction === 'up' && arrowsPainted && weight === 'double' && (
          <TickUpDouble />
        )}
        {direction === 'up' && arrowsPainted && weight === 'single' && (
          <TickUp />
        )}
        {direction === 'down' && arrowsPainted && weight === 'double' && (
          <TickDownDouble />
        )}
        {direction === 'down' && arrowsPainted && weight === 'single' && (
          <TickDown />
        )}
        {/* {direction === 'neutral' && <TickNeutral />} */}
      </div>
    </Fragment>
  )
}

const TableValue = ({
  direction,
  zone,
  weight,
  date,
  valuesPainted,
  arrowsPainted,
  value,
  difference,
  onValuesClick
}: TickProps) => {
  const isEmptyVal = useMemo(
    () =>
      isEmpty(difference) ||
      difference === null ||
      (typeof difference === 'number' && isNaN(difference)),
    [difference]
  )

  return (
    <td
      className={'flex middle end pointer tick value ' + weight}
      style={{
        backgroundColor: isEmptyVal ? '' : zone
      }}
      onClick={onValuesClick}
      // title={`${value} at ${date}`}
    >
      {!isEmptyVal && valuesPainted && (
        <span
          className={
            isEmptyVal
              ? ''
              : Number(difference).toFixed(1).toString().length > 5
                ? 'difference small'
                : 'difference'
          }
        >
          {isEmptyVal ? '' : Number(difference).toFixed(1).toString()}
        </span>
      )}
      {direction === 'up' && arrowsPainted && weight === 'double' && (
        <TickUpDouble />
      )}
      {direction === 'up' && arrowsPainted && weight === 'single' && <TickUp />}
      {direction === 'down' && arrowsPainted && weight === 'double' && (
        <TickDownDouble />
      )}
      {direction === 'down' && arrowsPainted && weight === 'single' && (
        <TickDown />
      )}
      {/* {direction === 'neutral' && <TickNeutral />} */}
    </td>
  )
}

const EntityHeader = ({
  ownerInfo,
  title,
  description,
  subtitle,
  titleClick,
  breadcrumbs
}: IEntityHeader) => {
  return (
    <div className="entity-header">
      <div className="owner">
        <div className="owner-image">
          <img src={ownerInfo.profileImage || '/favicon.ico'} alt="Thumbnail" />
        </div>
        {/* <div className="owner-info">
          <p className="name">
            {ownerInfo.username || 'No username provided'}
          </p>
        </div> */}
      </div>
      <div className="entity-header-details">
        {!breadcrumbs && <p className="subtitle">{subtitle}</p>}
        {breadcrumbs && (
          <TabbingSwitch
            options={breadcrumbs.map((breadcrumb) => ({
              label: breadcrumb.title,
              active: breadcrumb.active,
              onClick: breadcrumb.onClick
            }))}
            className="breadcrumbs"
          />
        )}
      </div>

      <div className="functional">
        <p
          className={`title ${titleClick ? 'pointer' : ''}`}
          onClick={titleClick}
        >
          {title}
        </p>
      </div>
      <p className="description">{description || 'No short description'}</p>
    </div>
  )
}

const Spinner = ({
  options,
  customStyles = {}
}: {
  options?: {
    noSpinner?: boolean
    noDots?: boolean
    customMessage?: string
    fullscreen?: boolean
    sidePanel?: boolean
    noMessage?: boolean
    noTransform?: boolean
  }
  customStyles?: { [key: string]: string }
}) => {
  const message = !options
    ? 'Chartit 360'
    : options.noMessage
      ? ''
      : options.customMessage || 'Chartit 360'

  const className = options?.fullscreen
    ? 'loader fullscreen'
    : options?.sidePanel
      ? 'loader sidepanel'
      : 'loader'

  customStyles = options?.noTransform
    ? {
        ...customStyles,
        position: 'static',
        transform: 'none'
      }
    : customStyles

  return (
    <div className={className} style={customStyles}>
      <h1>
        {message}
        {!options?.noDots && (
          <Fragment>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </Fragment>
        )}
      </h1>
      {!options?.noSpinner && <div className="spinner"></div>}
    </div>
  )
}

const AccessDenied = ({ message }: { message?: string | undefined }) => {
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 1500)

    return () => clearTimeout(timeout)
  }, [])

  if (loading) return <Spinner />
  return (
    <div className="access-denied">
      <h1>Access Denied</h1>
      <p>
        {message !== undefined
          ? message
          : 'You are not authorized to view this page.'}{' '}
        Please <a href="mailto:chartit360@gmail.com">contact us</a> if you think
        this is a mistake. Alternatively, you can go back to the{' '}
        <a href="/app">homepage</a>.
      </p>
    </div>
  )
}

const SkeletonLoader = () => <div className="skeleton-loader"></div>

const TrendlinesBlocks = () => (
  <Fragment>
    <div className="container w-6 medium">
      <SkeletonLoader />
    </div>
    <div className="container w-6 medium">
      <SkeletonLoader />
    </div>
    <div className="container w-12 small">
      <SkeletonLoader />
    </div>
  </Fragment>
)

const ToggledCollapsibleBlock = ({
  children,
  title,
  className,
  initialState = false
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  className?: string
}) => {
  const [isOpen, setIsOpen] = useState(initialState)

  return (
    <div className={`toggled-collapsible-block ${className || ''} col-12`}>
      <div
        className="col-12 flex justify-content-between pointer block-title"
        onClick={() => setIsOpen(!isOpen)}
      >
        <strong>{title}</strong>
        <button className="no-btn no-hover icon">
          <IconChevronUpDown open={isOpen} />
        </button>
      </div>

      <div
        style={{
          display: isOpen ? 'block' : 'none'
        }}
      >
        {children}
      </div>
    </div>
  )
}

const InitialLoadingScreen = ({ children }: { children?: React.ReactNode }) => {
  const urlType = new URLSearchParams(window.location.search).get('t') || ''

  const type =
    TYPES_DICT_REVERSE[urlType as keyof typeof TYPES_DICT_REVERSE] || 'home'

  return (
    <div className="new-modal">
      <div className="site-side-panel loading"></div>
      {children && children}
      {!children && <div className="sticky-header flex space-between "></div>}
      <div className="entity homepage">
        <div className="entity-header">
          <div className="entity-header-details">
            {/* <p className="subtitle"></p> */}
          </div>
          <p className="title">{capitalise(type)}</p>
        </div>

        {type === 'home' && (
          <div className="entity-blocks">
            <div className="container w-4 full">
              <SkeletonLoader />
            </div>
            <div className="container w-6 full">
              <SkeletonLoader />
            </div>
            <div className="container w-2 full">
              <SkeletonLoader />
            </div>
            <div className="container w-12 small">
              <SkeletonLoader />
            </div>
            <div className="container w-4 small">
              <SkeletonLoader />
            </div>
          </div>
        )}
        {(type === 'search' || type === 'collection' || type === 'channel') && (
          <div className="entity-blocks">
            <div className="container w-12 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
            <div className="container w-3 small">
              <SkeletonLoader />
            </div>
          </div>
        )}
        {type !== 'home' && type !== 'search' && type !== 'collection' && (
          <div className="entity-blocks">
            <div className="container w-4 tall">
              <SkeletonLoader />
            </div>
            <div className="container w-8 tall">
              <SkeletonLoader />
            </div>
            <div className="container w-12 small">
              <SkeletonLoader />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const ChevronButton = ({
  disabled,
  onClick,
  backwards
}: ChevronButtonProps) => (
  <button className="icon border-circle" onClick={onClick} disabled={disabled}>
    {backwards ? ( // eslint-disable-line
      <IconChevronOmniDirectional disabled={disabled} />
    ) : (
      <IconChevronOmniDirectional disabled={disabled} direction="right" />
    )}
  </button>
)

const NextPrevChevrons = ({
  handlePrevClick,
  handleNextClick,
  previousDisabled,
  nextDisabled
}: {
  handlePrevClick: () => void
  handleNextClick: () => void
  previousDisabled: boolean
  nextDisabled: boolean
}) => {
  return (
    <div className="flex gap-0 flex-end chevrons">
      <ChevronButton
        onClick={handlePrevClick}
        disabled={previousDisabled}
        backwards={true}
      />
      <ChevronButton
        onClick={handleNextClick}
        disabled={nextDisabled}
        backwards={false}
      />
    </div>
  )
}

const ButtonShowHide = ({
  show,
  onClick,
  disabled,
  className
}: {
  show: boolean
  onClick: () => void
  className?: string
  disabled?: boolean
}) => (
  <button
    className={`no-btn icon flex center middle ${
      className !== undefined ? className : ''
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    <img src={show ? IconHide : IconShow} width="20px" />
  </button>
)

const InlineSpinner = () => <i className="c-inline-spinner"></i>

const FunctionalButton = ({
  initialButtonState,
  functionToExecute,
  confirmation,
  disabled,
  customStyle,
  className,
  doesReset,
  iconReload,
  iconPlusMode,
  iconConvert,
  iconCalculate,
  iconMinusMode,
  iconCalculateMode,
  fakeLoading,
  iconTrendline,
  iconSearchMode,
  iconSaveMode,
  iconUndoMode,
  iconEditMode,
  iconForwardsMode,
  iconBackwardsMode,
  iconSettings,
  iconAI,
  combinedButtonTitle,
  refreshOnComplete,
  noReturn,
  iconDelete,
  iconListMode,
  collapsible
}: IFunctionalButton) => {
  const evaluateButtonState = (combined?: boolean) => {
    let state = initialButtonState
    if (iconPlusMode) {
      state = <IconPlus />
    } else if (iconMinusMode) {
      state = <IconMinus />
    } else if (iconSaveMode) {
      state = <IconSave />
    } else if (iconCalculateMode) {
      state = <IconCalculate />
    } else if (iconUndoMode) {
      state = <IconUndo />
    } else if (iconListMode) {
      state = <IconList />
    } else if (iconForwardsMode) {
      state = <IconForwards />
    } else if (iconBackwardsMode) {
      state = <IconBackwards />
    } else if (iconAI) {
      state = <IconAI />
    } else if (iconTrendline) {
      state = <IconTrendline />
    } else if (iconDelete) {
      state = <IconDelete />
    } else if (iconReload) {
      state = <IconReload />
    } else if (iconSettings) {
      state = <IconSettings />
    } else if (iconSearchMode) {
      state = <IconSearch />
    } else if (iconConvert) {
      state = <IconConvert />
    } else if (iconCalculate) {
      state = <IconCalculate />
    } else if (iconEditMode) {
      state = <IconEdit />
    }
    if (combined) {
      return {
        state: <span className="ms-1">{initialButtonState}</span>,
        combined: true
      }
    }

    if (combinedButtonTitle || combined) {
      return {
        state: (
          <>
            <span className="ms-1">{initialButtonState}</span>
            {state}
          </>
        ),
        combined: true
      }
    }
    return { state, combined: false }
  }

  const evaluatedButtonState = evaluateButtonState()
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)
  const [buttonContents, setButtonContents] = useState<any>(
    evaluatedButtonState.state
  )
  const [success, setSuccess] = useState<boolean>(false)
  const intialClassName =
    'send-update border border-gray' +
    (className ? ' ' + className : '') +
    ((iconPlusMode ||
      iconMinusMode ||
      iconSaveMode ||
      iconCalculateMode ||
      iconUndoMode ||
      iconForwardsMode ||
      iconBackwardsMode ||
      iconAI ||
      iconReload ||
      iconTrendline ||
      iconSettings ||
      iconDelete ||
      iconSearchMode ||
      iconListMode) &&
    !combinedButtonTitle
      ? ' icon'
      : '') +
    (evaluatedButtonState.combined ? ' flex gap-1' : '')
  const [iconClassName, setIconClassName] = useState<string>(intialClassName)

  const onHover = () => {
    if (collapsible) {
      setButtonContents(evaluateButtonState(true).state)
      const iconClass =
        iconClassName
          .split(' ')
          .filter((item) => item !== 'icon')
          .join(' ') + ' center middle collapsible-open'

      setIconClassName(iconClass)
    }
  }

  const onMouseLeave = () => {
    if (collapsible) {
      // add a timeout to prevent the button from collapsing too quickly
      const timeout = setTimeout(() => {
        setButtonContents(evaluateButtonState().state)
        setIconClassName(intialClassName)
      }, 3000)

      // clear the timeout if the user hovers over the button again
      return () => clearTimeout(timeout)
    }
  }

  const sendUpdate = async (confirmed?: boolean) => {
    if (!confirmed && confirmation) {
      let confirmationResult = false
      const { position, message } = confirmation

      if (position === 'popup') {
        confirmationResult = window.confirm(message || 'Confirm?')
      } else if (
        position === 'inline' ||
        position === 'bottom' ||
        position === 'top'
      ) {
        setConfirmationOpen(true)
      }

      if (!confirmationResult) {
        return
      }
    }
    setConfirmationOpen(false)

    if (fakeLoading) {
      setButtonContents(<InlineSpinner />)
    }
    if (noReturn) {
      functionToExecute()
      return
    }

    setButtonContents(<InlineSpinner />)
    const response = await functionToExecute()
    if (response === true || (response && response.status === 200)) {
      if (refreshOnComplete?.exists) {
        refreshOnComplete.refreshFunction()
      }
      setButtonContents(<TickSuccess />)
    } else {
      setSuccess(false)
      setButtonContents(<TickFail />)
      // setTimeout(() => {
      // }, 2000)
    }
    const timeout = setTimeout(
      () => setButtonContents(evaluateButtonState().state),
      2000
    )

    if (!doesReset && !refreshOnComplete?.doesReset) {
      setSuccess(true)
    }

    return () => clearTimeout(timeout)
  }

  return (
    <>
      <button
        className={iconClassName}
        onClick={() => sendUpdate(confirmation === undefined)}
        disabled={disabled || success}
        onMouseEnter={onHover}
        onMouseLeave={onMouseLeave}
        title={initialButtonState}
        style={customStyle}
      >
        {buttonContents}
      </button>
      {confirmation && confirmationOpen && (
        <span
          className={`flex ${confirmation.position === 'bottom' ? ' col-12' : ''}`}
          style={{
            // add absolute if the position is top
            position: confirmation.position === 'top' ? 'absolute' : 'relative',
            // add top if the position is top
            top: confirmation.position === 'top' ? '5px' : '',
            fontSize: confirmation.position === 'inline' ? '0.8rem' : ''
          }}
        >
          {confirmation.message || 'Confirm?'}
          <button
            onClick={() => sendUpdate(true)}
            className="no-btn icon scale-75"
          >
            <TickSuccess className="w-[20px]" />
          </button>
          <button
            onClick={() => setConfirmationOpen(false)}
            className="no-btn icon scale-75"
          >
            <TickFail className="w-[20px]" />
          </button>
        </span>
      )}
    </>
  )
}

const ToggleInfo = ({
  children,
  description,
  className
}: {
  children?: React.ReactNode
  description?: string
  className?: string
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState)
  }

  return (
    <div className={className}>
      <button
        style={{
          position: 'absolute',
          right: 3,
          top: 1
        }}
        className="no-btn icon no-hover"
        onClick={toggleVisibility}
      >
        <IconQuestionMark />
      </button>
      {isVisible && children && (
        <>
          <br />
          {children}
        </>
      )}
      {isVisible && description && (
        <>
          <br />
          {description}
        </>
      )}
    </div>
  )
}

interface IAnalyseAiProps {
  analyseParameters: any
  files: React.MutableRefObject<File[] | null>
  instructions: string
  analysisMode: 'component' | 'info'
}

const AnalyseWithAI = ({
  analyseParameters,
  files,
  instructions,
  analysisMode
}: IAnalyseAiProps) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  const analyseWithAI = async () => {
    if (analyseParameters) {
      const doc = new jsPDF() // eslint-disable-line
      doc.setFontSize(12)

      doc.text('Entity Analysis', 10, 10)

      let yPosition = 20 // Начальная вертикальная позиция для текста
      const startYPosition = yPosition
      const pageHeight = doc.internal.pageSize.height
      const margin = 10
      const lineSpacing = 10 // Расстояние между строками
      const maxLineWidth = doc.internal.pageSize.width - 20 // Максимальная ширина строки

      // Проходим по всем ключам и значениям в analyseParameters
      Object.entries(analyseParameters).forEach(([key, value]) => {
        if (yPosition >= pageHeight - margin) {
          doc.addPage()
          yPosition = startYPosition
        }

        // Преобразуем значение в строку
        const text = `${key}: ${JSON.stringify(value)}`

        // Разбиваем строку на части по ширине страницы
        const lines = doc.splitTextToSize(text, maxLineWidth)

        // Добавляем все линии текста в PDF
        lines.forEach((line: string) => {
          if (yPosition >= pageHeight - margin) {
            doc.addPage()
            yPosition = startYPosition
          }
          doc.text(line, 10, yPosition)
          yPosition += lineSpacing // Переход к следующей строке
        })
      })

      const blob = doc.output('blob')
      const file = new File([blob], 'Entity Analysis.pdf', {
        type: 'application/pdf'
      })

      // console.info('PDF файл создан:', file)
      // const url = URL.createObjectURL(blob)

      // // Создаем временный элемент <a> и инициируем скачивание
      // const link = document.createElement('a')
      // link.href = url
      // link.download = 'Entity Analysis.pdf'
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)

      // // Освобождаем память
      // URL.revokeObjectURL(url)

      files.current = [file]

      return true
    } else {
      return false
    }
  }

  return (
    <div>
      <FunctionalButton
        className="inline no-btn default-text no-hover no-border"
        noReturn
        functionToExecute={async () => {
          await analyseWithAI()
          setPopupOpen(true)
        }}
        doesReset
        initialButtonState={'AI Analysis'}
        iconAI
      />
      {popupOpen && (
        <PopupModal
          isOpen={popupOpen}
          onClose={() => setPopupOpen(false)}
          title="AI Analysis"
          size="large"
          className="p-0"
          noChanges={false}
          customMessage="Are you sure you want to close the window? This will end the conversation and it will be lost."
        >
          <ChatbotComponent
            page="entity"
            instructions={instructions}
            assistantOn={true}
            providedFiles={files.current}
            clearFiles={() => (files.current = null)}
          />
        </PopupModal>
      )}
    </div>
  )
}

const FunctionalToggle = ({
  leftTitle,
  rightTitle,
  functionToExecute,
  refreshOnComplete,
  state,
  disabled,
  className
}: IFunctionalToggle) => {
  const [isLoading, setIsLoading] = useState(false)

  const toggleSwitch = async () => {
    if (disabled) {
      return false
    }
    setIsLoading(true)
    try {
      const res = await functionToExecute()

      if (!res) {
        setIsLoading(false)
        return false
      }

      if (res && refreshOnComplete?.exists) {
        refreshOnComplete.refreshFunction()
      }
    } catch (error) {
      console.error('Failed to toggle', error)
    }
    setIsLoading(false)
  }

  return (
    <div className={`toggle-wrapper ${className || ''}`}>
      {leftTitle && <span className="toggle-title">{leftTitle}</span>}

      <div
        onClick={toggleSwitch}
        className={`toggle-container ${isLoading ? 'loading' : ''}`}
      >
        {isLoading && <InlineSpinner />}
        {!isLoading && (
          <div className={`slider ${state ? 'enabled' : 'disabled'}`}></div>
        )}
      </div>

      {rightTitle && <span className="toggle-title">{rightTitle}</span>}
    </div>
  )
}

const scrollDataTableToEnd = () => {
  $('.entity .container.w-12').animate(
    {
      scrollLeft: $('.entity .container.w-12 .data-table')[0].scrollWidth
    },
    800
  ) // 800ms for the animation duration
}

const scrollDataTableToStart = () => {
  $('.entity .container.w-12').animate(
    {
      scrollLeft: 0
    },
    800
  ) // 800ms for the animation duration
}

const ScrollTableButtons = () => (
  <div className="flex gap-2 justify-content-end">
    <button className="icon" onClick={scrollDataTableToStart}>
      <IconChevronOmniDirectional nonPrimary direction="left" />
    </button>
    <button className="icon" onClick={scrollDataTableToEnd}>
      <IconChevronOmniDirectional nonPrimary direction="right" />
    </button>
  </div>
)

const FunctionalDropdown = ({
  title,
  options,
  functionToExecute,
  refreshOnComplete,
  disabled,
  initialOption,
  className
}: IFunctionalDropdown) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(
    initialOption || null
  )

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (disabled) {
      return
    }
    setIsLoading(true)
    const newSelectedOption = event.target.value
    setSelectedOption(newSelectedOption)
    try {
      const res = await functionToExecute(newSelectedOption)

      if (!res) {
        setIsLoading(false)
        return
      }

      if (res && refreshOnComplete?.exists) {
        refreshOnComplete.refreshFunction()
      }
    } catch (error) {
      console.error('Failed to execute function', error)
    }
    setIsLoading(false)
  }

  return (
    <div
      className={`dropdown-wrapper ${className || ''}`}
      style={{
        width:
          Math.max(...options.map((option) => option.title.length)) * 9 + 'px'
      }}
    >
      {title && <span className="dropdown-title">{title}</span>}

      <select
        onChange={handleChange}
        disabled={isLoading || disabled}
        value={selectedOption || ''}
        className={`dropdown ${isLoading ? 'loading' : ''} col-12`}
      >
        {selectedOption === null && (
          <option value="" disabled hidden>
            Select an option
          </option>
        )}
        {options.map((option, index) => (
          <option key={index} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>

      {isLoading && <span className="loading-spinner">Loading...</span>}
    </div>
  )
}

interface Option {
  value: number | string
  label: string
}

interface SearchableSelectProps {
  options: Option[]
  onChange: (value: number | string) => void
  defaultValue?: number | string
  width?: number
  className?: string
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  onChange,
  defaultValue,
  width,
  className
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false)
  // const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const initialOption =
      options.find((option) => option.value === defaultValue) || null
    // setSelectedOption(initialOption)
    setSearchTerm(initialOption ? initialOption.label : '')
  }, [defaultValue, options])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleOptionClick = (option: Option) => {
    // setSelectedOption(option)
    setSearchTerm(option.label)
    setDropdownVisible(false)
    onChange(option.value)
  }

  const handleFocus = () => {
    setDropdownVisible(true)
  }

  const handleClickOutside = (e: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setDropdownVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const filteredOptions = options
    .filter((option) => !!option.label && !!option.value)
    .filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    )

  return (
    <div
      className={`search-select ${className}`}
      ref={dropdownRef}
      style={{ width: width ? `${width}px` : '150px' }}
    >
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={handleFocus}
        placeholder="Search..."
        className="col-12 inline m-0"
      />
      {isDropdownVisible && (
        <ul className="options-list">
          {filteredOptions.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

interface CanvasWordProps {
  word: string
  width?: number
  height?: number
}

const CanvasWord: React.FC<CanvasWordProps> = ({
  word,
  width = 400,
  height = 250
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const ctx = canvas.getContext('2d')
      if (ctx) {
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Set canvas dimensions
        canvas.width = width
        canvas.height = height

        const fontSize = width / 8
        const font = `${fontSize}px "El Messiri", sans-serif`

        ctx.font = font
        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'
        ctx.fillStyle = '#4b4897'

        const x = canvas.width / 2
        const y = canvas.height / 2

        ctx.fillText(word, x, y)
      }
    }
  }, [word, width, height])

  return <canvas ref={canvasRef} className="canvas-frame" />
}

const FunctionalCheckbox = ({
  title,
  functionToExecute,
  state,
  color,
  initialState,
  followup
}: {
  title: string
  state: boolean
  functionToExecute: () => any
  color: 'success-o' | 'danger-o' | 'warning-o' | 'info-o' | 'primary-o'
  initialState?: boolean
  followup?: {
    title: string
    class: string
    function: () => void
    refreshFunction: () => void
  }
}) => {
  // const [isLoading, setIsLoading] = useState(false)
  const [buttonState, setState] = useState<boolean>(initialState || state)
  const [followUpOpen, setFollowUpOpen] = useState<boolean>(false)

  functionToExecute = followup
    ? () => {
        setFollowUpOpen(!followUpOpen)
        return true
      }
    : functionToExecute

  const toggleSwitch = async () => {
    try {
      if (functionToExecute) {
        const res = await functionToExecute()
        if (res || res.status === 200) {
          setState(!buttonState)
          return true
        }
        return false
      }
    } catch (error) {
      return false
    }
  }

  return (
    <div className="flex middle gap-2">
      <span>{title}</span>
      <Checkbox
        checked={buttonState}
        // indeterminate={isLoading}
        onChange={toggleSwitch}
        title={title}
        color={color || 'success-o'}
        animation="pulse"
      />
      {followup && (
        <FunctionalButton
          className={followup.class}
          functionToExecute={async () => followup.function()}
          initialButtonState={followup.title}
          disabled={!followUpOpen}
          doesReset
          refreshOnComplete={{
            exists: true,
            refreshFunction: followup.refreshFunction
          }}
        />
      )}
    </div>
  )
}

const Thumbnail = ({
  image,
  title,
  subtitle,
  onClick,
  details,
  editFunction,
  addNewMode,
  ownerInfo,
  tags
}: IThumbnail) => {
  return (
    <div
      className={`thumbnail ${addNewMode ? 'new' : ''}`}
      onClick={() => addNewMode && onClick()}
    >
      <div className="image-container">
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              {!isEmpty(image) && <img src={image} alt={title} />}
              {isEmpty(image) && addNewMode && (
                <div className="image-placeholder">
                  <ImagePlaceHolderNoImage />
                </div>
              )}
              {isEmpty(image) && !addNewMode && (
                <div className="canvas-word">
                  <CanvasWord word={subtitle} />
                </div>
              )}
            </div>
            <div className="flip-card-back">
              <p className="description">
                {details || 'No Description Provided'}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="details">
        <div className="titles">
          <p className="title" onClick={onClick}>
            {title}
          </p>
          <p className="subtitle">{subtitle}</p>
        </div>
      </div>
      {editFunction && (
        <button
          className="no-btn flex center middle justify-content-end edit no-hover"
          onClick={editFunction}
        >
          <Icon3Dots />
        </button>
      )}
      {ownerInfo && (
        <div
          className={`owner-info ${ownerInfo && ownerInfo.onClick ? 'pointer' : ''}`}
          onClick={ownerInfo.onClick}
        >
          {!isEmpty(ownerInfo.profile_image) && (
            <img
              className="image"
              src={ownerInfo.profile_image}
              alt={ownerInfo.username}
            />
          )}
          {isEmpty(ownerInfo.profile_image) && (
            <span className="no-image-placeholder image"></span>
          )}
          <span>{ownerInfo.username}</span>
        </div>
      )}
      {tags && tags.length >= 1 && (
        <div className="tags">
          <IconTag />
          {tags.slice(0, 2).map((tag, index) => (
            <span key={index}>
              {tag.title}
              {index === 1 && tags.length > 2 ? '...' : ''}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

const Thumbnails = ({
  thumbnails,
  itemsInRow = 3
}: {
  thumbnails: IThumbnail[]
  itemsInRow?: number
}) => {
  const [visibleCount, setVisibleCount] = useState(25)

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 25)
  }
  return (
    <div className={'thumbnails no-border count-' + itemsInRow}>
      {thumbnails.length > 25 && (
        <div className="flex col-12 center middle p-1 justify-content-end gap-2">
          <span>Number Visible:</span>
          <select
            className="narrowest"
            value={visibleCount}
            onChange={(e) => setVisibleCount(Number(e.target.value))}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={thumbnails.length}>All</option>
          </select>
        </div>
      )}
      {thumbnails.slice(0, visibleCount).map((thumbnail, index) => (
        <Thumbnail
          key={index}
          image={thumbnail.image}
          title={thumbnail.title}
          subtitle={thumbnail.subtitle}
          details={thumbnail.details}
          onClick={thumbnail.onClick}
          editFunction={thumbnail.editFunction}
          addNewMode={thumbnail.addNewMode}
          ownerInfo={thumbnail.ownerInfo}
          tags={thumbnail.tags}
        />
      ))}
      {visibleCount < thumbnails.length && (
        <div className="flex col-12 center middle p-1">
          <button className="primary widest" onClick={handleShowMore}>
            Show More
          </button>
        </div>
      )}
    </div>
  )
}

export {
  TechnicalWorks,
  Spinner,
  InlineSpinner,
  Tick,
  FunctionalButton,
  AccessDenied,
  AnalyseWithAI,
  NextPrevChevrons,
  TableValue,
  IconChevronRight,
  ButtonShowHide,
  FunctionalToggle,
  TabbingSwitch,
  SearchableSelect,
  FunctionalCheckbox,
  EntityHeader,
  Thumbnail,
  SkeletonLoader,
  InitialLoadingScreen,
  TrendlinesBlocks,
  ToggledCollapsibleBlock,
  FunctionalDropdown,
  CanvasWord,
  ScrollTableButtons,
  Thumbnails,
  ToggleInfo
}
