import React, { useState } from 'react'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IIncludedId,
  ITrendline
} from '../../utils/interfaces'
import AllIndicatorsTableDisplay from '../../helperComponents/AllIndicatorsTableDisplay'
import IndicatorsAndTrendlinesSearch from '../../helperComponents/IndicatorsAndTrendlinesSearch'
import PopupModalNew from '../../helperComponents/PopUpModalNew'
import { createNewTrendline, updateEntityIncluded } from '../../utils/fetch'
import { generateRandomId } from '../../utils/functions'
import AISearch from '../../helperComponents/AISearch'

interface IAddRemoveSelectedPopup {
  entityType: string
  entityId: string
  initialSelectedEntities?: IIncludedId[]
  resetModals: () => void
  refreshEntity: () => Promise<void>
  fetchedAllIndicators: (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
}

const AddRemoveSelectedPopup = ({
  entityType,
  entityId,
  initialSelectedEntities = [],
  resetModals,
  refreshEntity,
  fetchedAllIndicators
}: IAddRemoveSelectedPopup) => {
  const [selection, setSelection] = useState<IIncludedId[]>(
    initialSelectedEntities
  )
  const updateSelected = (id: string, add?: boolean) => {
    if (add) {
      setSelection(
        selection.map((item) => {
          if (item.fid === id) {
            return { ...item, chart: true, data: true }
          }
          return item
        })
      )
    } else {
      setSelection(
        selection.map((item) => {
          if (item.fid === id) {
            return { ...item, chart: false, data: false }
          }
          return item
        })
      )
    }

    return true
  }

  const updateIncluded = (id: string, add?: boolean) => {
    if (add) {
      const ind = fetchedAllIndicators.find((indicator) => indicator.fid === id)

      const isCalculated = ind?.type === 'calculated'
      const isTrendline = ind?.type === 'trendline'
      const isExternal =
        ind?.type === 'external' ||
        ind?.category === 'FRED' ||
        ind?.category === 'treasury'

      const type = isCalculated
        ? 'calculated'
        : isTrendline
          ? 'trendline'
          : isExternal
            ? 'external'
            : 'indicator'
      setSelection([...selection, { fid: id, chart: true, data: true, type }])
    } else {
      setSelection(selection.filter((item) => item.fid !== id))
    }

    return true
  }

  const updateIncludedAISearch = ({
    fid,
    title,
    type = 'external',
    add = true
  }: {
    title: string
    fid: string
    add?: boolean
    type?: string
  }) => {
    try {
      const category = 'FRED'

      fetchedAllIndicators.push({
        title,
        fid,
        type,
        category
      } as IBasicIndicator)

      updateIncluded(fid, add)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const handleSubmitTrendlinesSelection = async () => {
    const res = await updateEntityIncluded({
      entity: {
        entityId,
        entityType
      },
      ids: selection
    })
    return res
  }

  const undoTrendlinesSelection = () => {
    setSelection(initialSelectedEntities)
    resetModals()

    return true
  }

  return (
    <PopupModalNew
      buttonFragment={{
        props: {
          className: 'inline no-btn default-text no-hover no-border',
          noReturn: true,
          functionToExecute: () => undefined,
          doesReset: true,
          initialButtonState: 'Change Selection',
          iconListMode: true
        }
      }}
      fid={generateRandomId()}
      noChanges
      size="large"
      title="Add/remove indicators"
      handleSubmit={handleSubmitTrendlinesSelection}
      saveButtonExists
      handleUndo={undoTrendlinesSelection}
      refreshOnComplete={{
        exists: true,
        refreshFunction: refreshEntity
      }}
    >
      {entityType === 'scenario' && (
        <div className="col-12 p-2">
          <AISearch addFunction={updateIncludedAISearch} />
        </div>
      )}
      {(entityType === 'scenario' || entityType === 'calculated') && (
        <IndicatorsAndTrendlinesSearch
          indicators={fetchedAllIndicators as any}
          includedIndicators={selection
            .filter((item) => item.chart)
            .map((item) => item.fid)}
          refreshIndicators={refreshEntity}
          direction="down"
          addFunction={(id: string) => updateIncluded(id, true)}
          removeFunction={(id: string) => updateIncluded(id, false)}
          altFunction={async (id: string) => {
            const res = await createNewTrendline({
              indicatorFid: id,
              sourceInfo: {
                source: entityType,
                source_fid: entityId
              }
            })

            if (!res) {
              return false
            }

            refreshEntity()
            return true
          }}
        />
      )}
      <div className="flex-column center middle col-12 gap-3 indicators-view-panel p-3">
        <AllIndicatorsTableDisplay
          allIndicators={fetchedAllIndicators}
          updateSelected={updateSelected}
          selection={selection}
          scenarioSpecifcAddRemoveButtons={{
            add: async (id: string) => updateIncluded(id, true),
            remove: async (id: string) => updateIncluded(id, false),
            generateTrendline: async (id: string) => {
              const res = await createNewTrendline({
                indicatorFid: id,
                sourceInfo: {
                  source: entityType,
                  source_fid: entityId
                }
              })

              if (!res) {
                return false
              }

              refreshEntity()
              return true
            }
          }}
        />
      </div>
    </PopupModalNew>
  )
}

export default AddRemoveSelectedPopup
