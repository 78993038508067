import React from 'react'

const Instructions = () => (
  <div className="collapsible-side-panel-rules">
    <h4>Examples of Valid Equations</h4>
    <ol>
      <li>
        <strong>Complex Expressions with Parentheses:</strong>
        <ul>
          <li>
            <code>
              &quot;((a + b) * CAGR of 4 (c)) / (OFFSET of 2 (d) - e)&quot;
            </code>
          </li>
          <li>
            <code>
              &quot;a + (b - CAGR of 2 (c)) * OFFSET of 5 (d) / e&quot;
            </code>
          </li>
        </ul>
      </li>
      <li>
        <strong>Basic Arithmetic with Variables:</strong>
        <ul>
          <li>
            <code>&quot;a + b - c * d / e&quot;</code>
          </li>
          <li>
            <code>&quot;A * B + C - D / E&quot;</code>
          </li>
        </ul>
      </li>
      <li>
        <strong>Including Numbers:</strong>
        <ul>
          <li>
            <code>&quot;5 + a - 3.2 * b&quot;</code>
          </li>
          <li>
            <code>&quot;-8 + c / 2 + OFFSET of 4 (d)&quot;</code>
          </li>
        </ul>
      </li>
      <li>
        <strong>Using Special Functions:</strong>
        <ul>
          <li>
            <code>&quot;CAGR of 5 (a) + OFFSET of 3 (b) - 2 * c&quot;</code>
          </li>
          <li>
            <code>&quot;1 + CAGR of 3.5 (d) - OFFSET of -1 (e)&quot;</code>
          </li>
        </ul>
      </li>
    </ol>

    <h4>Supported Syntax Elements</h4>

    <h5>1. Variables</h5>
    <ul>
      <li>
        <strong>Identifiers:</strong> Use single-letter variables from{' '}
        <code>a</code> to <code>h</code> (case-insensitive).
      </li>
    </ul>

    <h5>2. Numbers</h5>
    <ul>
      <li>
        <strong>Formats:</strong> Support both integer and decimal numbers.
        Numbers can be positive or negative.
        <ul>
          <li>
            <strong>Examples:</strong> <code>5</code>, <code>-8</code>,{' '}
            <code>3.14</code>, <code>-0.75</code>
          </li>
        </ul>
      </li>
    </ul>

    <h5>3. Operators</h5>
    <ul>
      <li>
        <strong>Arithmetic Operators:</strong> Use standard mathematical
        operators for calculations.
        <ul>
          <li>
            <strong>Supported Operators:</strong> <code>+</code> (addition),{' '}
            <code>-</code> (subtraction), <code>*</code> (multiplication),{' '}
            <code>/</code> (division)
          </li>
          <li>
            <strong>Parentheses:</strong> Use <code>(</code> and <code>)</code>{' '}
            to define the order of operations and group expressions.
          </li>
          <li>
            <strong>Examples:</strong> <code>+</code>, <code>-</code>,{' '}
            <code>*</code>, <code>/</code>, <code>(</code>, <code>)</code>
          </li>
        </ul>
      </li>
    </ul>

    <h5>4. Special Functions</h5>

    <h6>CAGR (Compound Annual Growth Rate)</h6>
    <ul>
      <li>
        <strong>Syntax:</strong>{' '}
        <code>CAGR of &lt;number&gt; (&lt;variable&gt;)</code>
        <ul>
          <li>
            <strong>Purpose:</strong> Applies a CAGR value to the specified
            variable.
          </li>
          <li>
            <strong>Components:</strong>
            <ul>
              <li>
                <code>&lt;number&gt;</code>: The CAGR value (integer or
                decimal).
              </li>
              <li>
                <code>&lt;variable&gt;</code>: A single-letter variable from{' '}
                <code>a</code> to <code>h</code>.
              </li>
            </ul>
          </li>
          <li>
            <strong>Examples:</strong> <code>CAGR of 5 (a)</code>,{' '}
            <code>CAGR of 3.2 (B)</code>
          </li>
        </ul>
      </li>
    </ul>

    <h6>OFFSET</h6>
    <ul>
      <li>
        <strong>Syntax:</strong>{' '}
        <code>OFFSET of &lt;number&gt; (&lt;variable&gt;)</code>
        <ul>
          <li>
            <strong>Purpose:</strong> Applies an offset value to the specified
            variable.
          </li>
          <li>
            <strong>Components:</strong>
            <ul>
              <li>
                <code>&lt;number&gt;</code>: The offset value (integer or
                decimal).
              </li>
              <li>
                <code>&lt;variable&gt;</code>: A single-letter variable from{' '}
                <code>a</code> to <code>h</code>.
              </li>
            </ul>
          </li>
          <li>
            <strong>Examples:</strong> <code>OFFSET of 5 (b)</code>,{' '}
            <code>OFFSET of -2.5 (C)</code>
          </li>
        </ul>
      </li>
    </ul>

    <h4>Formatting Guidelines</h4>
    <ul>
      <li>
        <strong>Spacing:</strong> Equations can have uneven or inconsistent
        spacing. The parser handles multiple spaces, no spaces, or a mix.
        <ul>
          <li>
            <strong>Valid Examples:</strong>
            <ul>
              <li>
                <code>
                  &quot;1 + a * b / (c - 5)-8 + b + CAGR of 5 (a) - OFFSET of 5
                  (b) + 5&quot;
                </code>
              </li>
              <li>
                <code>
                  &quot;1+a*b/(c-5)-8+b+CAGR of5(a)-OFFSET of5(b)+5&quot;
                </code>
              </li>
              <li>
                <code>
                  &quot;1 + a * b / ( c -5 ) -8 +b + CAGR of 5 (a) - OFFSET of 5
                  (b) +5&quot;
                </code>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <strong>Case Sensitivity:</strong> Variable identifiers are
        case-insensitive. Both uppercase and lowercase letters are accepted.
        <ul>
          <li>
            <strong>Examples:</strong> <code>a</code>, <code>A</code>,{' '}
            <code>b</code>, <code>B</code>
          </li>
        </ul>
      </li>
      <li>
        <strong>Order of Elements:</strong> Mix numbers, variables, operators,
        and special functions in any valid mathematical order.
        <ul>
          <li>
            <strong>Example:</strong>{' '}
            <code>
              &quot;1 + a * (b - CAGR of 5 (c)) / OFFSET of 3 (d) - 8&quot;
            </code>
          </li>
        </ul>
      </li>
      <li>
        <strong>Parentheses Usage:</strong> Use parentheses to dictate operation
        precedence and group expressions involving variables and special
        functions.
        <ul>
          <li>
            <strong>Example:</strong>{' '}
            <code>&quot;(a + b) * CAGR of 2.5 (c) - OFFSET of 1 (d)&quot;</code>
          </li>
        </ul>
      </li>
    </ul>
  </div>
)

export default Instructions
