import { Checkbox } from 'pretty-checkbox-react'
import FileUploadComponent from '../../helperComponents/FileUploadComponent'
import PopupModal from '../../helperComponents/PopUpModal'
import { generateNewScenario } from '../../utils/fetch'
import React, { useState } from 'react'
import { ToggledCollapsibleBlock } from '../../helperComponents/_components'

interface IFileContent {
  title: string
  content: string
}

const MainAppCreateScenarioPopup = ({ onClose }: { onClose: () => void }) => {
  const [scenarioName, setScenarioName] = useState<string>('')
  const [scenarioDescription, setScenarioDescription] =
    useState<string>('My new scenario')
  const [newScenarioPublic, setNewScenarioPublic] = useState<boolean>(false)
  const [uploadedContent, setUploadedContent] = useState<string>('')

  const handleSendFileContent = (uploaded: IFileContent) => {
    setScenarioName(uploaded.title)
    setUploadedContent(uploaded.content)
  }

  return (
    <PopupModal
      isOpen={true}
      onClose={onClose}
      title="New Scenario"
      size="large"
      handleSubmit={() =>
        generateNewScenario({
          scenarioName,
          scenarioOverview: scenarioDescription,
          newScenarioPublic,
          uploadedContent
        })
      }
      saveButtonExists
      saveButtonDisabled={scenarioName.length === 0}
      noChanges
    >
      <div className="flex-column col-12 gap-3">
        <div className="flex-row gap-2 col-12">
          <label>Create a name</label>
          <input
            type="text"
            className="widest small inline"
            value={scenarioName}
            onChange={(e) => {
              setScenarioName(e.target.value)
            }}
          />
        </div>
        <div className="flex-row col-12">
          <Checkbox
            color="info-o"
            animation="pulse"
            className="small"
            onChange={() => setNewScenarioPublic(!newScenarioPublic)}
            checked={newScenarioPublic}
          >
            Make public
          </Checkbox>
          {newScenarioPublic && (
            <div className="banner-strip warning small m-0">
              Are you sure you want to make this scenario accessible to
              everyone?
            </div>
          )}
        </div>
        <ToggledCollapsibleBlock
          title="(Optional) Create a Scenario From a Text"
          initialState={true}
          className="t-small-children info"
        >
          <textarea
            value={uploadedContent}
            onChange={(e) => {
              setUploadedContent(e.target.value)
            }}
            rows={3}
            className="col-12 small inline m-1 p-1"
            placeholder="Optional"
          />
        </ToggledCollapsibleBlock>
        <ToggledCollapsibleBlock
          title="(Optional) Create a Scenario From a text document"
          className="t-small-children info"
        >
          <FileUploadComponent handleSendFileContent={handleSendFileContent} />
        </ToggledCollapsibleBlock>

        <ToggledCollapsibleBlock
          title="(Optional) Add scenario description"
          initialState={false}
          className="t-small-children info"
        >
          <input
            type="text"
            value={scenarioDescription}
            onChange={(e) => {
              setScenarioDescription(e.target.value)
            }}
            className="col-12 small inline m-1"
            placeholder="Optional"
          />
        </ToggledCollapsibleBlock>
      </div>
    </PopupModal>
  )
}

export default MainAppCreateScenarioPopup
