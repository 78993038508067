import { aiSearch } from '../utils/fetch'
import React, { useState } from 'react'
import { FunctionalButton, ToggledCollapsibleBlock } from './_components'
import { IBasicIndicator } from '../utils/interfaces'

interface AISearchProps {
  addFunction: ({
    title,
    fid,
    add,
    type
  }: {
    title: string
    fid: string
    add?: boolean
    type?: string
  }) => void
}

const AISearch = ({ addFunction }: AISearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [results, setResults] = useState<IBasicIndicator[]>([])
  const [itemsToExclude, setItemsToExclude] = useState<string[]>([])

  const handleSearch = async () => {
    try {
      const res = await aiSearch(searchTerm, itemsToExclude)
      setResults(res)
      setItemsToExclude((prev) => [...prev, ...res.map((r) => r.fid)])
      return res.length > 0
    } catch (e) {
      console.error(e)
      setResults([])
      return false
    }
  }

  return (
    <div className="flex gap-2 col-12 p-0">
      <div className="banner-strip action col-12 small py-0 ms-2">
        Try our AI search to find indicators that are relevant to your needs.
        Just type in a keyword and hit the <i>AI Button</i>
      </div>
      <input
        type="text"
        className="indicators-search-input col-3"
        value={searchTerm}
        placeholder="AI Search"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <FunctionalButton
        functionToExecute={handleSearch}
        initialButtonState={'AI Search'}
        iconAI
        className="primary inline"
        doesReset
        disabled={searchTerm.length === 0}
      />
      {!(searchTerm.length === 0 || results.length === 0) && (
        <FunctionalButton
          functionToExecute={handleSearch}
          initialButtonState={'Retry'}
          iconReload
          className="secondary inline"
          doesReset
          disabled={searchTerm.length === 0 || results.length === 0}
        />
      )}
      {results.length > 0 && (
        <div className="col-12 flex gap-1 ps-2">
          <span>Suggested Indicator:</span>
          {results.map((result, index) => (
            <div key={result.fid + '_' + index} className="flex gap-2">
              <span>
                <i>{result.title}</i>
              </span>
              <FunctionalButton
                functionToExecute={() =>
                  addFunction({
                    fid: result.fid,
                    title: result.title
                  })
                }
                initialButtonState={'Add'}
                className="border border-gray"
                iconPlusMode
                doesReset
              />
            </div>
          ))}
          <ToggledCollapsibleBlock
            title="Description"
            className="t-small-children info"
          >
            {results.map((result, index) => (
              <div key={result.fid + '_' + index} className="flex gap-2">
                <span>
                  <i>{result.short_description || result.meaning}</i>
                </span>
              </div>
            ))}
          </ToggledCollapsibleBlock>
        </div>
      )}
    </div>
  )
}

export default AISearch
