import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react'
import {
  AccessDenied,
  EntityHeader,
  FunctionalButton,
  Spinner
} from '../helperComponents/_components'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import { Icon3Dots } from '../helperComponents/Icons'
import PopupModal from '../helperComponents/PopUpModal'
import {
  fetchTrendLineById,
  deleteTrendlineById,
  updateTrendlineParameters,
  modifyCollectionEntries
} from '../utils/fetch'
import { getPreviousPage } from '../utils/functions'
import {
  ITrendLinePage,
  ITrendline,
  IFetchedDataEntry,
  IForecast,
  IBasicIndicator,
  ICalculatedIndicator,
  ICollectionEntry
} from '../utils/interfaces'
import { Trendlines } from '../trendlines/Trendlines'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'

const TrendLinePage = ({
  trendlineId,
  favourites,
  userTags,
  readonly
}: ITrendLinePage) => {
  try {
    const [trendlines, setTrendlines] = useState([] as ITrendline[])
    const dataRef = useRef([] as IFetchedDataEntry[])

    const forecastsAssosiatedRef = useRef([] as IForecast[])
    const fullDatesRef = useRef([] as string[])

    const allIndicatorsRef = useRef(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    const [accessAllowed, setAccessAllowed] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const [modalDeleteIndicator, setModalDeleteIndicator] = useState(false)
    const [rangeSelected, setRangeSelected] = useState<string[]>([])
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }
    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [isCurrentIndicatorFavourite, setIsCurrentIndicatorFavourite] =
      useState(false)
    const [
      modalIndicatorNameAndDescription,
      setModalIndicatorNameAndDescription
    ] = useState(false)

    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })
    // const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
    //   []
    // )
    const [breadcrumbs, setBreadcrumbs] = useState(
      [] as
        | {
            title: string
            onClick: () => void
            active: boolean
          }[]
        | undefined
    )
    // const resetcontextMenuDetails = () =>
    //   contextMenuDetails.show &&
    //   setcontextMenuDetails(defaultContextMenuSettings)

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteIndicator(false)
      setModalIndicatorNameAndDescription(false)
      setContextMenuDetails(defaultContextMenuSettings)
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }
      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    // const handlecontextMenuDetails = (e: any) => {
    //   e.preventDefault()
    //   if (contextMenuDetails.show) {
    //     setcontextMenuDetails(defaultContextMenuSettings)
    //     return
    //   }
    //   setcontextMenuDetails({
    //     show: true,
    //     top: e.pageY,
    //     left: e.pageX - 210
    //   })
    // }

    const checkIfTrendlineIsFavourite = useMemo(() => {
      if (favourites === undefined) {
        return false
      }

      const isFavourite =
        favourites !== undefined &&
        favourites.trendlines.length > 0 &&
        favourites.trendlines.find(
          (item: ICollectionEntry) => item.id === trendlineId
        ) !== undefined

      return isFavourite
    }, [])

    const setupBreadcrumbs = (name: string) => {
      const previousPage = getPreviousPage()
      if (previousPage === undefined || previousPage === null) {
        return undefined
      }

      const type = previousPage.type

      if (
        type !== 'indicator' &&
        type !== 'indicatorTrendlines' &&
        type !== 'external' &&
        type !== 'externalTrendlines'
      ) {
        return undefined
      }

      const functionToExecute = type.includes('external')
        ? 'external'
        : 'indicator'

      const items = [
        {
          title: 'Indicator',
          onClick: () => {
            window.switchFunctions[functionToExecute](
              previousPage.fid as string
            )
          },
          active: false
        },
        {
          title: 'Trendlines Analysis',
          onClick: () =>
            window.switchFunctions[functionToExecute](
              previousPage.fid as string,
              true,
              true
            ),
          active: false
        },
        {
          title: 'Trendline',
          onClick: () => undefined,
          active: true
        }
      ]

      return items
    }

    // /////////////////////////////////////////////////////////////////

    // const findBFromAAGR = (AAGR: number) => {
    //   // Convert AAGR from a percentage to a decimal
    //   const aagrDecimal = AAGR / 100

    //   // Calculate the monthly growth factor
    //   const G_monthly = Math.pow(aagrDecimal + 1, 1 / 12)

    //   // Calculate 'b'
    //   const b = Math.log(G_monthly)

    //   return b
    // }

    const initialSetup = useCallback(
      async (trendlineId: string, noClose?: boolean) => {
        try {
          if (!noClose) {
            resetModals()
            setPageLoading(true)
          }
          let {
            parameters,
            data,
            fullDates,
            allIndicators,
            forecastsAssosiated,
            owner
          }: {
            parameters: ITrendline[]
            data: any
            fullDates: string[]
            allIndicators: (
              | IBasicIndicator
              | ICalculatedIndicator
              | ITrendline
            )[]
            forecastsAssosiated: IForecast[]
            owner: {
              profile_image: string
              username: string
            }
          } = await fetchTrendLineById(trendlineId)
          setOwnerInfo({
            profileImage: owner.profile_image,
            username: owner.username
          })

          if (!parameters || !data) {
            setAccessAllowed(false)
            setPageLoading(false)
            return
          }
          parameters = [parameters].flat()
          const trendline = parameters.find(
            (item: ITrendline) => item.fid === trendlineId
          ) as ITrendline
          const isFavourite = checkIfTrendlineIsFavourite
          const breadcrumbsSetup = setupBreadcrumbs(trendline.title)
          const firstDate = data.find(
            (element: any) => element[trendlineId] !== null
          ).date
          const lastDate = [...data]
            .reverse()
            .find((element: any) => element[trendlineId] !== null).date

          setRangeSelected([firstDate, lastDate])
          setTrendlines(parameters)
          dataRef.current = data
          forecastsAssosiatedRef.current = forecastsAssosiated
          fullDatesRef.current = fullDates
          allIndicatorsRef.current = allIndicators
          setBreadcrumbs(breadcrumbsSetup)
          setIsCurrentIndicatorFavourite(isFavourite)
          setAccessAllowed(true)

          if (!noClose) {
            setPageLoading(false)
          }
        } catch (err: any) {
          console.error(err)
          window.switchFunctions.home()
        }
      },
      [trendlineId]
    )

    const refreshTrendlineNoClose = () => {
      if (pageLoading) return
      initialSetup(trendlineId, true)
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateTrendlineParameters({
        fid: trendlineId,
        title: newName,
        meaning: newDescription,
        short_description: newShortDescription
      })

      if (res) {
        setModalIndicatorNameAndDescription(false)
        refreshTrendlineNoClose()
      }

      return res
    }

    useEffect(() => {
      if (pageLoading) return
      initialSetup(trendlineId)
    }, [])

    if (pageLoading) {
      return <Spinner />
    } else if (!accessAllowed && !pageLoading) {
      return <AccessDenied />
    } else if (accessAllowed && !pageLoading && trendlines[0]) {
      return (
        <div className="entity trendline">
          <EntityHeader
            subtitle="Trendline"
            breadcrumbs={breadcrumbs}
            titleClick={() => {
              const indicator = allIndicatorsRef.current.find(
                (item: IBasicIndicator) =>
                  item.fid === trendlines[0].base_indicator
              )
              if (indicator) {
                if (indicator.type === 'calculated') {
                  window.switchFunctions.calculated(indicator.fid)
                } else {
                  window.switchFunctions.indicator(indicator.fid)
                }
              } else {
                window.switchFunctions.external(trendlines[0].base_indicator)
              }
            }}
            ownerInfo={ownerInfo}
            title={trendlines[0].title.split('@')[0]}
            description={`Details: ${trendlines[0].title.split('@')[1]}`}
          />
          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters justify-content-start">
                {!readonly && (
                  <FunctionalButton
                    noReturn
                    className="inline no-btn default-text no-hover no-border"
                    functionToExecute={() => {
                      resetModals()
                      setModalDeleteIndicator(true)
                    }}
                    initialButtonState="Delete"
                    iconDelete
                  />
                )}
                <button
                  className="no-btn flex no-hover"
                  onClick={handleContextMenuDetails}
                >
                  <Icon3Dots />
                </button>
              </div>
            </div>
            <Trendlines
              actuals={trendlines.filter(
                (item) => item.type !== 'trendline' && item.type !== 'forecast'
              )}
              functions={{}}
              trendlines={trendlines.filter(
                (item) => item.type === 'trendline'
              )}
              readOnly={readonly}
              data={dataRef.current}
              userTags={userTags}
              forecastsAssosiated={forecastsAssosiatedRef.current}
              fullDates={fullDatesRef.current}
              allIndicators={allIndicatorsRef.current}
              selectedDataBoundaries={rangeSelected}
            />
          </div>
          {modalDeleteIndicator && !readonly && (
            <PopupModal
              isOpen={modalDeleteIndicator}
              onClose={() => setModalDeleteIndicator(false)}
              title="Are you sure you want to delete this trendline?"
              size="small"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
            >
              <div className="flex-row center middle margin-1">
                <FunctionalButton
                  className="destructive"
                  functionToExecute={async () =>
                    await deleteTrendlineById(trendlineId)
                  }
                  initialButtonState={'Delete'}
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: () => {
                      const indicator = allIndicatorsRef.current.find(
                        (item: IBasicIndicator) =>
                          item.fid === trendlines[0].base_indicator
                      )
                      if (indicator) {
                        if (indicator.type === 'calculated') {
                          window.switchFunctions.calculated(indicator.fid)
                        } else {
                          window.switchFunctions.indicator(indicator.fid)
                        }
                      }
                    }
                  }}
                />
              </div>
            </PopupModal>
          )}
          {modalIndicatorNameAndDescription && (
            <PopupModal
              isOpen={modalIndicatorNameAndDescription}
              onClose={resetModals}
              title="Edit Indicator Details"
              size="large"
              handleSubmit={() => undefined}
              inner
            >
              <RenameMajorEntity
                functionToExecute={handleRename}
                title={trendlines[0].title}
                description={trendlines[0].meaning}
                short_description={trendlines[0].short_description}
              />
            </PopupModal>
          )}
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorNameAndDescription(true)
                },
                title: 'Edit Details',
                noAccess: readonly
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'trendline',
                    entityId: trendlineId,
                    action: isCurrentIndicatorFavourite ? 'remove' : 'add',
                    entityName: trendlines[0].title,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentIndicatorFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: refreshTrendlineNoClose,
                  doesReset: true
                },
                noAccess: trendlines[0].is_own
              }
            ]}
          />
        </div>
      )
    } else {
      return <AccessDenied />
    }
  } catch (error) {
    console.error('Error rendering trendline page:', error)
    return <AccessDenied />
  }
}
export default TrendLinePage
