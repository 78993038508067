import * as React from 'react'
import Box from '@mui/material/Box'
import { FunctionalButton } from './_components'
import { RangeSliderProps } from '../utils/interfaces'
import { useState } from 'react'

const RangeSlider2 = ({
  dataArray,
  customStyles,
  valueLabelDisplay,
  initialDetails,
  selectedDetails,
  onApply,
  readOnly,
  noApplyButton,
  handleChange,
  dateSliderMode,
  iconMode,
  noSlider,
  extraItems
}: RangeSliderProps) => {
  const [startDate, setStartDate] = useState<string>(initialDetails[0] || '')
  const [endDate, setEndDate] = useState<string>(initialDetails[1] || '')

  const functionToExecute = (newStartDate: string, newEndDate: string) => {
    setEndDate(newEndDate)
    setStartDate(newStartDate)
    handleChange([newStartDate, newEndDate])
    onApply(newStartDate, newEndDate)
  }

  return (
    <div className={`flex middle gap-2 '${!noSlider ? ' w-100' : ''}`}>
      <div>
        <label htmlFor="startDateSelect" className="margin-right-0-half">
          From
        </label>

        <input
          id="startDateSelect"
          className="narrower"
          type="date"
          disabled={readOnly}
          value={startDate}
          onChange={(e) => functionToExecute(e.target.value, endDate)}
        />
      </div>
      {!noSlider && (
        <Box
          sx={{ ...customStyles, '& .MuiSlider-root': { color: '#4a4897' } }}
        >
          {/* <Slider
            getAriaLabel={() => 'Range'}
            value={value}
            min={0}
            max={dataArray.length - 1}
            valueLabelDisplay={valueLabelDisplay || 'off'}
            disableSwap={true}
            onChange={onSliderChange}
          /> */}
        </Box>
      )}
      {noSlider && <span>-</span>}
      <div>
        <input
          id="endDateSelect"
          className="narrower"
          type="date"
          disabled={readOnly}
          value={endDate}
          onChange={(e) => functionToExecute(startDate, e.target.value)}
        />
        <label htmlFor="endDateSelect" className="margin-left-0-half">
          To
        </label>
      </div>

      {!noApplyButton && (
        <FunctionalButton
          functionToExecute={() => functionToExecute(startDate, endDate)}
          initialButtonState={'Apply'}
          iconSaveMode={iconMode === 'save'}
          iconMinusMode={iconMode === 'minus'}
          iconCalculateMode={iconMode === 'calculate'}
          doesReset={true}
        />
      )}
    </div>
  )
}

export default RangeSlider2
