import React, { useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  FunctionalButton,
  InlineSpinner,
  TabbingSwitch
} from '../helperComponents/_components'
import {
  IconSmaller,
  IconLarger,
  IconUploadNew,
  TickFail,
  TickSuccess
} from '../helperComponents/Icons'
import { IMemoEntry } from '../utils/interfaces'
import { getMemoLink } from '../utils/fetch'

const MemoMaker = ({
  handleUpdateMemo,
  intialState,
  widthAvailable = 1000
}: {
  handleUpdateMemo: (intialState: IMemoEntry[]) => Promise<boolean>
  intialState: IMemoEntry[]
  widthAvailable?: number
}) => {
  const [uploadState, setUploadState] = useState<string>('ready')
  const [detailsTabMode, setDetailsTabMode] = useState<string>('reader')
  const [contentItems, setContentItems] = useState<IMemoEntry[]>(intialState)

  const handleAddImage = async (file: File) => {
    setUploadState('loading')
    const imageType = file.type.split('/')[1]
    const link: string = await getMemoLink(file, imageType)

    if (!link) {
      setUploadState('error')
      return
    }

    const newImageItem = {
      type: 'image',
      content: link,
      config: { className: 'col-6', source: '' }
    }
    setContentItems([...contentItems, newImageItem])
    setUploadState('success')

    const timeout = setTimeout(() => {
      setUploadState('ready')
      clearTimeout(timeout)
    }, 2000)
  }

  const handleAddTextArea = () => {
    const newTextItem = {
      type: 'text',
      content: '',
      config: { className: 'col-12', source: '' }
    }
    setContentItems((prevContentItems) => [...prevContentItems, newTextItem])
    return true
  }

  const handleDragStart = (
    e: {
      dataTransfer: {
        setData: (arg0: string, arg1: any) => void
        effectAllowed: string
      }
    },
    index: any
  ) => {
    try {
      e.dataTransfer.setData('text/plain', index)
      e.dataTransfer.effectAllowed = 'move'
    } catch (e) {
      console.error(e)
    }
  }

  const handleDrop = (
    e: {
      preventDefault: () => void
      dataTransfer: { getData: (arg0: string) => any }
    },
    dropIndex: number | undefined
  ) => {
    try {
      e.preventDefault()
      const dragIndex = e.dataTransfer.getData('text/plain')
      const draggedItem = contentItems[dragIndex]
      const remainingItems = contentItems.filter(
        (_, index) => index !== parseInt(dragIndex)
      )
      const newContentItems = [
        ...remainingItems.slice(0, dropIndex),
        draggedItem,
        ...remainingItems.slice(dropIndex)
      ]
      setContentItems(newContentItems)
    } catch (e) {
      console.error(e)
    }
  }

  const removeItemByIndex = (index: number) => {
    const newContentItems = [...contentItems]
    newContentItems.splice(index, 1)
    setContentItems(newContentItems)
  }

  const undoChanges = () => {
    setContentItems(intialState)
    return true
  }

  const MemoBlock = ({
    content,
    index,
    onDragStart,
    onDrop,
    mode
  }: {
    content: IMemoEntry
    index: number
    onDragStart: (e: React.DragEvent<HTMLDivElement>, index: number) => void
    onDrop: (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => void
    mode: string
  }) => {
    const ref = useRef<HTMLDivElement>(null)
    const isLarge = content.config.className === 'col-12'
    const [text, setText] = React.useState<string>(content.content)
    const [source, setSource] = React.useState<string>(content.config.source)

    const handleResize = () => {
      const newContentItems = [...contentItems] // Ensure you have access to contentItems or pass as props
      newContentItems[index].config.className = isLarge ? 'col-6' : 'col-12'
      setContentItems(newContentItems) // Ensure setContentItems is passed or accessible
    }

    const handleUpdate = () => {
      const newContentItems = [...contentItems] // Ensure you have access to contentItems or pass as props
      newContentItems[index].content = text
      setContentItems(newContentItems) // Ensure setContentItems is passed or accessible
    }

    const handleUpdateSource = () => {
      const newContentItems = [...contentItems] // Ensure you have access to contentItems or pass as props
      newContentItems[index].config.source = source
      setContentItems(newContentItems) // Ensure setContentItems is passed or accessible
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (mode === 'reader') return
      setText(e.target.value)
    }

    return (
      <div
        ref={ref}
        className={`memo-block ${content.config.className}`}
        onDragOver={(e) => e.preventDefault()} // Allows for the drop to register
        onDrop={(e) => onDrop(e, index)}
      >
        {mode === 'configuration' && (
          <div className="controls">
            <div
              className="drag-handle"
              draggable
              onDragStart={(e) => onDragStart(e, index)}
            >
              {/* Optional: Add an icon or text to signify this is a draggable area */}
              <span>:::</span>{' '}
              {/* This is just a simple textual representation; you might use an icon */}
            </div>
            <div className="flex gap-3">
              <input
                type="text"
                className="wide"
                placeholder="Source"
                onBlur={handleUpdateSource}
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
              <button
                onClick={handleResize}
                className="icon border"
                title={isLarge ? 'Shrink' : 'Expand'}
              >
                {isLarge ? <IconSmaller /> : <IconLarger />}
              </button>
              <FunctionalButton
                functionToExecute={() => removeItemByIndex(index)} // Make sure this function is passed or accessible
                initialButtonState={'Remove'}
                className="destructive"
                doesReset
                iconMinusMode
              />
            </div>
          </div>
        )}
        {content.type === 'text' && (
          <textarea
            className={`col-12 ${mode}`}
            // esure there are enough rows to display the content
            rows={
              text.length < 20
                ? 2
                : content.config.className === 'col-12'
                  ? (text.length / widthAvailable) * 14 + 1
                  : (text.length / widthAvailable) * 22 + 1
            }
            value={text}
            onChange={handleTextChange}
            onBlur={handleUpdate}
            onDrop={(e) => e.preventDefault()}
          />
        )}
        {content.type === 'image' && (
          <div
            className="image pointer"
            onClick={(e) => {
              e.preventDefault()

              if (isEmpty(content.config.source)) {
                return
              }

              if (mode === 'reader') {
                try {
                  window.open(content.config.source, '_blank')
                } catch (e) {
                  console.warn('Error opening source:', e)
                }
              }
            }}
          >
            <img
              src={content.content}
              alt="uploaded"
              className="full pointer"
              // disable interaction
              onDrag={(e) => e.preventDefault()}
            />
            {!isEmpty(content.config.source) && (
              <span className="t-small small font-small col-12 t-center">
                Source: {content.config.source}
              </span>
            )}
          </div>
        )}
      </div>
    )
  }

  const handleUpdate = async () => {
    const success = await handleUpdateMemo(contentItems)

    return success
  }

  return (
    <>
      <TabbingSwitch
        options={[
          {
            label: 'Reader',
            active: detailsTabMode === 'reader',
            onClick: () => setDetailsTabMode('reader'),
            className: 'flex justify-center'
          },
          {
            label: 'Configuration',
            active: detailsTabMode === 'configuration',
            onClick: () => setDetailsTabMode('configuration'),
            className: 'flex justify-center'
          }
        ]}
        fit
        numberVisible={2}
      />
      {contentItems &&
        contentItems.map((contentPiece, index) => (
          <MemoBlock
            key={index}
            content={contentPiece}
            index={index}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            mode={detailsTabMode}
          />
        ))}

      {detailsTabMode === 'configuration' && (
        <div className="col-12 flex gap-3 middle center p-2">
          <FunctionalButton
            functionToExecute={handleAddTextArea}
            initialButtonState={'Text'}
            className="no-btn no-hover no-border"
            doesReset
            iconPlusMode
            combinedButtonTitle
          />
          <div className={'file-upload'}>
            <label htmlFor="file-input" className="file-input">
              {
                {
                  loading: <InlineSpinner />,
                  error: <TickFail />,
                  success: <TickSuccess />,
                  ready: <IconUploadNew />
                }[uploadState]
              }
            </label>
            <input
              type="file"
              id="file-input"
              name="file-input"
              onChange={async (e) => {
                if (e.target.files) {
                  handleAddImage(e.target.files[0])
                }
              }}
            />
            <label htmlFor="file-input">
              {
                {
                  loading: 'Loading...',
                  error: 'Error',
                  success: 'Success',
                  ready: 'Add Image'
                }[uploadState]
              }
            </label>
          </div>
          <FunctionalButton
            functionToExecute={undoChanges}
            initialButtonState={'Undo'}
            className="ternary"
            doesReset
            iconUndoMode
            combinedButtonTitle
          />
          <FunctionalButton
            functionToExecute={handleUpdate}
            initialButtonState={'Save'}
            className="primary"
            doesReset
            iconSaveMode
            combinedButtonTitle
          />
        </div>
      )}
    </>
  )
}

export default MemoMaker
