import React, { useEffect, useState } from 'react'
import {
  TabbingSwitch,
  FunctionalToggle,
  ToggledCollapsibleBlock,
  FunctionalButton
} from '../../helperComponents/_components'
import {
  createNewIndicatorCalculated,
  fetchEntityData,
  generateNewScenario,
  getCalculatedFromDataAndMode,
  getCalculatedFromEquation,
  updateEntityIncluded,
  updateEquationCalculatedIndicator
} from '../../utils/fetch'
import IndicatorsAndTrendlinesSearch from '../../helperComponents/IndicatorsAndTrendlinesSearch'
import {
  ITrendline,
  ICollapsiblePanelIndicatorsSwitch,
  IEquationPiece,
  IIncludedId,
  IBasicIndicator,
  ICalculatedIndicator,
  ICombinedIndicatorFormulaAlternativeEquationBlocks,
  IGetCalulatedFromDataAndMode,
  IGetCalculatedFromEquation
} from '../../utils/interfaces'
import {
  AggregationMethod,
  CreateSeriesFromDataAndModeType,
  Frequency,
  IChartReferenceDataEntry,
  IChartReferenceEntry,
  IIndicatorSetting,
  IIndicatorsSettings
  // CreateSeriesFromDataAndModeType
} from '../interfaces'
import CombineIndicatorFormula from '../../helperComponents/CombineIndicatorFormula'
import { findDataFrequency } from '../../utils/transformingData'

const CollapsiblePanelIndicatorsSwitch = ({
  indicators,
  chartInstanceRef,
  allIndicators,
  initialFrequency,
  handleAddRemoveFromIdsIncuded,
  chartReferences,
  page
}: ICollapsiblePanelIndicatorsSwitch) => {
  const [selectedIndicator, setSelectedIndicator] = useState<string>(
    indicators[0]
  )
  // eslint-disable-next-line
  const [allIndicatorsState, setAllIndicatorsState] = useState<
    (ITrendline | IBasicIndicator | ICalculatedIndicator)[]
  >(allIndicators || [])
  // const equations = useRef<{
  //   equation: IEquationPiece[]
  //   alternativeEquation: IEquationPiece[]
  // }>({ equation: [], alternativeEquation: [] })
  const [addedIndicators, setAddedIndicators] = useState<string[]>([])
  const [editingTitle, setEditingTitle] = useState<boolean>(false)
  const [addedIndicatorsToLine, setAddedIndicatorsToLine] = useState<
    {
      fid: string
      included: string[]
    }[]
  >([])
  const [indicatorsDict, setIndicatorsDict] =
    useState<ICombinedIndicatorFormulaAlternativeEquationBlocks>({})

  const defaultSettings: IIndicatorSetting = {
    tabbingSwitchTitle: 'Line 1',
    seriesDisplayTitle: 'Line 1',
    color: '#7cb5ec',
    width: 1,
    fid: indicators[0],
    dashStyle: 'Solid',
    yAxis: 'left',
    mode: 'absolute',
    defaultFrequency: initialFrequency,
    currentFrequency: initialFrequency,
    aggregationMethod: 'first',
    equation: []
  }

  const generateDefaultSettingsForIndicators = () => {
    const newSettings: IIndicatorsSettings = {}

    indicators.forEach((indicatorFid, index) => {
      const matchedIndicator = allIndicators.find(
        (ind) => ind.fid === indicatorFid
      )
      const seriesDisplayTitle = matchedIndicator?.title || `Line ${index + 1}`

      newSettings[indicatorFid] = {
        ...defaultSettings,
        tabbingSwitchTitle: `Line ${index + 1}`,
        seriesDisplayTitle,
        fid: indicatorFid
      }
    })

    return newSettings
  }
  const constructDefaultSettings = () => {
    if (
      !chartReferences ||
      Object.keys(chartReferences).length === 0 ||
      !chartReferences.entries ||
      chartReferences.entries.length === 0
    ) {
      return generateDefaultSettingsForIndicators()
    }

    const settings = {}

    chartReferences.entries[0].chart_contents?.forEach(
      (entry: IChartReferenceDataEntry) => {
        ;(settings as any)[entry.fid] = entry.settings
      }
    )

    return settings
  }

  const [indicatorsSettings, setIndicatorsSettings] =
    useState<IIndicatorsSettings>(
      constructDefaultSettings() ?? {
        [indicators[0]]: defaultSettings
      }
    )

  const getSeries = (chartInstanceRef: any) => {
    try {
      if (
        !chartInstanceRef ||
        !chartInstanceRef.chartInstance ||
        !chartInstanceRef.chartInstance.series
      ) {
        return []
      }
      const series = chartInstanceRef.chartInstance.series.filter((s: any) => {
        const name = (s.options ? s.options : { name: '' }).name || ''
        return name.match(/Navigator \d+/) === null
      })

      return series
    } catch (error) {
      return []
    }
  }

  const readChartData = () => {
    // find all the series of the current chart
    const series = getSeries(chartInstanceRef.current as any)
    if (series.length === 0) {
      return []
    }

    const strippedSeries = series.map((s: any) => ({
      name: s.options.name,
      data: s.options.data,
      color: s.options.color,
      type: s.options.type,
      dashStyle: s.options.dashStyle,
      lineWidth: s.options.lineWidth,
      yAxis: s.options.yAxis,
      defaultFrequency: s.options.defaultFrequency,
      currentFrequency: s.options.currentFrequency,
      aggregationMethod: s.options.aggregationMethod
    }))

    return strippedSeries
  }

  const findReferenceKeyByFidFromSave = (fid: string, key: string) => {
    try {
      const settings: IChartReferenceEntry = chartReferences
        ?.entries[0] as IChartReferenceEntry

      if (!settings) {
        return null
      }

      const entry = settings.chart_contents.find((entry) => entry.fid === fid)

      if (!entry) return null

      return entry[key as keyof typeof entry]
    } catch (e) {
      console.error('Error at findReferenceKeyByFidFromSave')
      return null
    }
  }

  const findSettingsByFid = (fid: string): IIndicatorSetting | null => {
    const settingsObject = indicatorsSettings[fid]

    if (!settingsObject) {
      return null
    }

    return settingsObject
  }

  interface IIndicatorDictSetting {
    fid: string
    title: string
    mode: CreateSeriesFromDataAndModeType
    aggregationMethod: AggregationMethod
    offset: number
    cagr: number
    placeholderValue: string
  }

  const generateDefaultIndicatorsDict = (
    index: number,
    settings: Partial<IIndicatorDictSetting>
  ) => {
    const keys = 'abcdefghijklmnopqrstuvwxyz'

    const defaultObject = {
      fid: settings.fid || '',
      title: settings.title || '',
      mode: settings.mode || 'absolute',
      aggregationMethod: settings.aggregationMethod || 'first',
      offset: settings.offset || 0,
      cagr: settings.cagr || 0,
      placeholderValue: settings.placeholderValue || keys[index]
    }

    return defaultObject
  }

  const formatSelectedChartSeries = (
    propsToChange: Partial<IIndicatorSetting>,
    selectedSeries = selectedIndicator
  ) => {
    try {
      const series = getSeries(chartInstanceRef.current as any)
      if (series.length === 0) {
        return false
      }

      const matchedSettings = findSettingsByFid(selectedSeries)

      if (!matchedSettings) {
        return false
      }

      const { seriesDisplayTitle, tabbingSwitchTitle } = matchedSettings

      let matchedSeries = series.find(
        (series: any) => series.userOptions.name === seriesDisplayTitle
      )

      if (!matchedSeries) {
        matchedSeries = series.find(
          (series: any) => series.userOptions.name === tabbingSwitchTitle
        )

        if (!matchedSeries) {
          console.error('Error finding series by name', {
            seriesDisplayTitle,
            series
          })
          return false
        }
      }

      if (propsToChange.seriesDisplayTitle) {
        matchedSeries.update({
          name: propsToChange.seriesDisplayTitle
        })
      }

      if (propsToChange.color) {
        matchedSeries.update({
          color: propsToChange.color
        })
      }

      if (propsToChange.dashStyle) {
        matchedSeries.update({
          dashStyle: propsToChange.dashStyle
        })
      }

      if (propsToChange.width) {
        matchedSeries.update({
          lineWidth: propsToChange.width
        })
      }

      if (propsToChange.yAxis) {
        matchedSeries.update({
          yAxis: propsToChange.yAxis === 'right' ? 1 : 0
        })
      }

      return true
    } catch (error) {
      console.error('Error formatting series by name', error)
      return false
    }
  }

  const restoreSettings = (penultimate = false) => {
    try {
      // if series if not in time to render, we need a default settings to begin
      const defaultSettings =
        Object.keys(indicatorsSettings).length > 0
          ? indicatorsSettings
          : generateDefaultSettingsForIndicators()

      if (!defaultSettings) {
        console.error('Error generating default settings')
        return false
      }

      const settings: IChartReferenceEntry = chartReferences?.entries[
        penultimate ? 1 : 0
      ] as IChartReferenceEntry

      if (!settings) {
        console.error('No entries found in settings')
        // the chart should be updated with defaultSettings
        return setIndicatorsSettings(defaultSettings)
      }

      const entries = settings?.chart_contents

      if (!entries || !Array.isArray(entries) || entries.length === 0) {
        console.error('No entries found in settings')
        // the chart should be updated with defaultSettings
        return setIndicatorsSettings(defaultSettings)
      }

      entries.forEach((series: IChartReferenceDataEntry, index: number) => {
        if (!series) return
        const {
          settings,
          fid,
          indicatorsDict,
          addedIndicatorsToLine: added
        } = series
        if (!settings) return

        // // setChartData to chartInstanceRef
        // if (chartData) {
        //   const series = getSeries(chartInstanceRef.current as any)

        //   const matchedSeries = series.find(
        //     (s: any) => s.options.name === chartData.seriesDisplayTitle
        //   )

        //   matchedSeries.update({
        //     data: chartData.data
        //   })
        // }

        setIndicatorsSettings((prev) => ({
          ...prev,
          [fid]: settings
        }))
        const restoredIndicatorsDict =
          indicatorsDict as ICombinedIndicatorFormulaAlternativeEquationBlocks
        setIndicatorsDict(restoredIndicatorsDict)
        setAddedIndicatorsToLine(added)

        formatSelectedChartSeries(settings, fid)
      })

      return true
    } catch (e) {
      console.error('Error restoring settings')
      return false
    }
  }

  const restoreIndicatorsDictForIndicator = (
    existingAddedIndicators: any[],
    fid = selectedIndicator
  ) => {
    const restoredIndicatorsDict =
      findReferenceKeyByFidFromSave(selectedIndicator, 'indicatorsDict') || {}

    const indicatorsDictToSet: ICombinedIndicatorFormulaAlternativeEquationBlocks =
      restoredIndicatorsDict as ICombinedIndicatorFormulaAlternativeEquationBlocks

    if (!allIndicatorsState || existingAddedIndicators.length === 0) {
      setIndicatorsDict(
        indicatorsDictToSet as ICombinedIndicatorFormulaAlternativeEquationBlocks
      )
      return indicatorsDictToSet
    }

    const allIndicators: string[] = [
      selectedIndicator,
      (existingAddedIndicators as any)
        .filter((_: { fid: string }) => _.fid === fid)
        .map((_: { included: any }) => _.included)
        .flat()
    ].flat()

    const keys = 'abcdefghijklmnopqrstuvwxyz'

    allIndicators.forEach((indicator, index) => {
      const matchedIndicator = allIndicatorsState.find(
        (ind) => ind.fid === indicator
      )

      // const prev = indicatorsDictToSet[indicator]
      // the idea here is to ensure that the restored params are included
      // but also that on every change (cause this is a useEffect)
      // they should update accordingly still
      // TODO: probably must carry this functionality out into 2 separate functions

      const title = matchedIndicator?.title || indicator

      // if (!prev) {
      ;(indicatorsDictToSet[indicator] as any) = generateDefaultIndicatorsDict(
        index,
        {
          fid: indicator,
          title,
          placeholderValue: keys[index]
        }
      )
      // }
    })

    Object.keys(indicatorsDictToSet).forEach((key) => {
      if (!allIndicators.includes(key)) {
        delete indicatorsDictToSet[key]
      }
    })

    setIndicatorsDict(
      indicatorsDictToSet as ICombinedIndicatorFormulaAlternativeEquationBlocks
    )
    return indicatorsDictToSet
  }

  const restoreAddedIndicatorsToLineForIndicator = (
    fid = selectedIndicator
  ) => {
    const restoredIndicators = (findReferenceKeyByFidFromSave(
      fid,
      'addedIndicatorsToLine'
    ) || [
      {
        fid,
        included: []
      }
    ]) as {
      fid: string
      included: string[]
    }[]

    setAddedIndicatorsToLine(restoredIndicators)

    return restoredIndicators
  }

  const addExternalFull = async (fid: string) => {
    // try retrieving from backend anyway
    const result = await fetchEntityData({
      fid,
      type: 'external',
      title: '',
      frequency: ''
    })

    const series = result.series
    const params = result.params[0]
    setAllIndicatorsState((prev) => [...prev, params])

    return { series, params }
  }

  const handleAddIndicator = async (
    indicatorFid: string,
    insideIndicator = false
  ) => {
    try {
      const matchedIndicator = allIndicatorsState?.find(
        (ind) => ind.fid === indicatorFid
      )

      const newTabbingSwitchTitle = `Line ${Object.keys(indicatorsSettings).length + 1}`

      const newSeriesDisplayTitle =
        matchedIndicator?.title || newTabbingSwitchTitle

      let series
      let newTabbingOptionsObject = {
        fid: indicatorFid,
        seriesDisplayTitle: newSeriesDisplayTitle,
        tabbingSwitchTitle: newTabbingSwitchTitle
      }
      // if indicator not found, we assume its external
      if (!matchedIndicator || matchedIndicator.type === 'external') {
        const retrieved = await addExternalFull(indicatorFid)

        series = retrieved.series
        if (!insideIndicator) {
          newTabbingOptionsObject = {
            fid: retrieved.params.fid,
            seriesDisplayTitle: retrieved.params.title,
            tabbingSwitchTitle: newTabbingSwitchTitle
          }
        }
        // in alll other cases, we first check if the function requires an update to the chart and tabs
      } else if (!insideIndicator) {
        const { fid, title, type, frequency } = matchedIndicator

        const result = await fetchEntityData({ fid, title, type, frequency })
        series = result.series
        newTabbingOptionsObject = {
          fid,
          seriesDisplayTitle: title,
          tabbingSwitchTitle: newTabbingSwitchTitle
        }
      }

      if (!insideIndicator) {
        if (!series) {
          return false
        }

        // const frequency = findDataFrequency(
        //   series.map((d: number[]) => new Date(d[0]))
        // ) as Frequency
        const frequency =
          newTabbingOptionsObject.fid === indicators[0]
            ? initialFrequency
            : 'monthly'

        // before removing, read the color, width, dashstyle, yAxis, mode, defaultFrequency, currentFrequency, aggregationMethod from the series
        let newParams: IIndicatorSetting = {
          tabbingSwitchTitle: newTabbingOptionsObject.tabbingSwitchTitle,
          seriesDisplayTitle: newTabbingOptionsObject.seriesDisplayTitle,
          color: '#7cb5ec',
          width: 1,
          dashStyle: 'Solid',
          fid: newTabbingOptionsObject.fid,
          yAxis: 'left',
          mode: 'absolute',
          defaultFrequency: frequency,
          currentFrequency: frequency,
          aggregationMethod: 'first',
          equation: []
        }

        if (indicatorsSettings && indicatorsSettings[indicatorFid]) {
          const {
            color,
            width,
            dashStyle,
            yAxis,
            mode,
            defaultFrequency,
            currentFrequency,
            aggregationMethod,
            seriesDisplayTitle,
            tabbingSwitchTitle,
            equation
          } = indicatorsSettings[indicatorFid]

          newParams = {
            tabbingSwitchTitle,
            seriesDisplayTitle,
            color: color || '#7cb5ec',
            width: width || 1,
            dashStyle: dashStyle || 'Solid',
            yAxis: yAxis || 'left',
            fid: indicatorFid,
            mode: mode || 'absolute',
            defaultFrequency: defaultFrequency || initialFrequency,
            currentFrequency: currentFrequency || initialFrequency,
            aggregationMethod: aggregationMethod || 'first',
            equation: equation || []
          }
        }

        setIndicatorsSettings((prevSettings) => ({
          ...prevSettings,
          [newTabbingOptionsObject.fid]: newParams
        }))
        setAddedIndicators((prevIndicators) => [
          ...prevIndicators,
          indicatorFid
        ])
        ;(chartInstanceRef.current as any).chartInstance!.addSeries(series)
      }

      if (insideIndicator) {
        const matched = addedIndicatorsToLine.find(
          (_) => _.fid === selectedIndicator
        )

        if (!matched) {
          const newSelected = [
            ...addedIndicatorsToLine,
            {
              fid: selectedIndicator,
              included: [indicatorFid]
            }
          ]

          setAddedIndicatorsToLine(newSelected)
          restoreIndicatorsDictForIndicator(newSelected)
        } else {
          const newSelected = [...addedIndicatorsToLine].filter(
            (_) => _.fid !== selectedIndicator
          )

          newSelected.push({
            fid: selectedIndicator,
            included: [...(matched?.included || []), indicatorFid]
          })

          setAddedIndicatorsToLine(newSelected)
          restoreIndicatorsDictForIndicator(newSelected)
        }
      }

      if (
        page === 'scenario' &&
        handleAddRemoveFromIdsIncuded &&
        !insideIndicator
      ) {
        await handleAddRemoveFromIdsIncuded({
          fid: indicatorFid,
          add: true,
          type: matchedIndicator?.type || 'indicator'
        })
      }

      return true
    } catch (error) {
      console.error('Error adding indicator to chart', error)
      return false
    }
  }

  const handleRemoveIndicator = (indicator: string) => {
    try {
      setAddedIndicators((prevIndicators) =>
        prevIndicators.filter((ind) => ind !== indicator)
      )

      const matchedIndicator = allIndicatorsState?.find(
        (ind) => ind.fid === indicator
      )
      if (matchedIndicator) {
        const matchedSeries = (
          chartInstanceRef.current as any
        ).chartInstance.series.find(
          (series: any) => series.name === matchedIndicator.title
        )

        if (matchedSeries) {
          matchedSeries.remove()
        }
      }

      if (handleAddRemoveFromIdsIncuded) {
        handleAddRemoveFromIdsIncuded({
          fid: selectedIndicator,
          add: false,
          type: 'indicator'
        })
      }

      const newSettings = { ...indicatorsSettings }
      delete newSettings[indicator]
      setIndicatorsSettings(newSettings)

      const remainingIndicator =
        Object.keys(newSettings)[-1] || indicators[0] || ''
      setSelectedIndicator(remainingIndicator)

      return true
    } catch (error) {
      console.error('Error removing indicator from chart', error)
    }
  }

  const findSettingsByTabbingSwitchTitle = (
    tabbingSwitchTitle: string
  ): IIndicatorSetting | null => {
    const settingsObject = Object.values(indicatorsSettings).find(
      (setting) => setting.tabbingSwitchTitle === tabbingSwitchTitle
    )

    if (!settingsObject) {
      return null
    }

    return settingsObject
  }

  const handleFormatSelectedSeries = (
    newSettings: Partial<IIndicatorSetting>
  ) => {
    try {
      // if (!Object.keys(settings).length) {
      //   const setup = restoreSettings()
      //   if (!setup) {
      //     return false
      //   }

      //   settings = setup
      // }
      const matchedSettings = findSettingsByFid(selectedIndicator)

      if (!matchedSettings) {
        return false
      }

      setIndicatorsSettings((prevSettings) => ({
        ...prevSettings,
        [selectedIndicator]: {
          ...matchedSettings,
          ...newSettings
        }
      }))

      const updated = formatSelectedChartSeries({ ...newSettings })

      if (updated) {
        return true
      }

      return false
    } catch (error) {
      console.error('Error formatting series', error)
      return false
    }
  }

  const updateSelectedIndicatorSeries = async ({
    propsForModeData,
    propsForEquation
  }: {
    propsForModeData?: IGetCalulatedFromDataAndMode
    propsForEquation?: IGetCalculatedFromEquation
  }) => {
    let newSeries
    let propsSeriesTabbingSwitchTitle = ''
    if (propsForModeData) {
      newSeries = await getCalculatedFromDataAndMode(propsForModeData)
      propsSeriesTabbingSwitchTitle = propsForModeData.title
    }

    if (propsForEquation) {
      newSeries = await getCalculatedFromEquation(propsForEquation)
      propsSeriesTabbingSwitchTitle = propsForEquation.title
    }

    if (!newSeries || Object.keys(newSeries).length === 0) {
      return false
    }

    let matchedSettings = findSettingsByTabbingSwitchTitle(
      propsSeriesTabbingSwitchTitle
    )

    if (!matchedSettings || matchedSettings === null) {
      return false
    }

    const { seriesDisplayTitle, tabbingSwitchTitle } = matchedSettings

    // replace the existing series with the new series
    // remove the existing calculated series
    const existingSeries = getSeries(chartInstanceRef.current as any).find(
      (s: any) => s.name === tabbingSwitchTitle || s.name === seriesDisplayTitle
    )

    let mode, defaultFrequency, aggregationMethod // , dataMode,

    const {
      mode: modeFromSettings,
      defaultFrequency: defaultFrequencyFromSettings,
      aggregationMethod: aggregationMethodFromSettings
    } = matchedSettings

    if (propsForModeData) {
      mode = propsForModeData.mode
      // dataMode = propsForModeData.dataMode
      defaultFrequency = propsForModeData.frequency
      aggregationMethod =
        propsForModeData.aggregationMethod as AggregationMethod
    } else {
      mode = modeFromSettings
      defaultFrequency = defaultFrequencyFromSettings
      aggregationMethod = aggregationMethodFromSettings
    }

    const frequency = findDataFrequency(
      newSeries.data.map((d: number[]) => new Date(d[0]))
    ) as Frequency

    matchedSettings = {
      ...matchedSettings,
      seriesDisplayTitle: tabbingSwitchTitle,
      aggregationMethod,
      defaultFrequency,
      mode,
      currentFrequency: frequency
    }

    if (existingSeries) {
      existingSeries.remove()
    }

    // Add the new series to the chart
    ;(chartInstanceRef.current as any).chartInstance.addSeries(newSeries)

    formatSelectedChartSeries({
      ...matchedSettings
    })

    return true
  }

  const handleCalculateNewSeriesFromEquation = async (
    equation: IEquationPiece[]
  ) => {
    try {
      const settings = findSettingsByFid(selectedIndicator)
      if (!settings) return false
      let frequency = $('#frequency')?.val() as Frequency

      if (!frequency) {
        frequency = settings.currentFrequency || 'monthly'
      }

      // depending on frequency, different offsets methods are available
      const DICT = {
        daily: 1 / (365 / 12),
        weekly: 7 / (365 / 12),
        monthly: 1,
        quarterly: 3,
        yearly: 12
      }

      let multiplier = DICT[frequency]

      if (!multiplier) {
        multiplier = 1
      }

      equation = equation.map((piece) => {
        return {
          ...piece,
          offset: piece.offset * multiplier
        }
      })

      setIndicatorsSettings((prevSettings) => ({
        ...prevSettings,
        [selectedIndicator]: {
          ...settings,
          equation
        }
      }))

      const result = await updateSelectedIndicatorSeries({
        propsForEquation: {
          equation,
          title: settings.tabbingSwitchTitle,
          frequency
        }
      })

      return result
    } catch (error) {
      console.error('Error calculating new series from equation', error)
      return false
    }
  }

  const handleCalculateNewSeriesFromDataAndMode = async ({
    aggregationMethod,
    newFrequency,
    fid = selectedIndicator,
    mode = 'absolute',
    dataMode = 'values'
  }: {
    aggregationMethod: string
    newFrequency: Frequency
    fid?: string
    mode?: CreateSeriesFromDataAndModeType
    dataMode?: 'values' | 'deviations'
  }) => {
    const indicator = allIndicatorsState.find((ind) => ind.fid === fid)

    const settings = findSettingsByFid(fid)
    if (!settings) return false

    return await updateSelectedIndicatorSeries({
      propsForModeData: {
        type: indicator?.type || 'external',
        title: settings.tabbingSwitchTitle,
        fid,
        mode,
        frequency: newFrequency,
        aggregationMethod,
        dataMode
      }
    })
  }

  const handleFrequencyChangeForSelectedIndicator = async (
    newFrequency: Frequency
  ) => {
    const settings = findSettingsByFid(selectedIndicator)
    if (!settings) return false

    const isCalculationSuccessful =
      await handleCalculateNewSeriesFromDataAndMode({
        aggregationMethod: settings ? settings.aggregationMethod : 'first',
        newFrequency
      })
    if (!isCalculationSuccessful) {
      return false
    }

    handleFormatSelectedSeries({
      currentFrequency: newFrequency
    })

    return true
  }

  const handleAggregationChangeForSelectedIndicator = async (
    aggregationMethod: AggregationMethod
  ) => {
    const settings = findSettingsByFid(selectedIndicator)
    if (!settings) return false

    const isCalculationSuccessful =
      await handleCalculateNewSeriesFromDataAndMode({
        aggregationMethod,
        newFrequency: settings.currentFrequency || 'monthly'
      })

    if (!isCalculationSuccessful) {
      return false
    }

    handleFormatSelectedSeries({
      aggregationMethod
    })

    return true
  }

  const handleCreateNewCalculated = async (
    equation: IEquationPiece[],
    alternativeEquation: IEquationPiece[]
  ) => {
    const equationToUse =
      equation && equation.length > 0 ? equation : alternativeEquation
    if (!equationToUse || equationToUse.length === 0) {
      return false
    }

    const fid = await createNewIndicatorCalculated({
      title:
        indicatorsSettings[selectedIndicator].seriesDisplayTitle ||
        indicatorsSettings[selectedIndicator].tabbingSwitchTitle +
          ' (calculated)',
      dataMode: 'values',
      computationMode: 'calculated',
      noRefresh: true
    })

    if (!fid) {
      return false
    }

    const updated = await updateEquationCalculatedIndicator({
      indicatorId: fid,
      equation: equationToUse
    })

    if (!updated) {
      return false
    }

    // window.switchFunctions.calculated(fid)
    window.open(`/app?t=o&i=${fid}`, '_blank')

    return true
  }

  const handleCreateNewScenario = async (): Promise<string | null> => {
    const fid = await generateNewScenario({
      scenarioName: `Auto-generated at ${new Date().toLocaleString()}`,
      scenarioOverview: `Scenario generated at ${new Date().toLocaleString()} from the ${page} page.`,
      newScenarioPublic: false,
      noRefresh: true
    })

    return fid || null
  }

  const handleSaveSeriesDisplayTitle = () => {
    const inputElement = document.getElementById(
      'seriesDisplayTitle'
    ) as HTMLInputElement
    if (!inputElement) return false

    const newTitle = inputElement.value.trim()
    if (!newTitle || newTitle.length === 0) {
      return false
    }

    const res = formatSelectedChartSeries({ seriesDisplayTitle: newTitle })

    if (!res) return false

    handleFormatSelectedSeries({
      seriesDisplayTitle: newTitle
    })

    setEditingTitle(false)

    return res
  }

  const handleSubmitTrendlinesSelection = async (entityId: string) => {
    const scenarioIndicators = Object.values(indicatorsSettings).map(
      (setting) => setting.fid
    )
    const formattedScenarioIndicators: IIncludedId[] = scenarioIndicators
      .map((indicator) => {
        const foundIndicator = allIndicators.find(
          (allIndicator) => allIndicator.fid === indicator
        )

        if (!foundIndicator || !foundIndicator.type) return null

        return {
          fid: indicator,
          type: foundIndicator.type,
          chart: true,
          data: true
        }
      })
      .filter((indicator): indicator is IIncludedId => indicator !== null)

    const res = await updateEntityIncluded({
      entity: {
        entityId,
        entityType: 'scenario'
      },
      ids: formattedScenarioIndicators
    })

    return res
  }

  const handleUpdateScenario = async () => {
    const fid = await handleCreateNewScenario()

    if (fid) {
      return handleSubmitTrendlinesSelection(fid)
    }

    return false
  }

  const handleCreateReferenceObjectByFid = (fid: string, chartDataAll: any) => {
    const settings = indicatorsSettings[fid]
    if (!settings) return null

    // TODO: add reading off frequency and aggregation method

    const { tabbingSwitchTitle, seriesDisplayTitle } = settings

    const chartData =
      chartDataAll.find((entry: any) => entry.name === seriesDisplayTitle) || {}
    let added = addedIndicatorsToLine

    if (
      addedIndicatorsToLine.find((entry) => entry.fid === fid) === undefined
    ) {
      added = [
        ...added,
        {
          fid,
          included: []
        }
      ]
    }

    const referenceToUpload: IChartReferenceDataEntry = {
      fid,
      indicatorsDict,
      addedIndicatorsToLine: added,
      settings,
      chartData: {
        ...chartData,
        tabbingSwitchTitle,
        seriesDisplayTitle
      }
    }

    return referenceToUpload
  }

  const handleCreateReference = async () => {
    try {
      if (!chartReferences) return false

      const { createFunction } = chartReferences
      if (!createFunction) return false

      const chartDataAll = readChartData()

      const referenceObjects = Object.keys(indicatorsSettings)
        .map((key) => handleCreateReferenceObjectByFid(key, chartDataAll))
        .filter((_) => _ !== null) as IChartReferenceDataEntry[]

      const res = await createFunction(referenceObjects)

      if (!res) {
        return false
      }

      // to avoid refreshing the page we push this fake with real data into the array
      chartReferences.entries.unshift({
        id: 10000,
        fid: 'random_id',
        source_fid: 'does not matter',
        source_type: page,
        chart_contents: referenceObjects,
        updated_at: new Date().toISOString(),
        created_at: new Date().toISOString()
      })

      return res
    } catch (e) {
      console.error('Error creating a reference')
    }
  }

  useEffect(() => {
    const existingAddedIndicators =
      restoreAddedIndicatorsToLineForIndicator(selectedIndicator).filter(
        (entry) => entry.fid === selectedIndicator
      ) || []

    restoreIndicatorsDictForIndicator(
      existingAddedIndicators,
      selectedIndicator
    )
  }, [selectedIndicator])

  useEffect(() => {
    if (chartReferences) {
      restoreSettings()
    }
  }, [chartReferences])

  return (
    <div className="flex col-12 flex-wrap justify-between">
      <div className="col-11 py-1 ps-2 flex gap-2 h-[40px]">
        {chartReferences && (
          <FunctionalButton
            initialButtonState="Restore"
            functionToExecute={() => restoreSettings(true)}
            doesReset
            className="secondary inline"
            iconReload
            combinedButtonTitle
          />
        )}
        <FunctionalButton
          initialButtonState="Save" // сохраняется только после обновления страницы
          functionToExecute={handleCreateReference}
          className="primary inline"
          disabled={!chartReferences}
          iconSaveMode
          doesReset
          combinedButtonTitle
        />
        <FunctionalButton
          initialButtonState="Convert"
          functionToExecute={handleUpdateScenario}
          className="secondary inline"
          iconConvert
          doesReset
          combinedButtonTitle
        />
      </div>

      <div className="col-12 p-2 ps-1">
        <IndicatorsAndTrendlinesSearch
          disabled={page !== 'scenario'}
          indicators={(allIndicatorsState as ITrendline[]) || []}
          includedIndicators={addedIndicators}
          refreshIndicators={() => {}}
          direction="down"
          hoverMode
          customMessage="Add Lines to Chart"
          addFunction={(indicator) => handleAddIndicator(indicator)}
          removeFunction={(indicator: string) =>
            handleRemoveIndicator(indicator)
          }
          altFunction={async (id: string) => {}}
        />
      </div>

      <div className="flex col-12 p-2">
        <TabbingSwitch
          numberVisible={4}
          className="col-12"
          fit
          options={Object.entries(indicatorsSettings)
            .map(([key, value]) => ({
              label: value.tabbingSwitchTitle,
              active: selectedIndicator === key,
              onClick: () => setSelectedIndicator(key)
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
        />
        <hr />
        <div className="col-12 p-2 collapsible-side-panel-body">
          {Object.entries(indicatorsSettings).map(([key, value], index) => (
            <div key={index} className="flex-column space-between col-12">
              <div
                className="col-12 my-2 gap-2 flex-column"
                style={{
                  display: selectedIndicator === key ? 'block' : 'none'
                }}
              >
                <div className="col-12 flex justify-between">
                  {/* <strong className="overflow truncate max-w-[70%]">
                        {indicatorsSettings[key].seriesDisplayTitle}
                      </strong> */}
                  <div className="flex gap-2">
                    {!editingTitle && (
                      <>
                        <span className="overflow-hidden text-ellipsis whitespace-nowrap w-[auto] max-w-[250px]">
                          {value ? value.seriesDisplayTitle : ''}
                        </span>
                        <FunctionalButton
                          initialButtonState="Edit"
                          doesReset
                          functionToExecute={() => setEditingTitle(true)}
                          noReturn
                          className="secondary icon inline no-btn scale-90"
                          iconEditMode
                        />
                      </>
                    )}
                    {editingTitle && (
                      <>
                        <input
                          id="seriesDisplayTitle"
                          type="text"
                          className="inline m-0 wider"
                          defaultValue={value ? value.seriesDisplayTitle : ''}
                        />
                        <FunctionalButton
                          initialButtonState="Undo"
                          doesReset
                          functionToExecute={() => setEditingTitle(false)}
                          className="teriary icon scale-90"
                          iconUndoMode
                          noReturn
                        />
                        <FunctionalButton
                          initialButtonState="Save"
                          doesReset
                          functionToExecute={handleSaveSeriesDisplayTitle}
                          className="primary icon scale-90"
                          iconSaveMode
                        />
                      </>
                    )}
                  </div>
                  <div className="flex gap-2">
                    {/* <FunctionalButton
                        initialButtonState="Calculated"
                        functionToExecute={handleUpdateScenario}
                        className="secondary icon"
                        iconCalculate
                        confirmation={{
                          position: 'inline',
                          message: 'Save as calculated indicator?'
                        }}
                      /> */}
                    {handleAddRemoveFromIdsIncuded && (
                      <FunctionalButton
                        className="destructive icon"
                        disabled={page !== 'scenario'}
                        functionToExecute={() => handleRemoveIndicator(key)}
                        confirmation={{
                          position: 'popup',
                          message: 'Remove this line from chart?'
                        }}
                        iconDelete
                        initialButtonState="Remove"
                        doesReset
                      />
                    )}
                  </div>
                </div>
                <ToggledCollapsibleBlock
                  title="Appearance"
                  initialState={true}
                  className="t-small-children"
                >
                  <div className="col-12 t-small">
                    <div className="flex space-between col-12 my-2">
                      <div className="flex col-6 py-2">
                        <label>Color:</label>
                        <input
                          type="color"
                          value={value ? value.color : '#7cb5ec'}
                          onChange={(e) => {
                            handleFormatSelectedSeries({
                              color: e.target.value
                            })
                          }}
                        />
                      </div>
                      <div className="flex col-6 py-2">
                        <label>Line Width:</label>
                        <input
                          className="inline"
                          type="number"
                          value={value ? value.width : 1}
                          onChange={(e) => {
                            handleFormatSelectedSeries({
                              width: Number(e.target.value)
                            })
                          }}
                          max={15}
                        />
                      </div>
                      <div className="flex col-6 py-2">
                        <label>Line Style:</label>
                        <select
                          value={value ? value.dashStyle : 'Solid'}
                          className="inline"
                          onChange={(e) =>
                            handleFormatSelectedSeries({
                              dashStyle: e.target.value
                            })
                          }
                        >
                          <option value="Solid">Solid</option>
                          <option value="Dash">Dash</option>
                          <option value="Dot">Dot</option>
                        </select>
                      </div>
                      <div className="flex col-6">
                        <label className="margin-right-1">
                          Preferred Y-Axis:
                        </label>
                        <FunctionalToggle
                          leftTitle="Left"
                          rightTitle="Right"
                          state={value.yAxis === 'right'}
                          functionToExecute={() => {
                            const currentYAxis = value.yAxis
                            const newYAxis =
                              currentYAxis === 'left' ? 'right' : 'left'

                            handleFormatSelectedSeries({
                              yAxis: newYAxis
                            })
                          }}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </ToggledCollapsibleBlock>
                <ToggledCollapsibleBlock
                  title="Data"
                  className="action"
                  initialState={true}
                >
                  <div className="my-2">
                    <IndicatorsAndTrendlinesSearch
                      indicators={(allIndicatorsState as ITrendline[]) || []}
                      customMessage="Add Lines to Equation"
                      includedIndicators={Object.keys(indicatorsDict)}
                      refreshIndicators={() => {}}
                      direction="down"
                      inline
                      disabled={!(page === 'scenario' || page === 'calculated')}
                      addFunction={(indicator) =>
                        handleAddIndicator(indicator, true)
                      }
                      removeFunction={(indicator: string) =>
                        handleRemoveIndicator(indicator)
                      }
                      altFunction={async (id: string) => {}}
                    />
                  </div>

                  <div className="px-2">
                    <div className="flex col-12 gap-1 text-[0.8rem]">
                      <span>Frequency for all:</span>
                      <select
                        className="inline mx-0"
                        id="frequency"
                        onChange={(e) =>
                          handleFrequencyChangeForSelectedIndicator(
                            e.target.value as Frequency
                          )
                        }
                      >
                        {(value
                          ? value.defaultFrequency === 'daily'
                          : initialFrequency === 'daily') && (
                          <option value="daily">Daily</option>
                        )}
                        {((value
                          ? value.defaultFrequency === 'daily'
                          : initialFrequency === 'daily') ||
                          (value
                            ? value.defaultFrequency === 'weekly'
                            : initialFrequency === 'weekly')) && (
                          <option value="weekly">Weeky</option>
                        )}
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                      {(value ? value.defaultFrequency : initialFrequency) !==
                        value?.currentFrequency && (
                        <>
                          <span className="ms-1">Aggregation Method:</span>
                          <select
                            value={value ? value.aggregationMethod : 'first'}
                            className="inline mx-0"
                            onChange={(e) => {
                              handleAggregationChangeForSelectedIndicator(
                                e.target.value as AggregationMethod
                              )
                            }}
                          >
                            <option value="average">Average</option>
                            <option value="sum">Sum</option>
                            <option value="first">Start of Period</option>
                            <option value="last">End of Period</option>
                          </select>
                        </>
                      )}
                    </div>
                    <div className="border-b-2 mt-2"></div>
                    <CombineIndicatorFormula
                      indicators={allIndicatorsState.filter((ind) =>
                        Object.keys(indicatorsDict).includes(ind.fid)
                      )}
                      indicatorId={selectedIndicator}
                      refreshFunction={() => {}}
                      updateEquationFunction={
                        handleCalculateNewSeriesFromEquation
                      }
                      alternativeMode={{
                        mode: 'ab',
                        indicatorsDict,
                        equation: value?.equation ?? []
                      }}
                      customFunctionalButton={{
                        functionToExecute: handleCreateNewCalculated,
                        title: 'Convert',
                        className: 'secondary inline m-0'
                        // children: (
                        //   <input
                        //     type="text"
                        //     value={newCalculatedName}
                        //     onChange={(e) =>
                        //       setNewCalculatedName(e.target.value)
                        //     }
                        //     className="wide p-2"
                        //     placeholder="New Calculated Name"
                        //   />
                        // )
                      }}
                    ></CombineIndicatorFormula>
                  </div>
                </ToggledCollapsibleBlock>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CollapsiblePanelIndicatorsSwitch
